import React, { memo } from 'react'

import {calculateLifeInsuranceAmount } from '../helpers/Benefits'
import { type BenefitProps } from '../../../EmployeeCalculatorInterface'
import BenefitDetails from '../BenefitDetails'

const LifeInsuranceBenefit: React.FC<BenefitProps> = memo(({ formValuesContext, benefitDataCompany, setValues }) => {
  return (
    <BenefitDetails
      title='Life Insurance Benefit'
      benefitType='life_insurance'
      calculateAmount={calculateLifeInsuranceAmount}
      formValuesContext={formValuesContext}
      benefitDataCompany={benefitDataCompany}
      setValues={setValues}
    />
  )
})

export default LifeInsuranceBenefit
