import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core/'
import CloseIcon from '@mui/icons-material/Close'
import GppGoodIcon from '@mui/icons-material/GppGood'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal'
import { PARSLEY_EMAIL } from '../../Constants'

interface InfoModalProps {
  open: boolean
  handleClose: () => void
  isBenefitsModal?: boolean
}

const InfoModal: React.FC<InfoModalProps> = ({ open, handleClose, isBenefitsModal = false }) => {
  if (isBenefitsModal) {
    return (
      <Modal open={open} className='modal-background'>
        <div className='tax-modal-box'>
          <div className='modal-close-button'>
            <IconButton disableRipple onClick={handleClose}>
              <CloseIcon fontSize='large' />
            </IconButton>
          </div>
          <GppGoodIcon className='custom-shiled-icon' />
          <div className='modal-option'>
            <h3>This is confidential and will not be shared with your employer.</h3>
            <div className='modal-button'>
              <Button variant='contained' className='purple-button' onClick={handleClose}>OK</Button>
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{"Thanks for Using Parsley!"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This is a tool for estimating net income after expenses with eligibility for public benefits.
          <p>Parsley is a modeling tool and all results provided are estimates and should not be misconstrued as financial advice. To confirm eligibility for specific government programs, contact program offices directly.</p>
          <p className='mobile-line-hight'>
            <b>State of Wisconsin Programs included are:</b> BadgerCare and Medicaid for the Elderly or Disabled, ACA Marketplace Premium Tax Credits, FoodShare (SNAP), WIC, Wisconsin Shares Child Care Tuition Subsidy, HeadStart, Early HeadStart, 4-Year-Old Kindergarten, Lifeline, Reduced Fare Bus Passes for select local communities, State Tax Credits, Earned Income Tax Credit, Child Tax Credit, Child and Dependent Care Tax Credit.
            <b>Parsley does not estimate eligibility for Supplemental Security Income, Social Security Disability Insurance, Unemployment Insurance, or Medicare Programs.</b>
          </p>
          <p>
            <b>At this time, Parsley may not provide accurate results for the following populations:</b> those with veteran status, disability status, justice-involvement, foster care youth or youth aging out of foster care, those who are, or have been, recently homeless, those who are not U.S. citizens, or in some cases, adult students or minor parents. We are rapidly adding information to our database, and hope to update these areas soon.
          </p>
          <div className='mobile-line-hight'>
            For more information, email us at <a href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${PARSLEY_EMAIL}`} target='_blank' className='email_link' rel='noreferrer'>{PARSLEY_EMAIL}</a>.
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={handleClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default InfoModal
