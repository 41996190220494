import React, { memo } from 'react'

import BenefitDetails from '../BenefitDetails'
import { calculateShortTermDisabilityAmount } from '../helpers/Benefits'
import { type BenefitProps } from '../../../EmployeeCalculatorInterface'

const ShortTerm: React.FC<BenefitProps> = memo(({ formValuesContext, benefitDataCompany, setValues }) => {
  return (
    <BenefitDetails
      title='Short-Term Disability Insurance'
      benefitType='Short-Term Disability Insurance'
      calculateAmount={calculateShortTermDisabilityAmount}
      formValuesContext={formValuesContext}
      benefitDataCompany={benefitDataCompany}
      setValues={setValues}
    />
  )
})

export default ShortTerm
