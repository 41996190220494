import React, { memo, useMemo } from 'react'
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core'

import { type FormValues } from '../../../FormValuesInterfaces'
import {
  type Program,
  type Result
} from '../../../OutputInterfaces'
import {
  type GetBenefitEligibility,
  type GetYearlyOrMonthlyAmount,
  type MonthYearToggleButton,
  type RenderRow,
  type RenderRowWithHeading,
  type RenderTableHead,
  type SectionVisibilityType
} from '../../../interfaces'

interface Props {
  formValues: FormValues
  result: Result
  monthYearToggleButton: MonthYearToggleButton
  tableVisibility: SectionVisibilityType
  renderRow: RenderRow
  getBenefitEligibility: GetBenefitEligibility
  toggleTableBody: (value: string) => void
  getMonthlyOrYearlyAmount: GetYearlyOrMonthlyAmount
  renderRowsWithHeading: RenderRowWithHeading
  renderTableHead: RenderTableHead
  sectionsVisibility: SectionVisibilityType
}

const ExpenseAccountSection: React.FC<Props> = memo((props) => {
  const {
    formValues,
    result,
    monthYearToggleButton,
    tableVisibility,
    toggleTableBody,
    getBenefitEligibility,
    getMonthlyOrYearlyAmount,
    renderTableHead,
    sectionsVisibility
  } = props

  const renderOppRows = useMemo(() => (
    monthYearToggleValue: string,
    program: Program,
    programIndex: number,
    paddings?: string
  ) => {
    const { title, key, current_annual } = program
    const benefitEligibility = getBenefitEligibility(current_annual, monthYearToggleValue)

    const renderTitle = (title: string): JSX.Element => {
      const formattedTitle = title ?? '--'
      const formattedBenefitEligibility = benefitEligibility

      return (
        <>
          <TableCell component='th' scope='row' style={{ paddingLeft: paddings }}>
            {formattedTitle}
          </TableCell>

          <TableCell align='right'>{formattedBenefitEligibility}</TableCell>
        </>
      )
    }

    return (
      <React.Fragment key={programIndex}>
        <TableRow key={key}>{renderTitle(title)}</TableRow>
      </React.Fragment>
    )
  }, [getBenefitEligibility])

  const renderExpenseAccoutsRow = useMemo(() => (expenses: any[]) => {
    return expenses.map((expense: any, key: number) => {
      return renderOppRows('monthlyExpensesAccountCalculations', expense, key, '50px')
    })
  }, [renderOppRows])

  const renderDependentCareFsa = useMemo(() => (
    <>
      {sectionsVisibility.sectionExpenseAccount && (
        <>
          <TableCell className='cursor-pointer' onClick={() => { toggleTableBody('dependentCareFsa') }}>
            <b>
              {tableVisibility.dependentCareFsa ? <b>-</b> : <b>+</b>} Dependent Care FSA
            </b>
          </TableCell>

          <TableCell align='right'>
            <b>
              $ {getMonthlyOrYearlyAmount(
                result?.output?.expense_account_section?.programs[0]?.dependentcare_fsa?.programs_sum,
                'monthlyExpensesAccountCalculations'
              )}
            </b>
          </TableCell>

          {tableVisibility.dependentCareFsa && (
            <TableBody>
              {result?.output?.expense_account_section?.programs.map((expenses: any) =>
                renderExpenseAccoutsRow(expenses.dependentcare_fsa.programs)
              )}
              <TableRow>
                <TableCell colSpan={2}>
                  <p>
                    Your Dependent Care Flexible Spending Account funds and any employer “match” are available as your contribution is deducted from your paycheck.
                    However, if your employer provides a “seed” to the account, that portion will be available at the beginning of the year.
                    Any unspent funds are forfeited at the end of the year.
                  </p>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </>
      )}
    </>
  ), [
    sectionsVisibility.sectionExpenseAccount,
    tableVisibility.dependentCareFsa,
    toggleTableBody,
    result?.output?.expenses?.programs,
    getMonthlyOrYearlyAmount,
    renderExpenseAccoutsRow
  ])

  const ExpenseAccountText = () => (
    <>{sectionsVisibility.sectionExpenseAccount && (
      <TableBody>
        <TableCell colSpan={2}>
          Funds in this section have been applied to applicable expenses below, reducing your out-of-pocket expenses accordingly.
        </TableCell>
      </TableBody>

    )}

    </>
  )
  const renderHealthCareFsa = useMemo(() => (
    <>
      {sectionsVisibility.sectionExpenseAccount && (
        <>
          <TableCell className='cursor-pointer' onClick={() => { toggleTableBody('healthCareFsa') }}>
            <b>
              {tableVisibility.healthCareFsa ? <b>-</b> : <b>+</b>} Health Care FSA
            </b>
          </TableCell>

          <TableCell align='right'>
            <b>
              $ {getMonthlyOrYearlyAmount(
                result?.output?.expense_account_section?.programs[0]?.healthcare_fsa?.programs_sum,
                'monthlyExpensesAccountCalculations'
              )}
            </b>
          </TableCell>

          {tableVisibility.healthCareFsa && (
            <TableBody>
              {result?.output?.expense_account_section?.programs.map((expenses: any) =>
                renderExpenseAccoutsRow(expenses.healthcare_fsa.programs)
              )}
              <TableRow>
                <TableCell colSpan={2}>
                  <p>
                    Your entire Healthcare Flexible Spending Account is available at the beginning of the year, both your annual payroll deduction amount and any employers  contribution.  If you change employers you will lose any unspent funds.  Limited funds can be rolled over at the end of the year.
                  </p>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </>
      )}
    </>
  ), [
    sectionsVisibility.sectionExpenseAccount,
    tableVisibility.healthCareFsa,
    toggleTableBody,
    result?.output?.expenses?.programs,
    getMonthlyOrYearlyAmount,
    renderExpenseAccoutsRow
  ])

  const renderHealthSavingAmount = useMemo(() => (
    <>
      {sectionsVisibility.sectionExpenseAccount && (
        <>
          <TableCell className='cursor-pointer' onClick={() => { toggleTableBody('healthCareHsa') }}>
            <b>
              {tableVisibility.healthCareHsa ? <b>-</b> : <b>+</b>} Health Care HSA
            </b>
          </TableCell>

          <TableCell align='right'>
            <b>
              $ {getMonthlyOrYearlyAmount(
                result?.output?.expense_account_section?.programs[0]?.healthcare_hsa?.programs_sum,
                'monthlyExpensesAccountCalculations'
              )}
            </b>
          </TableCell>

          {tableVisibility.healthCareHsa && (
            <TableBody>
              {result?.output?.expense_account_section?.programs.map((expenses: any) =>
                renderExpenseAccoutsRow(expenses.healthcare_hsa.programs)
              )}
              <TableRow>
                <TableCell colSpan={2}>
                  <p>
                    Your Health Savings Account funds are available as your contribution is deducted from your paycheck.  If your employer contributes to the HSA, those funds may be available at the beginning of the year.  Unspent funds rollover for the next year and can also be spent at retirement.
                  </p>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </>
      )}
    </>
  ), [
    sectionsVisibility.sectionExpenseAccount,
    tableVisibility.healthCareHsa,
    toggleTableBody,
    result?.output?.expenses?.programs,
    getMonthlyOrYearlyAmount,
    renderExpenseAccoutsRow
  ])

  const renderHealthRembursment = useMemo(() => (
    <>

      {sectionsVisibility.sectionExpenseAccount && (
        <>
          <TableCell className='cursor-pointer' onClick={() => { toggleTableBody('healthCareHra') }}>
            <b>
              {tableVisibility.healthCareHra ? <b>-</b> : <b>+</b>} Health Reimbursment Account
            </b>
          </TableCell>

          <TableCell align='right'>
            <b>
              $ {getMonthlyOrYearlyAmount(
                result?.output?.expense_account_section?.programs[0]?.healthcare_hra?.programs_sum,
                'monthlyExpensesAccountCalculations'
              )}
            </b>
          </TableCell>

          {tableVisibility.healthCareHra && (
            <TableBody>
              {result?.output?.expense_account_section?.programs.map((expenses: any) =>
                renderExpenseAccoutsRow(expenses.healthcare_hra.programs)
              )}
              <TableRow>
                <TableCell colSpan={2}>
                  <p>
                    A Health Reimbursement Account is money an employer reimburses you for medical expenses. You cannot add to this account.
                  </p>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </>
      )}
    </>
  ), [
    sectionsVisibility.sectionExpenseAccount,
    tableVisibility.healthCareHra,
    toggleTableBody,
    result?.output?.expenses?.programs,
    getMonthlyOrYearlyAmount,
    renderExpenseAccoutsRow
  ])
  const programs = result?.output?.expense_account_section?.programs || []

  return (
    <>
      <div className="d-flex justify-content-end">
        {monthYearToggleButton('monthlyExpensesAccountCalculations')}
      </div>
      <TableContainer component={Paper}>
        <Table>
          {renderTableHead(
            'sectionExpenseAccount',
            'monthlyExpensesAccountCalculations',
            'Expense Accounts',
            result?.output?.expense_account_section?.programs_sum
          )}
          {ExpenseAccountText()}

          {/* Dependent Care FSA */}
          {(
            (formValues.b2bCalculator
              ? programs[0]?.dependentcare_fsa?.programs?.length > 0
              : true) &&
            renderDependentCareFsa
          )}
        </Table>

        <Table>
          {/* Health Care FSA */}
          {(
            (formValues.b2bCalculator
              ? programs[0]?.healthcare_fsa?.programs?.length > 0
              : true) &&
            renderHealthCareFsa
          )}
        </Table>

        <Table>
          {/* Health Care HSA */}
          {(
            (formValues.b2bCalculator
              ? programs[0]?.healthcare_hsa?.programs?.length > 0
              : true) &&
            renderHealthSavingAmount
          )}
        </Table>

        <Table>
          {/* Health Reimbursement Account */}
          {(
            (formValues.b2bCalculator
              ? programs[0]?.healthcare_hra?.programs?.length > 0
              : true) &&
            renderHealthRembursment
          )}
        </Table>
      </TableContainer>
    </>

  )
})

export default ExpenseAccountSection
