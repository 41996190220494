import React, { type Dispatch, type SetStateAction } from 'react'
import Modal from '@material-ui/core/Modal'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { Box, Button, Typography, Link } from '@mui/material'

interface Props {
  modalOpen: boolean
  setModalOpen: Dispatch<SetStateAction<boolean>>
}

const PrivacyPolicyModal: React.FC<Props> = (props) => {
  const { modalOpen, setModalOpen } = props

  const handleModalClose = (): void => {
    setModalOpen(false)
  };

  const handleAccept = (): void => {
    setModalOpen(false);
  };

  const handleReject = (): void => {
    setModalOpen(false)
  }

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: '12px',
          width: '100%',
          maxWidth: '600px',
          boxShadow: 24,
          outline: 'none',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#00b894',
            p: 2,
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" sx={{ color: 'white', fontWeight: 'bold' }}>
            Notice
          </Typography>
          <IconButton onClick={handleModalClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={{ p: 3 }}>
          <Typography variant="h6" align="left" sx={{ fontWeight: 'bold', mb: 1 }}>
            Notice
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            We and selected third parties collect personal information as specified in the{' '}
            <Link href="/privacy_policy" target="_blank">
              privacy policy
            </Link>{' '}
            and use cookies or similar technologies for technical purposes and, with your consent, for other purposes as specified.
          </Typography>
          <Typography variant="body2" sx={{ mb: 3 }}>
            Use the “Accept” button to consent. Use the “Reject” button or close this notice to continue without accepting.
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button
              variant="contained"
              color="error"
              sx={{ backgroundColor: '#007bff', color: 'white' }}
              onClick={handleReject}
            >
              Reject
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: '#007bff', color: 'white' }}
              onClick={handleAccept}
            >
              Accept
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default PrivacyPolicyModal
