import React, { Context, useContext } from 'react'
import { type BenefitData } from '../../EmployeeCalculatorInterface'
import { LONG_TERM_DISABILITY_BENEFIT, PENSION_PLAN_YEARS_LIMIT, SHORT_TERM_DISABILITY_BENEFIT } from '../../Constants'
import { FormValues } from '../../FormValuesInterfaces'

interface ContributionHeadingProps {
  benefitData: BenefitData
  benefitType: string
  isPension: boolean
  formValuesContext: Context<FormValues>
}

const ContributionHeading: React.FC<ContributionHeadingProps> = ({ benefitData, benefitType, isPension, formValuesContext }) => {
  const formValues: FormValues = useContext(formValuesContext)
  const { name, type, contribution, coverage, provider } = benefitData
  const getEmployerContributionMessage = (seedDeposit: number, matchPercentage: number, totalLimit: string | number) => {
    let message

    if (seedDeposit === 0 && matchPercentage === 0) {
      message = `Your employer does not match your contribution to this benefit.`
    } else if (matchPercentage === 0) {
      message = `Your employer contributes a $${seedDeposit} seed deposit and does not match your contribution to this benefit.`
    } else if (seedDeposit === 0) {
      message = `Your employer does not provide a seed deposit but matches your contribution ${matchPercentage}% up to the $${totalLimit} total contribution limit.`
    } else {
      message = `Your employer contributes a $${seedDeposit} seed deposit and matches your contribution ${matchPercentage}% up to the $${totalLimit} total contribution limit.`
    }

    return message
  }
  if (isPension) {
    return (
      <p>
        YOUR EMPLOYER MATCHES YOUR CONTRIBUTION UP TO {formValues.EmployerContributionLimit.pension}%. YOU MUST PARTICIPATE IN THE PLAN FOR {formValues.VestmentTimePeriod} YEARS TO RECEIVE THE MATCH INVESTMENT.
      </p>
    )
  }
  if (name === "medicalBenefitData") {
    const seedDeposit = formValues.employerSeed.healthCareFSA || 0
    const matchPercentage = formValues.EmployerContributionLimit.healthCareFSA || 0
    const totalLimit = benefitData.annualContributionLimit || 0
    const message = getEmployerContributionMessage(seedDeposit, matchPercentage, totalLimit)
    return <p>{message}</p>;
  }

  if (name === "dependentCareBenefitData") {
    const seedDeposit = formValues.employerSeed.dependentCareFSA || 0
    const matchPercentage = formValues.EmployerContributionLimit.DependentCareFSA || 0
    const totalLimit = benefitData.annualContributionLimit || 0
    const message = getEmployerContributionMessage(seedDeposit, matchPercentage, totalLimit)
    return <p>{message}</p>
  }
  if (name === 'lifeInsuranceBenefitData') {
    if (!type) return <p>Select plan details to see employer contribution</p>
    return (
      <p>
        {type === 'basic'
          ? 'YOUR EMPLOYER CONTRIBUTES 100% of BASIC COVERAGE'
          : 'YOUR EMPLOYER DOES NOT CONTRIBUTE TO THE COST OF THIS BENEFIT'}
      </p>
    )
  }

  if (name === SHORT_TERM_DISABILITY_BENEFIT || LONG_TERM_DISABILITY_BENEFIT) {
    const message =
      name === SHORT_TERM_DISABILITY_BENEFIT
        ? 'YOUR EMPLOYER DOES NOT CONTRIBUTE TO THE COST OF THIS BENEFIT'
        : 'YOUR EMPLOYER CONTRIBUTES 100% of THIS BENEFIT'
    return <p>{message}</p>
  }

  if (contribution !== '0' && coverage && contribution !== 'NA') {
    const matchText = isPension
      ? ` You must participate in the plan for ${PENSION_PLAN_YEARS_LIMIT} years to receive the match investment`
      : ''
    return (
      <p>
        Your employer {benefitType === 'premium' ? 'contributes' : 'matches up to'} {contribution} of{' '}
        {benefitType === 'premium' ? 'the remaining cost of your premium' : `your contribution.${matchText}`}
      </p>
    )
  }

  if (!coverage || !type || !provider) {
    return <p>Select plan details to see employer contribution</p>
  }

  return <p>Your employer does not contribute to {benefitType === 'premium' ? 'the cost of this benefit' : 'this benefit'}</p>
}

export default ContributionHeading
