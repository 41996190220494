import React, { forwardRef, useContext, useEffect, useMemo, useState, useImperativeHandle, useRef } from 'react'
import { Step, StepLabel, Stepper } from '@material-ui/core'
import { useParams } from 'react-router-dom'

import PurpleButton from '../PurpleButton'
import OutlinedButton from '../OutlinedButton'
import TakeHomePay from '../TakeHomePay'
import NPSSurvey from '../NPSSurveyModal'
import SurveyCompletion from '../SurveyCompletionModal'
import LogoAndBackButton from '../../LogoAndBackButton'
import { getBenefitData } from '../helpers/Benefits'
import { type BenefitDataCompany, type StepProps } from '../../../EmployeeCalculatorInterface'
import { type FormValues } from '../../../FormValuesInterfaces'
import { B2B_BENEFITS_FORMAT_STEP_NAME, BenefitComponents } from '../../../Constants'
import { convertAndRound } from '../../../Helper'
import { calculateTakeHomePayAfterBenefits } from '../../../api/employeeTaxes'

import './styles.sass'

const Benefits = forwardRef((props: StepProps, ref) => {
  const { formValuesContext, setValues, handleNext, handleBack, sethideLogo } = props
  const formValues: FormValues = useContext(formValuesContext)
  const { id } = useParams()

  const [activeStep, setActiveStep] = useState(0)
  const [skipped, setSkipped] = useState(new Set<number>())
  const [nextStepName, setNextStepName] = useState('')
  const [isTakeHomePayOpen, setIsTakeHomePayOpen] = useState(false)
  const [isNPSSurveyOpen, setIsNPSSurveyOpen] = useState(false)
  const [isSurveyCompletionOpen, setSurveyCompletionOpen] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [askForFeedback, setAskForFeedback] = useState(false)
  const [npsRating, setNpsRating] = useState(0)
  const [takeHomePayAmount, setTakeHomePayAmount] = useState(formValues.adults[0].takeHomePayAmount)
  const [annualTakeHomePayAmount, setAnnualTakeHomePayAmount] = useState(formValues.adults[0].annualTakeHomePayAmount)
  const [benefitDataCompany, setBenefitDataCompany] = useState({})
  const stepRefs = useRef<Array<HTMLDivElement | null>>([])

  useEffect(() => {
    const activeStepRef = stepRefs.current[activeStep]
      activeStepRef?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      })
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }, 500)
  }, [activeStep])
    
  useEffect(() => {
    if (activeStep + 1 < (formValues?.adults[0]?.selectedBenefits as string[]).length) {
      const nextStepName = B2B_BENEFITS_FORMAT_STEP_NAME(formValues?.adults[0]?.selectedBenefits?.[activeStep + 1] as string)
      setNextStepName(nextStepName)
    }
  }, [formValues.adults[0].selectedBenefits, activeStep])

  const isStepOptional = (step: number): boolean => step === activeStep

  const isStepSkipped = (step: number): boolean => skipped.has(step)

  useImperativeHandle(ref, () => ({
    handleButtonClick() {
      let newSkipped = skipped
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values())
        newSkipped.delete(activeStep)
      }

      if (activeStep !== (formValues?.adults[0]?.selectedBenefits as string[]).length) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
        setSkipped(newSkipped)
      }

      const formValuesUpdated = {
        ...formValues
      }

      setValues(formValuesUpdated)
    }
  }))

  const handleNextEmployeeBenefitStep = (): void => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    if (activeStep !== (formValues?.adults[0]?.selectedBenefits as string[]).length) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
      setSkipped(newSkipped)
    }

    setValues({ ...formValues })
  }

  const getData = async (): Promise<void> => {
    const response = await calculateTakeHomePayAfterBenefits(formValues)

    setTakeHomePayAmount(convertAndRound(response?.take_home_pay as number))
    setAnnualTakeHomePayAmount(convertAndRound(response?.annual_take_home_pay as number))
  }

  useEffect(() => {
    if (isTakeHomePayOpen) {
      void getData()
    }
  }, [isTakeHomePayOpen])

  const handleFinsh = (): void => { setIsTakeHomePayOpen(true) }

  const handleBackStep = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    setDisabled(false)
  }

  const handleStep = (step: number): void => { setActiveStep(step) }

  const handleSkip = (): void => {
    if (!isStepOptional(activeStep)) {
      throw new Error('You can’t skip a step that isn’t optional.')
    }

    if (activeStep < (formValues?.adults[0]?.selectedBenefits as string[]).length) {
      setActiveStep((prevActiveStep) => prevActiveStep + 2)
      setSkipped((prevSkipped) => {
        const newSkipped = new Set(prevSkipped.values())
        newSkipped.add(activeStep)
        return newSkipped
      })
    }
  }

  const renderLogoAndBackButton = (): React.ReactNode => {
    if (activeStep >= 1) {
      return <LogoAndBackButton activeStep={activeStep} backButtonCondition={0} onBackClick={handleBackStep} />
    }
  }

  const fetchBenefitCompany = async (): Promise<void> => {
    const benefits = await getBenefitData(formValues?.adults[0]?.employee?.id as number)
    setBenefitDataCompany(benefits.benefits)
  }

  useEffect(() => {
    void fetchBenefitCompany()
  }, [])

  const renderBenefits = useMemo(() => (benefitDataCompany: BenefitDataCompany) => {
    const selectedBenefit = formValues?.adults[0]?.selectedBenefits?.[activeStep]
    if (selectedBenefit !== undefined) {
      const Component = BenefitComponents[selectedBenefit as keyof typeof BenefitComponents]
      if (Component) {
        return (
          <Component
            formValuesContext={formValuesContext}
            benefitDataCompany={benefitDataCompany}
            setValues={setValues}
            setDisabled={setDisabled}
          />
        )
      }
    }

    return null
  }, [activeStep, formValues.adults[0]?.selectedBenefits])


  return (
    <>
      {renderLogoAndBackButton()}
      <div className='benefits-Step'>
        <div className='benefits-step-names'>
        <Stepper nonLinear activeStep={activeStep}>
  {formValues?.adults[0]?.selectedBenefits?.map((benefit: string, index: number) => {
    const capitalizedBenefit = B2B_BENEFITS_FORMAT_STEP_NAME(benefit)
    return (
      <Step
        key={index}
        ref={(el) => { stepRefs.current[index] = el as HTMLDivElement | null }} // Attach ref to each Step
        onClick={() => { handleStep(index) }}
      >
        <StepLabel>{capitalizedBenefit}</StepLabel>
      </Step>
    )
  })}
</Stepper>
        </div>

        {renderBenefits(benefitDataCompany as BenefitDataCompany)}

        <div className='benefit-buttons'>
          {isStepOptional(activeStep) && (activeStep < (formValues?.adults[0]?.selectedBenefits as string[]).length - 1) &&
            (<>
              <OutlinedButton label='Skip Next' width='40%' btnClick={handleSkip} disabled={disabled} />
            </>)}

          {activeStep < (formValues?.adults[0]?.selectedBenefits as string[]).length - 1
            ? (
              <PurpleButton label={`Next: ${nextStepName}`} width='60%' buttonClick={handleNextEmployeeBenefitStep} disabled={disabled} />)
            : (
              <>
                <PurpleButton label='Finish' width='100%' buttonClick={handleFinsh} disabled={disabled} />
              </>)
          }

          {isTakeHomePayOpen &&
            <TakeHomePay
              isOpen={isTakeHomePayOpen}
              takeHomePayAmount={takeHomePayAmount as number}
              annualTakeHomePayAmount={annualTakeHomePayAmount as number}
              setIsOpen={setIsTakeHomePayOpen}
              handleNext={handleNext}
              formValuesContext={formValuesContext}
              setValues={setValues}
              handleBack={handleBack}
              setIsNPSSurveyOpen={setIsNPSSurveyOpen}
              setAskForFeedback={setAskForFeedback}
              employeeID={id}
              sethideLogo= {sethideLogo}
            />
          }
          {askForFeedback && isNPSSurveyOpen && (
            <NPSSurvey
              isOpen={isNPSSurveyOpen}
              setIsOpen={setIsNPSSurveyOpen}
              setSurveyCompletionOpen={setSurveyCompletionOpen}
              employeeID={id}
              npsRating={npsRating}
              setNpsRating={setNpsRating}
            />
          )}
          {isSurveyCompletionOpen && !isNPSSurveyOpen && (
            <SurveyCompletion
              isOpen={isSurveyCompletionOpen}
              setIsOpen={setSurveyCompletionOpen}
              npsRating={npsRating}
            />
          )}
        </div>
      </div>
    </>
  )
})

export default Benefits
