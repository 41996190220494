import React, { useState, useEffect, useRef } from 'react'
import { Box, Card, CardContent, Checkbox, Divider, Grid, useMediaQuery } from '@material-ui/core'
import * as d3 from 'd3'

import { type Result } from '../../../OutputInterfaces'
import { type GetYearlyOrMonthlyAmount, type MonthYearToggleButton } from '../../../interfaces'
import { OOP_MEDICAL_EXPENSES_QP } from '../../../Constants'

interface Props {
  result: Result
  monthYearToggleButton: MonthYearToggleButton
  getMonthlyOrYearlyAmount: GetYearlyOrMonthlyAmount
}

const FinancialSnapshot: React.FC<Props> = (props) => {
  const { result, monthYearToggleButton, getMonthlyOrYearlyAmount } = props
  const [taxCredits, setTaxCredits] = useState(false)

  const isSmallScreen = useMediaQuery('(max-width:960px)')

  const totalIncome = taxCredits
    ? result?.output?.income?.programs_sum + result?.output?.tax_credits?.programs_sum
    : result?.output?.income?.programs_sum

  const expenses = result?.output?.expenses?.programs_sum || 0
  const financialDifference = Number(totalIncome) - expenses

  const handleTaxCreditsChange = (): void => {
    setTaxCredits((prevState) => !prevState)
  }

  const chartRef = useRef<SVGSVGElement | null>(null)

  useEffect(() => {
    if (chartRef.current) {
      d3.select(chartRef.current).selectAll('*').remove()

      const width = isSmallScreen ? 280 : 360
      const height = 250

      const svg = d3
        .select(chartRef.current)
        .attr('width', width)
        .attr('height', height)

      const data = [
        { label: 'Income', value: totalIncome, color: '#120D73' },
        { label: 'Expenses', value: expenses, color: '#FDB984' },
      ];

      const x = d3.scaleBand().domain(data.map((d) => d.label)).range([0, width]).padding(0.2)
      const y = d3.scaleLinear().domain([0, Math.max(Number(totalIncome), expenses)]).range([height, 0])

      svg
        .selectAll('.bar')
        .data(data)
        .enter()
        .append('rect')
        .attr('class', 'bar')
        .attr('x', (d) => x(d.label)!)
        .attr('y', (d) => y(Number(d.value)))
        .attr('width', x.bandwidth())
        .attr('height', (d) => height - y(Number(d.value)))
        .attr('fill', (d) => d.color)
    }
  }, [totalIncome, expenses, isSmallScreen])

  const Dot = ({ color }: { color: string }) => (
    <span
      style={{
        height: '8px',
        width: '8px',
        backgroundColor: color,
        borderRadius: '50%',
        display: 'inline-block',
        marginRight: '0.5rem',
      }}
    ></span>
  )

  return (
    <Card style={{ padding: '1rem' }}>
      <div className="d-flex justify-space-between align-items-center card-header">
        <p style={{ fontSize: '1.2rem', fontWeight: '600' }}>Financial Snapshot</p>
        <div>{monthYearToggleButton('financialSnapshotCalculations')}</div>
      </div>

      <Divider className="divider" style={{ marginBottom: '1rem' }} />

      <CardContent className="card-content">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4} style={{ textAlign: 'center' }}>
            <Box style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
              <span style={{ fontSize: '1rem', fontWeight: '500' }}>
                Income After All Deductions:
              </span>
              <Box
                display="flex"
                alignItems="center"
                style={{ gap: '0.5rem', marginTop: '0.5rem', marginLeft: '13px' }}
              >
                <Dot color="#120D73" />
                <strong style={{ fontSize: '1.3rem', fontWeight: '600' }}>
                  ${getMonthlyOrYearlyAmount(Number(totalIncome), 'financialSnapshotCalculations')}
                </strong>
              </Box>
            </Box>
            <Box style={{ marginTop: '1.5rem' }}>
              <p
                style={{
                  fontSize: '1rem',
                  fontWeight: '500',
                  marginRight: '1rem',
                  marginBottom: '0.25rem',
                }}
              >
                With Annual Tax Refund
              </p>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  marginTop: '0.5rem',
                  marginLeft: '2rem',
                  maxWidth: '18rem',
                }}
              >
                <Checkbox
                  checked={taxCredits}
                  size="small"
                  onChange={handleTaxCreditsChange}
                  style={{
                    padding: 0,
                    marginRight: '0.5rem',
                    marginTop: '8px'
                  }}
                />
                <span
                  style={{
                    fontSize: '0.9rem',
                    fontWeight: '500',
                    lineHeight: '1.4',
                    textAlign: 'left',
                    display: 'inline-block',
                    whiteSpace: 'normal',
                    width: '12rem',
                  }}
                >
                  Show total with Annual Tax Refund/Credits applied
                </span>

              </Box>
            </Box>

          </Grid>

          <Grid
            item
            xs={12}
            sm={3}
            style={{ marginTop: isSmallScreen ? '20px' : '0' }}
          >
            <Box style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ fontSize: '1rem', fontWeight: '500' }}>
                Out of Pocket Expenses:
              </span>
              <Box
                display="flex"
                alignItems="center" style={{ gap: '0.5rem', marginTop: '0.5rem', marginLeft: '1px' }}
              >
                <Dot color="#FDB984" />
                <strong style={{ fontSize: '1.2rem', fontWeight: '600' }}>
                  ${getMonthlyOrYearlyAmount(expenses, 'financialSnapshotCalculations')}
                </strong>
              </Box>
            </Box>
            <Box style={{ marginTop: '1.52rem', marginRight: '5rem' }}>
              <span style={{ fontSize: '1rem', fontWeight: '500' }}>{financialDifference > 0 ? 'Surplus' : 'Deficit'}:</span>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{ gap: '0.5rem', marginTop: '0.5rem' }}
              >
                <strong
                  style={{
                    fontSize: '1.3rem',
                    fontWeight: '600',
                    color: financialDifference > 0 ? '#28730D' : 'red',
                  }}
                >
                  ${getMonthlyOrYearlyAmount(financialDifference, 'financialSnapshotCalculations')}
                </strong>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Box style={{ width: '100%', marginTop: 0 }}>
              <svg ref={chartRef} style={{ overflow: 'visible' }}></svg>
            </Box>
          </Grid>
        </Grid>
      </CardContent>

      <Divider style={{ marginTop: '1rem' }} />

      <p className="card-note" style={{ marginTop: '1rem', lineHeight: '1.5rem', fontSize: '0.9rem' }}>
        <b>Review your expenses carefully:</b> They may have been adjusted due to changes in your public or private benefits, and
        please note that expenses will also vary depending on how much medical care you receive. Review your{' '}
        <a href={`/quick-peek/${OOP_MEDICAL_EXPENSES_QP}`} className="link" target="blank">
          out-of-pocket medical expenses
        </a>{' '}
        to ensure you are planning for copays and deductibles.
      </p>
    </Card>
  );
};

export default FinancialSnapshot
