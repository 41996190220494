import React, { memo } from 'react'

import BenefitDetails from '../BenefitDetails'
import { calculateLongTermisabilityAmount } from '../helpers/Benefits'
import { type BenefitProps } from '../../../EmployeeCalculatorInterface'

const LongTerm: React.FC<BenefitProps> = memo(({ formValuesContext, benefitDataCompany, setValues }) => {
  return (
    <BenefitDetails
      title='Long-Term Disability Insurance'
      benefitType='Long-Term Disability Insurance'
      calculateAmount={calculateLongTermisabilityAmount}
      formValuesContext={formValuesContext}
      benefitDataCompany={benefitDataCompany}
      setValues={setValues}
    />
  )
})

export default LongTerm
