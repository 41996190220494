import React, { memo } from 'react'

import { NON_NUMERIC_CHARACTERS } from '../../../Constants'

import './styles.sass'


interface NumberInputProps {
  value: string
  setValue: (value: string) => void
  error?: string
  setError?: (error: string) => void
  setIsError?: (isError: boolean) => void
  annualContributionStartrangeValue?: number
  annualContributionEndrangeValue?: number
  label: string
  disabled?: boolean
  setSelected?: (isSelected: boolean) => void
  isPension?:boolean
}

const NumberInput: React.FC<NumberInputProps> = memo((props) => {
  const { value, setValue, setError, setIsError, annualContributionStartrangeValue, annualContributionEndrangeValue, label, disabled, setSelected,isPension } = props
  const validateValue = (numberValue: number, annualContributionStartrangeValue?: number, annualContributionEndrangeValue?: number): void => {
    if (
      annualContributionStartrangeValue !== undefined &&
      !isNaN(annualContributionStartrangeValue) &&
      annualContributionEndrangeValue !== undefined
    ) {
      const isError =
        numberValue > annualContributionEndrangeValue ||
        numberValue < annualContributionStartrangeValue    
      if (setError && setIsError) {
        setIsError(isError);
        if (isError) {
          setError(
            `Employee contributions must not exceed ${annualContributionEndrangeValue}.`
          )
        } else {
          setError("")
        }
      }
    }  
  }
  const handleInputChange = (event: { target: { value: string } }): void => {
    if (setSelected) { setSelected(false) }
    let inputValue = event.target.value
    const numericRegex = isPension ? /[^0-9.]/g : NON_NUMERIC_CHARACTERS
    inputValue = inputValue.replace(numericRegex, '')

    const numberValue = isPension ? parseFloat(inputValue) : parseInt(inputValue, 10)

    if (!isNaN(numberValue)) {
      validateValue(numberValue, annualContributionStartrangeValue, annualContributionEndrangeValue)
    }

    setValue?.(inputValue)
  }

  return (
    <div className={`custom-input ${disabled ?? false ? 'disabled' : ''}`}>
      <input
        type='text'
        value={value}
        onChange={handleInputChange}
        placeholder={label}
        disabled={disabled}
      />
    </div>
  )
})

NumberInput.displayName = 'NumberInput'

export default NumberInput
