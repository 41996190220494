import React, { useState } from 'react'

import FileUploader from './FileUploader'
import EmployeeList, { type EmployeeData } from './EmployeeList'

import '../../component_styles/EmployerDashboard.sass'
import LogoAndBackButton from '../LogoAndBackButton'

const EmployerDashboard = ({ employees }: { employees: EmployeeData[] }): JSX.Element => {
  const [userEmployees, setUserEmployees] = useState(employees)

  const renderLogoAndBackButton = (): React.ReactNode => {
  
      return <LogoAndBackButton activeStep={0} backButtonCondition={0} onBackClick={function (): void {
        throw new Error('Function not implemented.')
      } }/>
    }


  return (
    <>
     {renderLogoAndBackButton()}
    <div className='employer-dashboard'>
      <div className='employer-actions'>
        <FileUploader setEmployees={setUserEmployees} />
      </div>
      <EmployeeList employees={userEmployees} />
    </div>
    </>
  )
}

export default EmployerDashboard
