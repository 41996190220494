import React, { type ChangeEvent, type FC, memo } from 'react'
import { Checkbox, FormControlLabel } from '@mui/material'

import './styles.sass'
import { type FormValues } from '../../../../FormValuesInterfaces'

const _ = require('lodash')

interface ReducedFareBusPassProps {
  contextValues: FormValues
  setContextValues: Function
}

const ReducedFareBusPass: FC<ReducedFareBusPassProps> = memo(({ contextValues, setContextValues }) => {
  const handleSave = (): void => { setContextValues({ ...contextValues }) }

  const handlecheckBusPassEligibilityChange = (event: ChangeEvent<HTMLInputElement>, index: number | string): void => {
    _.set(contextValues, ['adults', index, 'checkBusPassEligibility'], event.target.checked)
    event.target.checked && _.set(contextValues, ['adults', index, 'publicTransport'], true)
    handleSave()
  }

  const rendercheckBusPassEligibilityCheckbox = (index: number): JSX.Element => {
    _.get(contextValues, ['adults', index, 'checkBusPassEligibility']) === undefined &&
      _.set(contextValues, ['adults', index, 'checkBusPassEligibility'], false)
    const adult = contextValues.adults[index]
    const name = index < 1 ? 'Myself' : adult?.name || `Adult ${index}`

    return (
      <FormControlLabel
        key={index}
        label={name}
        labelPlacement='bottom'
        control={
          <Checkbox
            checked={adult?.checkBusPassEligibility || false}
            onChange={(event: ChangeEvent<HTMLInputElement>) => { handlecheckBusPassEligibilityChange(event, index) }}
          />
        }
      />
    )
  }

  return (
    <div className='bus-pass-benefit'>
      <p>For which adults would you like to determine eligibility?</p>

      {Object.keys(contextValues.adults).map((_, key) => rendercheckBusPassEligibilityCheckbox(key))}
    </div>
  )
})

export default ReducedFareBusPass
