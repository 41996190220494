import React, { forwardRef, useImperativeHandle, useContext, useState, useEffect } from 'react'
import Select from 'react-select'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { toast } from 'react-toastify'

import ColorDivider from '../../Employee/ColorDivider'
import { convertAndRound, getSelectdValue, scrollToTop } from '../../../Helper'
import PurpleButton from '../PurpleButton'
import AfterEstimatedTax from '../BeforeAfterEstimatedTax'
import { NUMBER_OF_CHILDREN } from '../../../Constants'
import { type FormValues } from '../../../FormValuesInterfaces'
import { getBenefitsData } from '../helpers/Benefits'
import { calculatePayAfterTaxes } from '../../../api/employeeTaxes'
import { type Children } from '../../AdultsInterface'

import './styles.sass'

interface TaxesProps {
  formValuesContext: React.Context<FormValues>
  setValues: React.Dispatch<FormValues>
  handleNext: () => void
  handleBack: () => void
  isAfterTaxes: boolean
  setIsAfterTaxes: (value: boolean) => void
  setIsChanged: (value: boolean) => void
}

const Taxes = forwardRef((props: TaxesProps, ref) => {
  const { formValuesContext, setValues, handleNext, handleBack, isAfterTaxes, setIsAfterTaxes, setIsChanged } = props
  const formValues: FormValues = useContext(formValuesContext)
  const [filingStatus, setFilingStatus] = useState(formValues.adults[0].filingStatus)
  const [claimDependent, setClaimDependent] = useState(formValues.adults[0].claimDependent)
  const [dependencyCount, setDependencyCount] = useState(formValues.adults[0].dependencyCount)
  const [afterTaxAmount, setAfterTaxAmount] = useState(formValues?.adults[0]?.afterTaxAmount)
  const childrenObject: Record<number, Children> = {}
  const [employeeBenefitsList, setEmployeeBenefitsList] = useState(formValues?.adults[0]?.employerBenefits ?? [])
  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        await getBenefitsData(formValues?.adults[0]?.employee?.id as number, setEmployeeBenefitsList)
      } catch (error) {
        toast.error(`Unable to fetch or process benefits data`)
      }
    }

    void fetchData()
  }, [])
  useEffect(() => {
    !claimDependent && setDependencyCount(0)
  }, [claimDependent])

  if (typeof dependencyCount !== 'undefined') {
    for (let i = 0; i < dependencyCount; i++) {
      childrenObject[i] = { parent: 0 } satisfies Children
    }
  }

  const getData = async (formValues: FormValues): Promise<void> => {
    const response = await calculatePayAfterTaxes(formValues)

    setAfterTaxAmount(convertAndRound(response?.pay_after_tax as number))

    const formValuesUpdated = {
      ...formValues,
      isInitialRender: false,
      adults: {
        ...formValues.adults,
        0: {
          ...formValues.adults[0],
          afterTaxAmount: convertAndRound(response?.pay_after_tax as number)
        }
      }
    }
    setValues(formValuesUpdated)
    setIsAfterTaxes(!isAfterTaxes)
  }

  useImperativeHandle(ref, () => ({
    handleButtonClick() {
      let selectedBenefits = []

      if (!formValues.adults[0].isBenefitsInitialRender) {
        selectedBenefits = formValues?.adults[0]?.selectedBenefits as string[]
      } else {
        if (formValues.adults[0].employee?.eligible_for_all_benefits) {
          selectedBenefits = employeeBenefitsList.map((benefit) => benefit.value)
        } else {
          selectedBenefits = formValues.adults[0].employee?.benefits_available ?? []
        }
      }

      const formValuesUpdated = {
        ...formValues,
        childrenCount: dependencyCount as number,
        adults: {
          ...formValues.adults,
          0: {
            ...formValues.adults[0],
            filingStatus,
            claimDependent,
            dependencyCount,
            selectedBenefits,
            selectAllBenefits: formValues?.adults[0].employee?.eligible_for_all_benefits
          },
          isInitialRender: false
        },
        children: childrenObject
      }
      setValues(formValuesUpdated)
    }
  }))

  const handleButtonClick = (): void => {
    setIsAfterTaxes(!isAfterTaxes)

    let selectedBenefits = []

    if (!formValues.adults[0].isBenefitsInitialRender) {
      selectedBenefits = formValues?.adults[0]?.selectedBenefits as string[]
    } else {
      if (formValues.adults[0].employee?.eligible_for_all_benefits) {
        selectedBenefits = employeeBenefitsList.map((benefit) => benefit.value)
      } else {
        selectedBenefits = formValues.adults[0].employee?.benefits_available ?? []
      }
    }

    const formValuesUpdated = {
      ...formValues,
      childrenCount: dependencyCount as number,
      adults: {
        ...formValues.adults,
        0: {
          ...formValues.adults[0],
          filingStatus,
          claimDependent,
          dependencyCount,
          selectedBenefits,
          selectAllBenefits: formValues?.adults[0].employee?.eligible_for_all_benefits
        }
      },
      children: childrenObject
    }
    setValues(formValuesUpdated)
    void getData(formValuesUpdated)
  }

  scrollToTop()

  return (
    <div className='taxes-page-container'>
      <div className='page-header'>
        <b>Taxes</b>
        <p>How do you plan to file your taxes?</p>
      </div>

      <ColorDivider />

      <div className='page-body'>
        <div className='tax-main-data'>
          <b>Tax Filing Status</b>
          <RadioGroup
            value={filingStatus}
          >
            <div className='radio-button-box'>
              <FormControlLabel
                value='single'
                control={<Radio />}
                label='Single'
                onChange={() => {
                  setFilingStatus('single')
                  setIsChanged(true)
                }}
              />
            </div>

            <div className='radio-button-box'>
              <FormControlLabel
                value='married'
                control={<Radio />}
                label='Married, filing jointly'
                onChange={() => {
                  setFilingStatus('married')
                  setIsChanged(true)
                }}
              />
            </div>

            <div className='radio-button-box'>
              <FormControlLabel
                value='head_of_household'
                control={<Radio />}
                label='Head of Household'
                onChange={() => {
                  setFilingStatus('head_of_household')
                  setIsChanged(true)
                }}
              />
            </div>
          </RadioGroup>
          <p>Coming soon: Married, filing separately.  Parsley does not currently support this option. </p>

          <b>Will you claim dependents?</b>
          <RadioGroup
            value={claimDependent}
          >
            <div className='radio-button-box'>
              <FormControlLabel
                value={true}
                control={<Radio />}
                label='Yes'
                onChange={() => {
                  setClaimDependent(true)
                  setIsChanged(true)
                }}
              />
            </div>

            <div className='radio-button-box'>
              <FormControlLabel
                value={false}
                control={<Radio />}
                label='No'
                onChange={() => {
                  setClaimDependent(false)
                  setIsChanged(true)
                }}
              />
            </div>
          </RadioGroup>

          <b>Additional Details</b>
          <div className='additional-data'>
            <b>How many children do you have under age 17? </b>

            <Select
              className='basic-single'
              classNamePrefix='select'
              placeholder='Duration'
              onChange={(e) => {
                setDependencyCount(e.value)
                setIsChanged(true)
              }}
              isSearchable={false}
              options={NUMBER_OF_CHILDREN}
              isDisabled={!claimDependent}
              value={dependencyCount ? getSelectdValue(dependencyCount, NUMBER_OF_CHILDREN) : { label: '0', value: 0 }}
              menuPlacement='top'
            />

            {filingStatus === 'single' && (dependencyCount ?? 0) > 0 && (
              <>
                <p className='info-message'>
                  If your dependents are related to you, claiming Head of Household status will reduce the taxes you pay.
                </p>
              </>)
            }
          </div>

          < div className='tax-confirm-button'>
            <PurpleButton label='Continue' width='100%' buttonClick={handleButtonClick} />
            <AfterEstimatedTax
              handleNext={handleNext}
              handleBack={handleBack}
              isOpen={isAfterTaxes}
              setIsOpen={setIsAfterTaxes}
              position='After'
              value={afterTaxAmount as number}
            />
          </div>
        </div>
      </div>
    </div >
  )
})

export default Taxes
