import React, { Context, useContext, useEffect, type Dispatch, type SetStateAction } from 'react'
import { Button, Modal } from '@mui/material'

import './styles.sass'
import { FormValues } from '../../../FormValuesInterfaces'

interface Props {
  modalOpen?: boolean
  step?: number
  setModalOpen?: Dispatch<SetStateAction<boolean>>
  handleSaveAndBack: () => void
  formValuesContext?: Context<FormValues>
  setValues?: Dispatch<FormValues>
}

const BackButtonModal: React.FC<Props> = (props) => {
  const { modalOpen, setModalOpen, handleSaveAndBack, step, formValuesContext, setValues } = props
  const formValues = formValuesContext ? useContext(formValuesContext) : undefined
  const handleModalClose = (): void => {
    setModalOpen?.(false)
  }

  const handleKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'Enter') {
      handleSaveAndBack()
    }
  }

  useEffect(() => {
    if (modalOpen) {
      window.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [modalOpen, handleSaveAndBack])

  const handleResetPayDetail = () => {
    if (formValues && setValues) {
      const formValuesUpdated = {
        ...formValues,
        currentWorkweek: {
          0: {
            0: formValues.initialCurrentWorkweek[0][0]
          }
        },
        overtimeWorkweek: {
          0: {
            0: 0
          }
        }
      }

      setValues(formValuesUpdated)
    }
    setModalOpen?.(false)
  }

  return (
    <Modal open={modalOpen ?? false} onClose={handleModalClose}>
      <div className='modal-inner-content'>
        <p>Do you want to save your inputs before going back?</p>
        {step === 2 && (
          <p>
            The Home Screen will show your wages without any changes in scheduled hours. Do you want to save your inputs before viewing original compensation?
          </p>
        )}
        {step === 2 && (
          <Button size='small' className='reset-pay-details' onClick={handleResetPayDetail}>
            Reset Pay Details
          </Button>
        )}
        <div className='buttons-container'>
          <Button size='small' onClick={handleSaveAndBack}>
            Save & Back
          </Button>
          <Button size='small' onClick={handleModalClose}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default BackButtonModal
