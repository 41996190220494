import React, { useState } from 'react'
import { LockOutlined, SecurityOutlined } from '@material-ui/icons'

import PrivacyPolicyModal from './PrivacyPolicyModal'
import '../component_styles/Privacy.sass'
import { MAX_OUTER_WIDTH } from '../Constants'
import PrivacyBanner from './PrivacyBanner'

const Privacy = (): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <div className={`${outerWidth > MAX_OUTER_WIDTH ? 'content' : ''}`}>
      <div className='content privacy-main-div'>
        <div className='privacy-div'>
          <h3>Your privacy is our absolute first priority.</h3>
          <a href={'/privacy_policy'} target='_blank'>Review our privacy policy</a>
        </div>
        {outerWidth > MAX_OUTER_WIDTH && <div className='privacy-info'>
          <div>
            <LockOutlined />
            <div>
              <h5>
                We treat your data like it&apos;s our own.
              </h5>
              <p>
                We don&apos;t share your personal information with unaffiliated third parties for their own advertising or marketing purposes.
              </p>
            </div>
          </div>

          <div>
            <SecurityOutlined />
            <div>
              <h5>
                We take your security seriously.
              </h5>
              <p>
                We use 128-bit or higher encryption to protect during the transmission of your data to our site.
              </p>
            </div>
          </div>
        </div>}
      </div>

      <PrivacyPolicyModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <PrivacyBanner />
    </div >
  )
}

export default Privacy
