import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'

const PrivacyBanner = (): JSX.Element | null => {
  const [bannerVisible, setBannerVisible] = useState(false)

  useEffect(() => {
    const consent = Cookies.get('privacy-consent')
    if (!consent) {
      setBannerVisible(true)
    }
  }, [])

  const handleAccept = (): void => {
    Cookies.set('privacy-consent', 'accepted', { expires: 365 })
    setBannerVisible(false)
  }

  const handleReject = (): void => {
    Cookies.set('privacy-consent', 'rejected', { expires: 365 })
    setBannerVisible(false)
  }

  if (!bannerVisible) {
    return null
  }

  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        backgroundColor: '#f4f5f7',
        boxShadow: '0 -2px 6px rgba(0, 0, 0, 0.1)',
        padding: '10px 16px',
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: "'Poppins', sans-serif",
        fontSize: '0.8rem',
        lineHeight: '1.4',
      }}
    >
      <div
        style={{
          maxWidth: '1200px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 16px',
        }}
      >
        <div style={{ flex: 1, marginBottom: '8px' }}>
          <h3 style={{ margin: 0, fontSize: '0.9rem', fontWeight: '500', color: '#333' }}>
            Notice
          </h3>
          <p style={{ margin: '6px 0', fontWeight: '400', color: '#555' }}>
            We and selected third parties collect personal information as specified in the{' '}
            <a
              href="/privacy_policy"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: '#6a1b9a',
                textDecoration: 'underline',
                fontWeight: '500',
              }}
            >
              privacy policy
            </a>{' '}
            and use cookies or similar technologies for technical purposes and, with your consent, for other purposes as specified.
          </p>
          <p style={{ margin: '6px 0', fontWeight: '400', color: '#555' }}>
            Use the “Accept” button to consent. Use the “Reject” button or close this notice to continue without accepting.
          </p>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '8px',
            flexDirection: 'row',
          }}
        >
          <button
            style={{
              padding: '8px 16px',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              fontSize: '0.8rem',
              backgroundColor: '#0C7455',
              color: '#fff',
              fontFamily: "'Poppins', sans-serif",
              fontWeight: '500',
              transition: 'background-color 0.3s',
            }}
            onClick={handleAccept}
          >
            Accept
          </button>
          <button
            style={{
              padding: '8px 16px',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              fontSize: '0.8rem',
              backgroundColor: '#4E49B7',
              color: '#fff',
              fontFamily: "'Poppins', sans-serif",
              fontWeight: '500',
              transition: 'background-color 0.3s',
            }}
            onClick={handleReject}
          >
            Reject
          </button>
        </div>
      </div>
    </div>
  )
}

export default PrivacyBanner
