import React from 'react'

import './styles.sass'
import PrivacyBanner from '../../PrivacyBanner'

const PrivacyPolicy = (): JSX.Element => {

  return (
    <div className='privacy-modal'>
      <p>Your privacy is our absolute first priority.</p>
      <a href={'/privacy_policy'} target='_blank' >Review our privacy policy</a>
      <PrivacyBanner />
    </div>
  )
}
export default PrivacyPolicy
