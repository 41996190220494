import React, { useState, useContext, type ChangeEvent } from 'react'
import { Checkbox, Divider, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import Select from 'react-select'

import InputField from './InputField'
import {
  getMoneyMakingObject,
  convertObjectToArray,
  ordinalSuffixOf,
  getSelectdValue,
  scrollToTop,
  calculateHoursPerMonth
} from '../../Helper'
import { DURATION_OPTIONS, TIPS_DURATION_OPTIONS, MAX_TRAVEL_DAYS, AGES_IN_MONTHS, SNAP_MIN_HOURS } from '../../Constants'

import '../../component_styles/BenefitsCliff.sass'
import { type StepProps } from '../../interfaces'
import NavigationButtons from './NavigationButtons'
import { type FormValues } from '../../FormValuesInterfaces'
import { type JobDetails, type Adults } from '../AdultsInterface'

const _ = require('lodash')

const StepThree: React.FC<StepProps> = (props) => {
  let formValues: FormValues = useContext(props.formValuesContext)
  const adultCount = formValues?.adultsCount
  const [savings, setSavings] = useState(formValues?.savings ?? 0)
  const [currentWage, setCurrentWage] = useState(formValues?.currentWage ?? {})
  const [currentPayscale, setCurrentRoutine] = useState(formValues?.currentPayscale ?? {})
  const [currentWorkweek, setCurrentWorkweek] = useState(formValues?.currentWorkweek ?? {})
  const [overtimeAvailable, setOvertimeAvailable] = useState(formValues?.overtimeAvailable ?? {})
  const [overtimeWage, setOvertime] = useState(formValues?.overtimeWage ?? {})
  const [overtimePayscale, setOvertimePayscale] = useState(formValues?.overtimePayscale ?? {})
  const [overtimeWorkweek, setOvertimeWorkweek] = useState(formValues?.overtimeWorkweek ?? {})
  const [tipsAvailable, setTipsAvailable] = useState(formValues?.tipsAvailable ?? {})
  const [tipsAmount, setTips] = useState(formValues?.tipsAmount ?? {})
  const [tipsPayscale, setTipsPayscale] = useState(formValues?.tipsPayscale ?? {})
  const [travelPerWeek, setTravelPerWeek] = useState(formValues?.travelPerWeek ?? {})
  const [trainingPerWeek, setTrainingPerWeek] = useState(formValues?.trainingPerWeek ?? {})
  const [isChanged, setIsChanged] = useState(false)
  const [adults, setAdults] = useState(formValues.adults)
  const [showNote, setShowNote] = useState(false);
  const saveUpdatedData = (): void => {
    props.setValues({
      ...formValues,
      adults: { ...adults },
      travelPerWeek,
      currentWage,
      currentPayscale,
      currentWorkweek,
      overtimeAvailable,
      overtimeWage,
      overtimePayscale,
      overtimeWorkweek,
      tipsAvailable,
      tipsAmount,
      tipsPayscale,
      trainingPerWeek,
      currentIncome: getMoneyMakingObject(currentWage, currentPayscale, currentWorkweek, adultCount),
      savings
    })
  }

  const handleSubmit = (): void => {
    props.handleNext()
    saveUpdatedData()
  }

  const setIfUndefined = (obj: object, path: string[], defaultValue: number | string | boolean): void => {
    const value = _.get(obj, path)
    if (!value || value != defaultValue) {
      _.set(obj, path, defaultValue)
    }
  }

  const renderCurrentJobInputs = (value: number, adult: any, key: number): JSX.Element => {
    setIfUndefined(adults, [adult, 'currentJobs', value, 'currentWage'], _.get(currentWage, [adult, value]) ?? 0)
    setIfUndefined(adults, [adult, 'currentJobs', value, 'currentPayscale'], _.get(currentPayscale, [adult, value]) ?? 'hour')
    setIfUndefined(adults, [adult, 'currentJobs', value, 'currentWorkweek'], _.get(currentWorkweek, [adult, value]) ?? 0)
    setIfUndefined(adults, [adult, 'currentJobs', value, 'overtimeAvailable'], _.get(overtimeAvailable, [adult, value]) ?? 'no')
    setIfUndefined(adults, [adult, 'currentJobs', value, 'overtimeWage'], _.get(overtimeWage, [adult, value]) ?? 0)
    setIfUndefined(adults, [adult, 'currentJobs', value, 'overtimePayscale'], _.get(overtimePayscale, [adult, value]) ?? 'hour')
    setIfUndefined(adults, [adult, 'currentJobs', value, 'overtimeWorkweek'], _.get(overtimeWorkweek, [adult, value]) ?? 0)
    setIfUndefined(adults, [adult, 'currentJobs', value, 'tipsAvailable'], _.get(tipsAvailable, [adult, value]) ?? 'no')
    setIfUndefined(adults, [adult, 'currentJobs', value, 'tipsAmount'], _.get(tipsAmount, [adult, value]) ?? 0)
    setIfUndefined(adults, [adult, 'currentJobs', value, 'tipsPayscale'], _.get(tipsPayscale, [adult, value]) ?? 'week')
    return (
      <div key={key}>
        <div className='financial-input'>
          <b>{`${ordinalSuffixOf(value + 1)} Job: `}</b>
          <div className='input-with-text d-flex-on-mobile'>
            <p className='input-field-text'>$</p>
            <div className='desktop-display'>
              <InputField
                onChange={(inputValue: number | string) => {
                  _.set(adults, [adult, 'currentJobs', value, 'currentWage'], inputValue)
                  setCurrentWage({ ...currentWage, [adult]: { ...currentWage[adult], [value]: Number(inputValue) } })
                  setIsChanged(true)
                  if (value === 0 && adult ==0) {
                    setShowNote(true)
                  }
                }}
                className='mobile-width'
                label='Dollars Earned'
                value={_.get(currentWage, [adult, value])}
                type='tel'
              />
              <p className='input-field-text'>per</p>
            </div>
          </div>

          <Select
            className='basic-single mobile-width'
            classNamePrefix='select'
            placeholder={'Work Rountine'}
            onChange={(e) => {
              _.set(adults, [adult, 'currentJobs', value, 'currentPayscale'], e.value)
              setCurrentRoutine({ ...currentPayscale, [adult]: { ...currentPayscale[adult], [value]: e.value } })
              setIsChanged(true)
              if (value === 0 && adult ==0) {
                setShowNote(true)
              }
            }}
            isSearchable={false}
            name='state'
            options={DURATION_OPTIONS}
            value={_.get(currentPayscale, [adult, value])
              ? getSelectdValue(_.get(currentPayscale, [adult, value]), DURATION_OPTIONS)
              : DURATION_OPTIONS[0]
            }
          />

          <div className='input-with-text padding-on-mobile'>
            <p className='input-field-text'>and work</p>
            <InputField
              onChange={(inputValue: number | string) => {
                _.set(adults, [adult, 'currentJobs', value, 'currentWorkweek'], inputValue)
                setCurrentWorkweek({ ...currentWorkweek, [adult]: { ...currentWorkweek[adult], [value]: Number(inputValue) } })
                setIsChanged(true)
                if (value === 0 && adult ==0) {
                  setShowNote(true)
                }
              }}
              className='mobile-width'
              label='Work hours/week'
              value={_.get(currentWorkweek, [adult, value])}
              type='tel'
            />
            <p className='input-field-text'>hours per week</p>
          </div>
        </div>

        {_.get(adults, [adult, 'currentJobs', value, 'overtimeAvailable']) === 'yes' &&
          <div className='financial-input'>
            <b>Overtime:</b>
            <div className='input-with-text d-flex-on-mobile'>
              <p className='input-field-text'>$</p>
              <div className='desktop-display'>
                <InputField
                  onChange={(inputValue: number | string) => {
                    _.set(adults, [adult, 'currentJobs', value, 'overtimeWage'], inputValue)
                    setOvertime({ ...overtimeWage, [adult]: { ...overtimeWage[adult], [value]: Number(inputValue) } })
                    setIsChanged(true)
                    if (value === 0 && adult ==0) {
                      setShowNote(true)
                    }
                  }}
                  className='mobile-width'
                  label='Dollars Earned'
                  value={_.get(overtimeWage, [adult, value])}
                  type='tel'
                />
                <p className='input-field-text'>per</p>
              </div>
            </div>

            <Select
              className='basic-single mobile-width'
              classNamePrefix='select'
              onChange={(e) => {
                _.set(adults, [adult, 'currentJobs', value, 'overtimePayscale'], e.value)
                setOvertimePayscale({ ...overtimePayscale, [adult]: { ...overtimePayscale[adult], [value]: e.value } })
                setIsChanged(true)
              }}
              isSearchable={false}
              options={DURATION_OPTIONS}
              value={_.get(overtimePayscale, [adult, value])
                ? getSelectdValue(_.get(overtimePayscale, [adult, value]), DURATION_OPTIONS)
                : DURATION_OPTIONS[0]
              }
            />

            <div className='input-with-text padding-on-mobile'>
              <p className='input-field-text'>and work</p>
              <InputField
                onChange={(inputValue: number | string) => {
                  _.set(adults, [adult, 'currentJobs', value, 'overtimeWorkweek'], inputValue)
                  setOvertimeWorkweek({ ...overtimeWorkweek, [adult]: { ...overtimeWorkweek[adult], [value]: Number(inputValue) } })
                  setIsChanged(true)
                  if (value === 0 && adult ==0) {
                    setShowNote(true)
                  }
                }}
                className='mobile-width'
                label='Work hours/week'
                value={_.get(overtimeWorkweek, [adult, value])}
                type='tel'
              />
              <p className='input-field-text'>hours per week</p>
            </div>
          </div>
        }

        {_.get(adults, [adult, 'currentJobs', value, 'tipsAvailable']) === 'yes' &&
          <div className='tips-income-input' key={key}>
            <b className='tips-label'>Tips:</b>
            <div className='input-with-text d-flex-on-mobile'>
              <p className='input-field-text'>$</p>
              <div className='desktop-display'>
                <InputField
                  onChange={(inputValue: number | string) => {
                    _.set(adults, [adult, 'currentJobs', value, 'tipsAmount'], inputValue)
                    setTips({ ...tipsAmount, [adult]: { ...tipsAmount[adult], [value]: Number(inputValue) } })
                    setIsChanged(true)
                  }}
                  className='mobile-width'
                  label='Dollars Earned'
                  value={_.get(tipsAmount, [adult, value])}
                  type='tel'
                />
                <p className='input-field-text'>per</p>
              </div>
            </div>

            <Select
              className='basic-single mobile-width'
              classNamePrefix='select'
              onChange={(e) => {
                _.set(adults, [adult, 'currentJobs', value, 'tipsPayscale'], e.value)
                setTipsPayscale({ ...tipsPayscale, [adult]: { ...tipsPayscale[adult], [value]: e.value } })
                setIsChanged(true)
                if (value === 0 && adult ==0) {
                  setShowNote(true)
                }
              }}
              isSearchable={false}
              options={TIPS_DURATION_OPTIONS}
              value={_.get(tipsPayscale, [adult, value])
                ? getSelectdValue(_.get(tipsPayscale, [adult, value]), TIPS_DURATION_OPTIONS)
                : TIPS_DURATION_OPTIONS[0]
              }
            />
          </div>
        }

        <div className='income-radio-buttons'>
          <RadioGroup
            onChange={(e) => {
              _.set(adults, [adult, 'currentJobs', value, 'overtimeAvailable'], e.target.value)
              setOvertimeAvailable({ ...overtimeAvailable, [adult]: { ...overtimeAvailable[adult], [value]: e.target.value } })
              setIsChanged(true)

              if (e.target.value === 'no') {
                _.set(adults, [adult, 'currentJobs', value, 'overtimeWage'], 0)
                setOvertime({ ...overtimeWage, [adult]: { ...overtimeWage[adult], [value]: 0 } })
                setOvertimePayscale({ ...overtimePayscale, [adult]: { ...overtimePayscale[adult], [value]: DURATION_OPTIONS[0].value } })
                setOvertimeWorkweek({ ...overtimeWorkweek, [adult]: { ...overtimeWorkweek[adult], [value]: 0 } })
              }
            }}
          >
            <b>Overtime Available: </b>
            <FormControlLabel value='yes' control={<Radio checked={_.get(adults, [adult, 'currentJobs', value, 'overtimeAvailable']) === 'yes'} />} label='Yes' />
            <FormControlLabel value='no' control={<Radio checked={_.get(adults, [adult, 'currentJobs', value, 'overtimeAvailable']) === 'no'} />} label='No' />
          </RadioGroup>

          <RadioGroup
            onChange={(e) => {
              _.set(adults, [adult, 'currentJobs', value, 'tipsAvailable'], e.target.value)
              setTipsAvailable({ ...tipsAvailable, [adult]: { ...tipsAvailable[adult], [value]: e.target.value } })
              setIsChanged(true)

              if (e.target.value === 'no') {
                _.set(adults, [adult, 'currentJobs', value, 'tipsAmount'], 0)
                setTips({ ...tipsAmount, [adult]: { ...tipsAmount[adult], [value]: 0 } })
                setTipsPayscale({ ...tipsPayscale, [adult]: { ...tipsPayscale[adult], [value]: TIPS_DURATION_OPTIONS[0].value } })
              }
            }}
          >
            <b>Tips Available: </b>
            <FormControlLabel value='yes' control={<Radio checked={_.get(adults, [adult, 'currentJobs', value, 'tipsAvailable']) === 'yes'} />} label='Yes' />
            <FormControlLabel value='no' control={<Radio checked={_.get(adults, [adult, 'currentJobs', value, 'tipsAvailable']) === 'no'} />} label='No' />
          </RadioGroup>
          {formValues.b2bCalculator && showNote && key == 0 && adult == 0 &&  (
            <p className='b2b-job-change-notes'>
              <b>Please Note:</b> Modeling different wages or hours are not guaranteed changes to compensation from your employer.
            </p>
          )}

        </div>

        {
          calculateHoursPerMonth(adults[adult]?.currentJobs as JobDetails[]) < SNAP_MIN_HOURS &&
            ['pt_student', 'ft_student'].includes(formValues.adults[adult].student as string) &&
            ((formValues.adults[adult].isFourYearCollegeEnrolled && !formValues.adults[adult].isFederalWorkStudyEnrolled) || (
              !formValues.adults[adult].isHighSchoolEnrolled && !formValues.adults[adult].isHSEDEnrolled &&
              !formValues.adults[adult].isELLorABEEnrolled && !formValues.adults[adult].isVocationalTrainingEnrolled &&
              !formValues.adults[adult].isTechnicalCollegeEnrolled && !formValues.adults[adult].isOnTheJobTrainingEnrolled &&
              !formValues.adults[adult].isFourYearCollegeEnrolled && !formValues.adults[adult].isFederalWorkStudyEnrolled))
            ? renderSubqustionsForUnemployed(adult)
            : resetSubquestionsForUnemployed(adult)
        }
      </div>
    )
  }

  const SubquestionsForUnEmployed = {
    isVetern: 'Veteran',
    wasInFosterCare: '18 - 24-year-old who was in foster care, a subsidized guardianship, or court-ordered kinship care on 18th birthday.',
    isPrimaryCaretaker: 'Primary caretaker for a person who cannot care for themselves (whether the person lives in your home or out of your home).',
    isUnableToWork: 'Physically or mentally unable to work.',
    isPrimaryCaretakerForChild: 'Primary caretaker for a dependent child under 13 (if you and another person both have parental control of the child, only one of you can be exempt from the FoodShare work requirement as the primary caregiver of that child',
    isRecievingUnemploymentCompensation: 'Receiving or have applied for unemployment compensation.',
    isPartOfRehabilitationProgram: 'Taking part in an alcohol or other drug abuse (AODA) treatment or rehabilitation program.'
  }

  const resetSubquestionsForUnemployed = (index: number): void => {
    resetSubquestionsForPrimaryCaretaker(index)
    Object.keys(SubquestionsForUnEmployed).forEach(property => _.set(adults, [index, property], false))
  }

  const resetSubquestionsForPrimaryCaretaker = (index: number): void => {
    _.set(adults, [index, 'dependentChildLivesInHome'], 'no')
    _.set(adults, [index, 'isChildCareAvailable'], false)
    _.set(adults, [index, 'dependentChildAge'], -1)
    _.set(adults, [index, 'isSingleParent'], false)
  }

  const renderSubqustionsForPrimaryCaretakerForChild = (index: number): JSX.Element => {
    setIfUndefined(adults, [String(index), 'dependentChildLivesInHome'], adults[index].dependentChildLivesInHome ?? 'no')
    setIfUndefined(adults, [String(index), 'isChildCareAvailable'], adults[index].isChildCareAvailable ?? false)
    setIfUndefined(adults, [String(index), 'isSingleParent'], adults[index].isSingleParent ?? false)

    return (
      <div className='primary-caretaker-questions'>
        <RadioGroup
          onChange={(e) => {
            setAdults((prevValue: Record<string, Adults>) => ({ ...prevValue, [index]: { ...prevValue[index], dependentChildLivesInHome: e.target.value } }))
            setIsChanged(true)
          }}
          className='primary-caretaker-radio-group'
        >
          <span>Does this child live in the home?:</span>
          <FormControlLabel value='yes' control={<Radio checked={adults[index].dependentChildLivesInHome === 'yes'} size='small' />} label='Yes' />
          <FormControlLabel value='no' control={<Radio checked={adults[index].dependentChildLivesInHome === 'no'} size='small' />} label='No' />
        </RadioGroup>

        <div className='step-three-input' key={index}>
          <p className='child-age-question'>How old is the child or youngest child?</p>
          <Select
            className='basic-single'
            classNamePrefix='select'
            placeholder={'Select Age'}
            onChange={(e) => {
              setAdults((prevValue: Record<string, Adults>) => ({ ...prevValue, [index]: { ...prevValue[index], dependentChildAge: e?.value } }))
              setIsChanged(true)
            }}
            isSearchable={false}
            name='state'
            options={AGES_IN_MONTHS}
            value={AGES_IN_MONTHS[adults[index]?.dependentChildAge as number]}
          />
        </div>

        {adults[index]?.dependentChildAge as number >= 6 &&
          <FormControlLabel
            key={index}
            labelPlacement='start'
            onChange={(e: ChangeEvent<{}>) => {
              const Checkbox = e.target as HTMLInputElement
              setAdults((prevValue) => ({ ...prevValue, [index]: { ...prevValue[index], isChildCareAvailable: Checkbox.checked } }))
              setIsChanged(true)
            }}
            control={<Checkbox checked={adults[index].isChildCareAvailable ?? false} size='small' />}
            label={'Is adequate child care available'}
          />}

        {adults[index].student === 'ft_student' &&
          <FormControlLabel
            key={index}
            labelPlacement='start'
            onChange={(e: ChangeEvent<{}>) => {
              const Checkbox = e.target as HTMLInputElement
              setAdults((prevValue) => ({ ...prevValue, [index]: { ...prevValue[index], isSingleParent: Checkbox.checked } }))
              setIsChanged(true)
            }}
            control={<Checkbox checked={adults[index].isSingleParent ?? false} size='small' />}
            label={'Is this adult a single parent'}
          />}
      </div>
    )
  }

  const renderUnemployedCheckbox = (index: number, value: string, label: string, disabled: boolean): JSX.Element => (
    <>
      <FormControlLabel
        key={index}
        labelPlacement='end'
        onChange={(e: ChangeEvent<{}>) => {
          const Checkbox = e.target as HTMLInputElement
          if (value === 'isPrimaryCaretakerForChild' && !Checkbox.checked) {
            resetSubquestionsForPrimaryCaretaker(index)
          }

          setAdults((prevValue) => ({ ...prevValue, [index]: { ...prevValue[index], [value]: Checkbox.checked } }))
          setIsChanged(true)
        }}
        control={<Checkbox checked={adults[index][value as keyof Adults] as boolean ?? false} size='small' />}
        label={label}
        disabled={disabled}
        className='check-box-index'
      />

      {value === 'isPrimaryCaretakerForChild' && adults[index].isPrimaryCaretakerForChild &&
        renderSubqustionsForPrimaryCaretakerForChild(index)
      }
    </>
  )

  const renderSubqustionsForUnemployed = (index: number): JSX.Element => {
    return (
      <>
        <p><b>Are any of the following true of {index === 0 ? 'You' : formValues.adults[index].name}</b></p>
        <p><i>Check all that apply.</i></p>
        <div className='unemployed-checkboxes'>
          {Object.entries(SubquestionsForUnEmployed).map(([key, value]) =>
            renderUnemployedCheckbox(index, key, value, key === 'wasInFosterCare' ? (formValues.adults[index].age < 18 || formValues.adults[index].age > 24) : false))
          }
        </div>
      </>
    )
  }

  const renderCurrentFinancialInputsByPerson = (adultIndex: number): JSX.Element => (
    <React.Fragment key={adultIndex}>
      <p>
        <b>
          {`${adultIndex < 1 ? 'My' : `${formValues?.adults?.[adultIndex]?.name}’s`} Job Details:`}
        </b>
        {formValues?.adults?.[adultIndex]?.jobs || 0 > 0 && (
          <p>
            Currently, on average and before taxes {`${adultIndex < 1 ? 'I' : formValues?.adults?.[adultIndex]?.name || ''}`} make:
          </p>
        )}
        {formValues?.adults?.[adultIndex]?.jobs === 0 && 'Unemployed'}
      </p>


      {formValues.childrenCount === 0 && formValues.adults[adultIndex].jobs === 0 && !formValues.adults[adultIndex].pregnant
        ? renderSubqustionsForUnemployed(adultIndex)
        : (
          <>
            {convertObjectToArray(formValues?.jobsDetails[adultIndex]).map((jobIndex: number) =>
              renderCurrentJobInputs(jobIndex, adultIndex, jobIndex)
            )}
          </>
        )
      }
    </React.Fragment>
  )

  const renderTravelInputs = (value: number): JSX.Element => (
    <div className='travel-inputs' key={value}>
      {`Days ${value < 1 ? `I commute` : `${formValues.adults[value]?.name} commutes`} for work or training:`}
      <InputField
        onChange={(inputValue: number | string) => {
          _.set(adults, [value, 'transdaysWeek'], inputValue)
          setTravelPerWeek({ ...travelPerWeek, [value]: { ...travelPerWeek[value], travelBefore: Number(inputValue) } })
          setIsChanged(true)
        }}
        label='Days per week'
        value={_.get(travelPerWeek, [value, 'travelBefore'])}
        maxLimit={MAX_TRAVEL_DAYS}
        type='tel'
      />
    </div>
  )

  const renderAdultTrainingCheckboxes = (index: number): JSX.Element => {
    _.get(adults, [index, 'jobTrainingProgram']) === undefined &&
      _.set(adults, [index, 'jobTrainingProgram'], false)

    const name = index < 1 ? 'Myself' : adults[index]?.name || `Adult ${index}`

    return (
      <FormControlLabel
        key={index}
        label={name}
        labelPlacement='bottom'
        control={
          <Checkbox
            checked={adults[index]?.jobTrainingProgram || false}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setAdults((prevValue) => ({ ...prevValue, [index]: { ...prevValue[index], jobTrainingProgram: event.target.checked } }))
              setIsChanged(true)

              if (!event.target.checked) {
                setAdults((prevValue) => ({ ...prevValue, [index]: { ...prevValue[index], traininghours: 0 } }))
              }
            }}
          />
        }
      />
    )
  }

  const renderTrainginglInputs = (value: number): JSX.Element => {
    return (
      <div className='travel-inputs' key={value}>
        <p className='user-name-field'>{`Hours ${value < 1 ? 'I' : formValues.adults[value]?.name}  spends in training or work experience programming (do not include any employment hours entered above) :`}</p>
        <InputField
          onChange={(inputValue: number | string) => {
            _.set(adults, [value, 'traininghours'], inputValue)
            setTrainingPerWeek({ ...trainingPerWeek, [value]: { ...trainingPerWeek[value], traininghours: inputValue } })
            setIsChanged(true)
          }}
          label='Hours per/week'
          value={_.get(trainingPerWeek, [value, 'traininghours'])}
          type='tel'
        />
      </div>
    )
  }

  const renderSavings = (): JSX.Element => (
    <>
      <b>Savings:</b>
      <div className='savings'>
        <p>Amount of family savings (in checking or savings account):</p>
        <div>
          <p className='input-field-text'>$</p>
          <InputField
            setValue={setSavings}
            setIsChanged={setIsChanged}
            value={savings}
            type='tel'
          />
        </div>
      </div>
    </>
  )

  const renderAdultCheckboxes = (index: number, value: string, amount: string): JSX.Element => {
    _.get(adults, [index, value]) === undefined && _.set(adults, [index, value], false)

    return (
      <FormControlLabel
        key={index}
        labelPlacement='bottom'
        onChange={(e: ChangeEvent<{}>) => {
          const Checkbox = e.target as HTMLInputElement
          if (!Checkbox.checked) {
            setAdults((prevValue: Record<number, Adults>) => ({ ...prevValue, [index]: { ...prevValue[index], [amount]: 0 } }))

            if (amount === 'unemploymentIncome') {
              setAdults((prevValue: Record<number, Adults>) => ({ ...prevValue, [index]: { ...prevValue[index], unemploymentWeeks: 0 } }))
            }
          }

          setAdults((prevValue: Record<number, Adults>) => ({ ...prevValue, [index]: { ...prevValue[index], [value]: Checkbox.checked } }))
          setIsChanged(true)
        }}
        control={<Checkbox checked={adults[index][value as keyof Adults] as boolean ?? false} />}
        label={index < 1 ? 'Me' : adults[index]?.name}
        className='check-box-index'
      />
    )
  }

  const renderSingleOtherSoucesInputField = (index: number, value: string): JSX.Element => {
    _.get(adults, [index, value]) === undefined && _.set(adults, [index, value], 0)

    return (
      <div>
        <p className='input-field-text'>$</p>
        <InputField
          onChange={(inputValue: number | string) => {
            setAdults((prevValue) => ({ ...prevValue, [index]: { ...prevValue[index], [value]: inputValue } }))
            setIsChanged(true)
          }}
          setIsChanged={setIsChanged}
          value={adults[index][value as keyof Adults] as number}
          label='Amount'
          type='tel'
        />
        <p className='input-field-text'>per month</p>
      </div>
    )
  }

  const renderOtherSourcesInputField = (value: string, hasValue: string): JSX.Element => (
    <>
      {formValues.isMarried || formValues.adultsCount < 2
        ? renderSingleOtherSoucesInputField(0, value)
        : Object.keys(adults).map((_adult, index: number) => adults[index][hasValue as keyof Adults] &&
          <div key={index}>
            <p>{`${index < 1 ? 'Me' : formValues.adults[index]?.name}:`}</p>
            {renderSingleOtherSoucesInputField(index, value)}
          </div>
        )
      }
    </>
  )

  const renderSingleUnemploymentInputField = (index: number): JSX.Element => {
    _.get(adults, [index, 'unemploymentIncome']) === undefined && _.set(adults, [index, 'unemploymentIncome'], 0)
    _.get(adults, [index, 'unemploymentWeeks']) === undefined && _.set(adults, [index, 'unemploymentWeeks'], 0)

    return (
      <div className='unemployment-income-fields'>
        <div>
          <p className='input-field-text'>$</p>
          <InputField
            onChange={(inputValue: number | string) => {
              setAdults((prevValue: Record<string, Adults>) => ({ ...prevValue, [index]: { ...prevValue[index], unemploymentIncome: inputValue } }))
              setIsChanged(true)
            }}
            setIsChanged={setIsChanged}
            value={adults[index]?.unemploymentIncome as number}
            label='Amount'
            type='tel'
          />
          <p className='input-field-text'>weekly</p>
        </div>

        <div className='total-weeks-field'>
          <InputField
            onChange={(inputValue: number | string) => {
              setAdults((prevValue: Record<string, Adults>) => ({ ...prevValue, [index]: { ...prevValue[index], unemploymentWeeks: inputValue } }))
              setIsChanged(true)
            }}
            setIsChanged={setIsChanged}
            value={adults[index]?.unemploymentWeeks as number}
            label='weeks'
            type='tel'
          />
          <p className='input-field-text' style={{ marginLeft: '0px' }}>total weeks</p>
        </div>
      </div>
    )
  }

  const renderUnEmploymentInputField = (): JSX.Element => (
    <>
      {formValues.isMarried || formValues.adultsCount < 2
        ? renderSingleUnemploymentInputField(0)
        : Object.keys(adults).map((_adult, index: number) => (adults[index].hasUnemploymentIncome || (formValues.isMarried && index === 0)) &&
          <div key={index}>
            <p>{`${index < 1 ? 'Me' : formValues.adults[index]?.name}:`}</p>
            {renderSingleUnemploymentInputField(index)}
          </div>
        )
      }
    </>
  )

  const renderOtherSourcesInputs = (): JSX.Element => (
    <>
      {Object.keys(formValues.children).length > 0 &&
        <div className={formValues.isMarried || formValues.adultsCount < 2 ? 'other-sources' : 'other-sources-split'}>
          <div className='other-sources-checkboxes'>
            <p className='other-sources-title'> Formal, court-ordered child support:</p>
            <div>
              {!formValues.isMarried && formValues.adultsCount > 1 && convertObjectToArray(formValues?.adultsCount).map((_adult, index: number) =>
                renderAdultCheckboxes(index, 'hasChildSupport', 'childSupportPaidM'))}
            </div>
          </div>

          {renderOtherSourcesInputField('childSupportPaidM', 'hasChildSupport')}
        </div>
      }

      <div className={formValues.isMarried || formValues.adultsCount < 2 ? 'other-sources' : 'other-sources-split'}>
        <div className='other-sources-checkboxes'>
          <p className='other-sources-title'>Formal or court-ordered alimony:</p>
          <div>
            {!formValues.isMarried && formValues.adultsCount > 1 && convertObjectToArray(formValues?.adultsCount).map((_adult, index: number) =>
              renderAdultCheckboxes(index, 'hasAlimony', 'alimonyPaidM'))}
          </div>
        </div>

        {renderOtherSourcesInputField('alimonyPaidM', 'hasAlimony')}
      </div>

      <div className={formValues.isMarried || formValues.adultsCount < 2 ? 'other-sources' : 'other-sources-split'}>
        <div className='other-sources-checkboxes'>
          <p className='other-sources-title'>Amount received from any other source of income:</p>
          <div>
            {!formValues.isMarried && formValues.adultsCount > 1 && convertObjectToArray(formValues?.adultsCount).map((_adult, index: number) =>
              renderAdultCheckboxes(index, 'hasOtherIncome', 'otherIncomeM'))}
          </div>
        </div>

        {renderOtherSourcesInputField('otherIncomeM', 'hasOtherIncome')}
      </div>

      <div className={formValues.isMarried || formValues.adultsCount < 2 ? 'other-sources' : 'other-sources-split'}>
        <div className='other-sources-checkboxes'>
          <p className='other-sources-title'>Current gift income:</p>
          <div>
            {!formValues.isMarried && formValues.adultsCount > 1 && formValues.adultsCount > 1 && convertObjectToArray(formValues?.adultsCount).map((_adult, index: number) =>
              renderAdultCheckboxes(index, 'hasGiftIncome', 'giftIncomeM'))}
          </div>
        </div>

        {renderOtherSourcesInputField('giftIncomeM', 'hasGiftIncome')}
      </div>

      <div className={formValues.isMarried || formValues.adultsCount < 2 ? 'other-sources' : 'other-sources-split'}>
        <div className='other-sources-checkboxes'>
          <p className='other-sources-title'>Unemployment Income (UI):</p>
          <div>
            {!formValues.isMarried && formValues.adultsCount > 1 && convertObjectToArray(formValues?.adultsCount).map((_adult, index: number) =>
              renderAdultCheckboxes(index, 'hasUnemploymentIncome', 'unemploymentIncome'))}
          </div>
        </div>

        {renderUnEmploymentInputField()}
      </div>

      <div className={formValues.isMarried || formValues.adultsCount < 2 ? 'other-sources' : 'other-sources-split'}>
        <div className='other-sources-checkboxes'>
          <p className='other-sources-title'>Supplemental Security Income (SSI):</p>
          <div>
            {!formValues.isMarried && formValues.adultsCount > 1 && formValues.adultsCount > 1 && convertObjectToArray(formValues?.adultsCount).map((_adult, index: number) =>
              renderAdultCheckboxes(index, 'hasSsi', 'ssiRecdMnth'))}
          </div>
        </div>

        {renderOtherSourcesInputField('ssiRecdMnth', 'hasSsi')}
      </div>
    </>
  )

  scrollToTop()

  return (
    <>
      <div className='step-three'>
        <h3>Employment and Other Household Income</h3>
        {Object.keys(formValues?.jobsDetails).map((value: any) => renderCurrentFinancialInputsByPerson(value))}

        <Divider />

        <div className='step-three-input'>
          <p><b>Does anyone in your home participate in a job training program like W-2, FSET or a related program:</b></p>
          {Object.keys(formValues.adults).map((_, key) => renderAdultTrainingCheckboxes(key))}
        </div>

        {Object.keys(formValues.adults).map((_adult, value) => (adults[value]?.jobTrainingProgram && renderTrainginglInputs(value)))}

        <Divider />

        <b>Commute schedules</b>
        <p><i>Days you commute will be used to help estimate transportation expenses.</i></p>
        {Object.keys(formValues?.adults).map((value: any) => renderTravelInputs(value))}

        <Divider />

        {renderSavings()}

        {Object.values(formValues.adults).some((adult: Adults) => adult?.disability) && <Divider />}

        <b>Other sources of income:</b>
        {renderOtherSourcesInputs()}

      </div>

      <NavigationButtons
        handleBack={props.handleBack}
        handleSubmit={handleSubmit}
        step={props.step}
        saveUpdatedData={saveUpdatedData}
        isChanged={isChanged}
      />
    </>
  )
}

export default StepThree
