import React, { useContext, useState, type ChangeEvent } from 'react'
import { Checkbox, Divider, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@material-ui/core'
import Select from 'react-select'
import ErrorMessage from '../ErrorMessage'
import { validate } from '../../formValidations/benefitsCliff/stepTwo'

import {
  JOB_CURRENTLY_HAVE,
  GENDER_OPTIONS,
  STUDENT_STATUS
} from '../../Constants'
import {
  convertObjectToArray,
  getSelectdValue,
  getAgesOfAdults,
  getAgesOfChildren,
  scrollToTop
} from '../../Helper'

import '../../component_styles/BenefitsCliff.sass'
import { type StepProps } from '../../interfaces'
import NavigationButtons from './NavigationButtons'
import { type FormValues } from '../../FormValuesInterfaces'
import { type Adults, type JobDetails } from '../AdultsInterface'

const _ = require('lodash')

interface Adult {
  name?: string
  age: number
}

const StepTwo: React.FC<StepProps> = (props) => {
  const formValues: FormValues = useContext(props.formValuesContext)
  const [children, setChildren] = useState(formValues.children ?? {})
  const [adults, setAdults] = useState(formValues.adults ?? {})
  const [jobsDetails, setJobDetails] = useState(formValues?.jobsDetails ?? {})
  const [pregnant, setPregnant] = useState(formValues?.pregnant ?? false)
  const [homelessness, setHomelessness] = useState(formValues?.homelessness ?? false)
  const [currentlyHomeless, setCurrentlyHomeless] = useState(formValues?.currentlyHomeless ?? false)
  const [isChanged, setIsChanged] = useState(false)
  const [isRequiredFieldsEmpty, setIsRequiredFieldsEmpty] = useState(false)

  const [errors, setErrors] = useState({
    adults: false,
    children: false,
    jobsDetails: false,
    financialChange: false
  })

  if (formValues.isMarried || formValues.adultsCount < 2) {
    Object.keys(children).forEach((_child, key) => {
      children[key].parent = -1
    })
  }

  const generateAdultsOptions = (): Array<{ label: string, value: number }> => {
    const adultsCount = formValues.adultsCount ?? 0

    return Array.from({ length: formValues.adultsCount + 1 }, (_, i) => ({
      label: i === adultsCount ? `My and ${adults[i]?.name ?? `Adult 1`} 's child` : i === 0 ? 'My child only' : `${adults[i]?.name ?? `Adult ${i}`}'s child only`,
      value: i
    }))
  }

  const adultOptions = generateAdultsOptions()

  const saveUpdatedData = (): void => {
    let updatedJobsDetails = { ...jobsDetails }

    if (formValues.adults && formValues.currentWage && formValues.currentWorkweek) {
      for (let index = 0; index < formValues.adultsCount; index++) {
        const currentJobs = adults[index]?.jobs ?? 0
        const jobsCountDifference = Object.keys(formValues.adults[index]?.currentJobs ?? []).length - currentJobs
        const adultsCountDifference = Object.keys(formValues.jobsDetails).length - Object.keys(formValues.adults).length

        if (jobsCountDifference > 0) {
          formValues.adults[index]?.currentJobs && (formValues.adults[index].currentJobs = Object.values(formValues?.adults[index]?.currentJobs as JobDetails).slice(0, currentJobs))
          formValues?.currentWage[index] && (formValues.currentWage[index] = Object.fromEntries(Object.entries(formValues?.currentWage[index]).slice(0, currentJobs)))
          formValues?.currentWorkweek[index] && (formValues.currentWorkweek[index] = Object.fromEntries(Object.entries(formValues?.currentWorkweek[index]).slice(0, currentJobs)))
          formValues?.currentIncome[index] && (formValues.currentIncome[index] = Object.values(formValues?.currentIncome[index]).slice(0, currentJobs))
          formValues?.currentPayscale[index] && (formValues.currentPayscale[index] = Object.values(formValues?.currentPayscale[index]).slice(0, currentJobs))
          formValues.adults[index]?.jobs && (formValues.adults[index].jobs = currentJobs)
          formValues.overtimeWage[index] && (formValues.overtimeWage[index] = Object.values(formValues?.overtimeWage[index]).slice(0, currentJobs))
          formValues.overtimePayscale[index] && (formValues.overtimePayscale[index] = Object.values(formValues?.overtimePayscale[index]).slice(0, currentJobs))
          formValues.overtimeWorkweek[index] && (formValues.overtimeWorkweek[index] = Object.values(formValues?.overtimeWorkweek[index]).slice(0, currentJobs))
          formValues.overtimeAvailable[index] && (formValues.overtimeAvailable[index] = Object.values(formValues?.overtimeAvailable[index]).slice(0, currentJobs))
          adults[index] = formValues.adults[index]
        }

        if (adultsCountDifference > 0) {
          updatedJobsDetails = Object.fromEntries(Object.entries(updatedJobsDetails).slice(0, adultsCountDifference))
          formValues?.currentWage && (formValues.currentWage = Object.fromEntries(Object.entries(formValues?.currentWage).slice(0, adultsCountDifference)))
          formValues?.currentWorkweek && (formValues.currentWorkweek = Object.fromEntries(Object.entries(formValues?.currentWorkweek).slice(0, adultsCountDifference)))
        }
      }
    }

    props.setValues({
      ...formValues,
      children,
      adults,
      jobsDetails: Object.values(updatedJobsDetails).slice(0, formValues.adultsCount),
      pregnant,
      homelessness,
      currentlyHomeless
    })
  }

  const handleSubmit = (): void => {
    let hasErrors = false

    for (let index = 0; index < formValues.childrenCount; index++) {
      if (children[index]?.name === '' || children[index]?.name === undefined) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          ...validate('', 'children', index, 'name', prevErrors)
        }))
        setIsRequiredFieldsEmpty(true)
        hasErrors = true
      }

      if ((!formValues.isMarried && formValues.adultsCount > 1 && children[index]?.parent === undefined) || typeof children[index]?.parent === 'object') {
        setErrors((prevErrors) => ({
          ...prevErrors,
          ...validate('', 'children', index, 'parent', prevErrors)
        }))
        setIsRequiredFieldsEmpty(true)
        hasErrors = true
      }

      if (children[index]?.age === undefined || typeof children[index]?.age === 'object') {
        setErrors((prevErrors) => ({
          ...prevErrors,
          ...validate('', 'children', index, 'age', prevErrors)
        }))
        setIsRequiredFieldsEmpty(true)
        hasErrors = true
      }
    }

    for (let index = 0; index < formValues.adultsCount; index++) {
      if (adults[index]?.name === '' || adults[index]?.name === undefined) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          ...validate('', 'adults', index, 'name', prevErrors)
        }))
        setIsRequiredFieldsEmpty(true)
        hasErrors = true
      }

      if (adults[index]?.age === undefined || typeof adults[index]?.age === 'object') {
        setErrors((prevErrors) => ({
          ...prevErrors,
          ...validate('', 'adults', index, 'age', prevErrors)
        }))
        setIsRequiredFieldsEmpty(true)
        hasErrors = true
      }

      if (adults[index]?.jobs === undefined) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          ...validate('', 'adults', index, 'jobs', prevErrors)
        }))
        setIsRequiredFieldsEmpty(true)
        hasErrors = true
      }
    }

    const areAdultFieldsEmpty = Object.values<Adult>(adults).some(
      (adult: Adult) => !adult.age
    )

    if (areAdultFieldsEmpty || Object.keys(adults).length < formValues.adultsCount) {
      setIsRequiredFieldsEmpty(true)
      hasErrors = true
    }

    if (!hasErrors) {
      props.handleNext()
      saveUpdatedData()
    }
  }

  const handleOnChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, details: string, index: number, key: string): void => {
    setErrors({ ...errors, ...validate(e, details, index, key, errors) })
    setIsRequiredFieldsEmpty(false)
  }

  const renderChildInputs = (index: number): JSX.Element => (
    <>
      <div className='step-two-input multiple-inputs' key={index}>
        <p>{`Details of Child ${index + 1}:`}</p>
        <div className='errors-div'>
          <TextField
            onChange={(e) => {
              setChildren({ ...children, [index]: { ...children[index], name: e.target.value } })
              handleOnChange(e, 'children', index, 'name')
              setIsChanged(true)
            }}
            onBlur={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => { handleOnChange(e, 'children', index, 'name') }}
            required
            margin='dense'
            type={'text'}
            label='First Name / Nickname '
            variant='outlined'
            value={children[index]?.name ?? ''}
            inputProps={{ maxLength: 30 }}
          />
          <ErrorMessage text={_.get(errors, `children[${index}].name`)} />
        </div>

        <div className='errors-div width-age'>
          <Select
            placeholder={'Select Age'}
            onChange={(e) => {
              setChildren({ ...children, [index]: { ...children[index], age: e != null ? e.value : {} } })
              handleOnChange(e, 'children', index, 'age')
              setIsChanged(true)
            }}
            isClearable
            isSearchable={true}
            required
            name='state'
            options={getAgesOfChildren()}
            value={getSelectdValue(children[index]?.age, getAgesOfChildren())}
            className='select-input-container'
          />
          <ErrorMessage text={_.get(errors, `children[${index}].age`)} />
        </div>

        <div className='errors-div'>
          <Select
            placeholder={'Select Gender'}
            onChange={(e) => {
              setChildren({ ...children, [index]: { ...children[index], gender: e != null ? e.value : {} } })
              handleOnChange(e, 'children', index, 'gender')
              setIsChanged(true)
            }}
            isClearable
            isSearchable={false}
            name='state'
            options={GENDER_OPTIONS}
            value={getSelectdValue(children[index]?.gender, GENDER_OPTIONS)}
          />
          <ErrorMessage text={_.get(errors, `children[${index}].gender`)} />
        </div>

        {!formValues.isMarried && formValues.adultsCount > 1 &&
          <div className='errors-div'>
            <Select
              placeholder={'Parent/Guardian'}
              onChange={(e) => {
                setChildren({ ...children, [index]: { ...children[index], parent: e != null ? e.value : {} } })
                handleOnChange(e, 'children', index, 'parent')
                setIsChanged(true)
              }}
              isClearable
              isSearchable={false}
              name='state'
              options={adultOptions}
              value={getSelectdValue(children[index]?.parent, adultOptions)}
            />
            <ErrorMessage text={_.get(errors, `children[${index}].parent`)} />
          </div>}
      </div>

      {children[index]?.parent === formValues.adultsCount &&
        <div className='step-two-radio-group'>
          <p>Who will claim this child as dependent on their taxes?</p>
          <RadioGroup
            className='step-two-radio-button'
            onChange={(e) => {
              setChildren({ ...children, [index]: { ...children[index], dependentClaimant: e.target.value } })
              setIsChanged(true)
            }}>
            <FormControlLabel value='0' control={<Radio checked={children[index].dependentClaimant === '0'} />} label='Me' />
            <FormControlLabel value='1' control={<Radio checked={children[index].dependentClaimant === '1'} />} label={adults[1]?.name ?? `Adult 1`} />
          </RadioGroup>
        </div>}
    </>
  )

  const renderAdultInputs = (index: number): JSX.Element => (
    <div className='step-two-input multiple-inputs' key={index}>
      <p className='details-of-adults'>{index < 1 ? ' Your Details:' : ` Details of Adult ${index}:`}</p>

      <div className='errors-div'>
        <TextField
          onChange={(e) => {
            setAdults({ ...adults, [index]: { ...adults[index], name: e.target.value } })
            handleOnChange(e, 'adults', index, 'name')
            setIsChanged(true)
          }}
          onBlur={(e) => { handleOnChange(e, 'adults', index, 'name') }}
          required
          margin='dense'
          type={'text'}
          label='First Name / Nickname '
          variant='outlined'
          value={adults[index]?.name || ''}
          inputProps={{ maxLength: 30 }}
        />
        <ErrorMessage text={_.get(errors, `adults[${index}].name`)} />
      </div>

      <div className='errors-div'>
        <Select
          placeholder={'Select Age'}
          onChange={(e) => {
            setAdults({ ...adults, [index]: { ...adults[index], age: e != null ? e.value : {} } })
            handleOnChange(e, 'adults', index, 'age')
            setIsChanged(true)
          }}
          isClearable
          required
          isSearchable={true}
          name='state'
          options={getAgesOfAdults()}
          value={getSelectdValue(adults[index]?.age, getAgesOfAdults())}
          className='select-input-container'
        />
        <ErrorMessage text={_.get(errors, `adults[${index}].age`)} />
      </div>

      <div className='errors-div'>
        <Select
          placeholder={'Select Gender'}
          onChange={(e) => {
            setAdults({ ...adults, [index]: { ...adults[index], gender: e != null ? e.value : {} } })
            handleOnChange(e, 'adults', index, 'gender')
            setIsChanged(true)
          }}
          isClearable
          isSearchable={false}
          name='state'
          options={GENDER_OPTIONS}
          value={getSelectdValue(adults[index]?.gender, GENDER_OPTIONS)}
        />
        <ErrorMessage text={_.get(errors, `adults[${index}].gender`)} />
      </div>
    </div>
  )

  const renderBlindnessCheckboxesForChildren = (index: number): JSX.Element => (
    <FormControlLabel
      key={index}
      labelPlacement='bottom'
      onChange={(e: ChangeEvent<{}>) => {
        const Checkbox = e.target as HTMLInputElement
        setChildren({ ...children, [index]: { ...children[index], blindness: Checkbox.checked } })
        setIsChanged(true)
      }}
      control={<Checkbox checked={children[index]?.blindness ?? false} />}
      label={children[index]?.name ?? `Child ${index + 1}`}
      className='check-box-index'
    />
  )

  const renderBlindnessCheckboxes = (index: number): JSX.Element => (
    <FormControlLabel
      key={index}
      labelPlacement='bottom'
      onChange={(e: ChangeEvent<{}>) => {
        const Checkbox = e.target as HTMLInputElement
        setAdults({ ...adults, [index]: { ...adults[index], blindness: Checkbox.checked } })
        setIsChanged(true)
      }}
      control={<Checkbox checked={adults[index]?.blindness ?? false} />}
      label={index < 1 ? 'Me' : adults[index]?.name ?? `Adult ${index}`}
      className='check-box-index'
    />
  )

  const renderDisabilityCheckboxesForChildren = (index: number): JSX.Element => (
    <FormControlLabel
      key={index}
      labelPlacement='bottom'
      onChange={(e: ChangeEvent<{}>) => {
        const Checkbox = e.target as HTMLInputElement
        setChildren({ ...children, [index]: { ...children[index], disability: Checkbox.checked } })
        setIsChanged(true)
      }}
      control={<Checkbox checked={children[index]?.disability ?? false} />}
      label={children[index]?.name ?? `Child ${index + 1}`}
      className='check-box-index'
    />
  )

  const renderDisabilityCheckboxes = (index: number): JSX.Element => (
    <FormControlLabel
      key={index}
      labelPlacement='bottom'
      onChange={(e: ChangeEvent<{}>) => {
        const Checkbox = e.target as HTMLInputElement
        setAdults({ ...adults, [index]: { ...adults[index], disability: Checkbox.checked } })
        setIsChanged(true)
      }}
      control={<Checkbox checked={adults[index]?.disability ?? false} />}
      label={index < 1 ? 'Me' : adults[index]?.name ?? `Adult ${index}`}
      className='check-box-index'
    />
  )

  const renderTribalMemberCheckboxes = (index: number): JSX.Element => (
    <FormControlLabel
      key={index}
      labelPlacement='bottom'
      onChange={(e: ChangeEvent<{}>) => {
        const Checkbox = e.target as HTMLInputElement
        setAdults({ ...adults, [index]: { ...adults[index], memberOfNativeAmericanTribe: Checkbox.checked } })
        setIsChanged(true)
      }}
      control={<Checkbox checked={adults[index]?.memberOfNativeAmericanTribe ?? false} />}
      label={index < 1 ? 'Me' : adults[index]?.name ?? `Adult ${index}`}
    />
  )

  const renderTribalMemberCheckboxesForChildren = (index: number): JSX.Element => (
    <FormControlLabel
      key={index}
      labelPlacement='bottom'
      onChange={(e: ChangeEvent<{}>) => {
        const Checkbox = e.target as HTMLInputElement
        setChildren({ ...children, [index]: { ...children[index], memberOfNativeAmericanTribe: Checkbox.checked } })
        setIsChanged(true)
      }}
      control={<Checkbox checked={children[index]?.memberOfNativeAmericanTribe ?? false} />}
      label={children[index]?.name ?? `Child ${index + 1}`}
      className='check-box-index'
    />
  )

  const renderJobInputs = (index: number): JSX.Element => (
    <div className='step-two-input' key={index}>
      <p>{index < 1 ? 'Jobs I currently have:' : `Jobs ${adults[index]?.name} has:` ?? `Jobs adult ${index} have:`}</p>

      <div className='errors-div'>
        <Select
          placeholder={'Select Number of Job(s)'}
          onChange={(e) => {
            setAdults({ ...adults, [index]: { ...adults[index], jobs: e != null ? e.value : {} } })
            setJobDetails({ ...jobsDetails, [index]: e != null ? e.value : {} })
            handleOnChange(e, 'jobs', index, 'jobs')
            setIsChanged(true)
          }}
          isClearable
          isSearchable={true}
          name='state'
          options={JOB_CURRENTLY_HAVE}
          value={getSelectdValue(adults[index]?.jobs, JOB_CURRENTLY_HAVE)}
          className='select-input-container'
        />
        <ErrorMessage text={_.get(errors, `adults[${index}].jobs`)} />
      </div>
    </div>
  )

  const SubquestionsForStudents = {
    isHighSchoolEnrolled: 'High School',
    isHSEDEnrolled: 'HSED or GED program',
    isELLorABEEnrolled: 'English Language Learning, Remedial, or Adult Basic Education',
    isVocationalTrainingEnrolled: 'Vocational Training leading to a diploma or certificate',
    isTechnicalCollegeEnrolled: '2 Year (or less) Technical College leading to a diploma or certificate',
    isOnTheJobTrainingEnrolled: 'On-the-job Training',
    isFourYearCollegeEnrolled: '4 Year college',
    isFederalWorkStudyEnrolled: 'Federal Work Study'
  }

  const renderStudentsCheckbox = (index: number, value: string, label: string, disabled: boolean): JSX.Element => (
    <FormControlLabel
      key={index}
      labelPlacement='end'
      onChange={(e: ChangeEvent<{}>) => {
        const Checkbox = e.target as HTMLInputElement
        setAdults((prevValue) => ({ ...prevValue, [index]: { ...prevValue[index], [value]: Checkbox.checked } }))
        setIsChanged(true)
      }}
      control={<Checkbox checked={adults[index][value as keyof Adults] as boolean} size='small' />}
      label={label}
      disabled={disabled}
      className='check-box-index'
    />
  )

  const renderSubqustionsForStudents = (index: number): JSX.Element => {
    const isHighSchoolOrHSEDChecked = adults[index]?.isHighSchoolEnrolled || adults[index]?.isHSEDEnrolled || adults[index]?.isTechnicalCollegeEnrolled
    const isFourYearCollegeChecked = adults[index]?.isFourYearCollegeEnrolled
    return (
      <>
        <p><b>{index === 0 ? 'I am' : adults[index]?.name ?? `Adult ${index}`} currently enrolled in: </b></p>
        <p><i>Select all that apply:</i></p>
        <div className='student-status-checkboxes'>
          {Object.entries(SubquestionsForStudents).map(([key, value]) => {
            let disabled = false

            if ((key === 'isFourYearCollegeEnrolled' && isHighSchoolOrHSEDChecked) ||
              (isFourYearCollegeChecked && (key === 'isHighSchoolEnrolled' || key === 'isHSEDEnrolled' || key === 'isTechnicalCollegeEnrolled'))) {
              disabled = true
            }

            return renderStudentsCheckbox(index, key, value, disabled)
          })}
        </div>
      </>
    )
  }

  const resetSubquestionsForStudents = (index: number): void => {
    Object.keys(SubquestionsForStudents).forEach(property => _.set(adults, [index, property], false))
  }

  const setDefaultValueIfUndefined = (source: Record<number, Adults>, path: Array<string | number>, value: string | boolean): void => {
    if (_.get(source, path) === undefined) {
      _.set(source, path, value)
    }
  }

  const renderStudentsInput = (index: number): JSX.Element => {
    setDefaultValueIfUndefined(adults, [index, 'student'], 'not-student')
    setDefaultValueIfUndefined(adults, [index, 'isHighSchoolEnrolled'], false)
    setDefaultValueIfUndefined(adults, [index, 'isHSEDEnrolled'], false)
    setDefaultValueIfUndefined(adults, [index, 'isELLorABEEnrolled'], false)
    setDefaultValueIfUndefined(adults, [index, 'isVocationalTrainingEnrolled'], false)
    setDefaultValueIfUndefined(adults, [index, 'isTechnicalCollegeEnrolled'], false)
    setDefaultValueIfUndefined(adults, [index, 'isOnTheJobTrainingEnrolled'], false)
    setDefaultValueIfUndefined(adults, [index, 'isFourYearCollegeEnrolled'], false)
    setDefaultValueIfUndefined(adults, [index, 'isFederalWorkStudyEnrolled'], false)

    return (
      <>
        <div className='step-two-input' key={index}>
          <p>{index < 1 ? 'My student status:' : `Student status of ${adults[index]?.name ?? `Adult ${index}`}:`}</p>

          <Select
            className='basic-single'
            classNamePrefix='select'
            placeholder={'Select student status'}
            onChange={(e) => {
              if (e.value === 'not-student') {
                resetSubquestionsForStudents(index)
              }

              setAdults({ ...adults, [index]: { ...adults[index], student: e.value } })
              setIsChanged(true)
            }}
            isSearchable={false}
            name='state'
            options={STUDENT_STATUS}
            value={adults[index]?.student
              ? getSelectdValue(adults[index]?.student, STUDENT_STATUS)
              : STUDENT_STATUS[0]
            }
          />
        </div>

        {adults[index]?.student !== 'not-student' && renderSubqustionsForStudents(index)}
      </>
    )
  }

  const renderPregnantCheckboxes = (index: number): JSX.Element => (
    <FormControlLabel
      key={index}
      labelPlacement='bottom'
      onChange={(e: ChangeEvent<{}>) => {
        const Checkbox = e.target as HTMLInputElement
        setAdults({ ...adults, [index]: { ...adults[index], pregnant: Checkbox.checked } })
        setIsChanged(true)
      }}
      control={<Checkbox checked={adults[index]?.pregnant} />}
      label={index < 1 ? 'Me' : adults[index]?.name ?? `Adult ${index}`}
    />
  )

  const renderHomeLessCheckboxesForChildren = (index: number): JSX.Element => (
    <FormControlLabel
      key={index}
      labelPlacement='bottom'
      onChange={(e: ChangeEvent<{}>) => {
        const Checkbox = e.target as HTMLInputElement
        setChildren({ ...children, [index]: { ...children[index], homeless: Checkbox.checked } })
        setIsChanged(true)
      }}
      control={<Checkbox checked={children[index]?.homeless} />}
      label={children[index]?.name ?? `Child ${index + 1}`}
      className='check-box-index'
    />
  )

  const renderHomeLessCheckboxes = (index: number): JSX.Element => (
    <FormControlLabel
      key={index}
      labelPlacement='bottom'
      onChange={(e: ChangeEvent<{}>) => {
        const Checkbox = e.target as HTMLInputElement
        setAdults({ ...adults, [index]: { ...adults[index], homeless: Checkbox.checked } })
        setIsChanged(true)
      }}
      control={<Checkbox checked={adults[index]?.homeless} />}
      label={index < 1 ? 'Me' : adults[index]?.name ?? `Adult ${index}`}
    />
  )

  const handleHomelessReset = (): void => {
    Object.keys(children).forEach((_child, key) => {
      children[key] = { ...children[key], homeless: false }
    })

    Object.keys(adults).forEach((_adult, key) => {
      adults[key] = { ...adults[key], homeless: false }
    })

    setCurrentlyHomeless(false)
  }

  const handlePregnantReset = (): void => {
    Object.keys(adults).forEach((_adults, key) => {
      adults[key] = { ...adults[key], pregnant: false }
    })
  }

  scrollToTop()

  return (
    <>
      <div className='step-two'>
        <FormControl variant='outlined'>
          <h3>Household Information</h3>
          {convertObjectToArray(formValues?.childrenCount).map((_child, index: number) => renderChildInputs(index))}

          {formValues?.childrenCount > 0 &&
            <p className='child-aged-notes'>At this time, Parsley does not model how benefits may be affected by birthdays within the year modeled.
              Keep in mind, annual estimates in Step 8 will be based on 12 months of the child being the age you enter.</p>}

          {convertObjectToArray(formValues?.adultsCount).map((_child, index: number) => renderAdultInputs(index))}

          <Divider />
          <p className='child-aged-notes'>Do any of the following statuses apply to members of your family? If not, leave blank.</p>
          <b>Household Status</b>

          {
            <div className='step-two-input'>
              <p style={{fontSize:'15px'}}>Has a permanent disability: </p>

              {convertObjectToArray(formValues?.childrenCount).map((_child, index: number) => renderDisabilityCheckboxesForChildren(index))}
              {convertObjectToArray(formValues?.adultsCount).map((_child, index: number) => renderDisabilityCheckboxes(index))}
            </div>
          }

          {
            <div className='step-two-input'>
              <p style={{ marginRight: '144px' }}>Is Blind:</p>

              {convertObjectToArray(formValues?.childrenCount).map((_child, index: number) => renderBlindnessCheckboxesForChildren(index))}
              {convertObjectToArray(formValues?.adultsCount).map((_child, index: number) => renderBlindnessCheckboxes(index))}
            </div>
          }

          <Divider />

          {
            <div className='student-status'>
              {convertObjectToArray(formValues?.adultsCount).map((_child, index: number) => renderStudentsInput(index))}
            </div>
          }

          <Divider />

          {
            <div className=' disability-div'>
              <FormControlLabel
                className='d-flex justify-content-end'
                control={<Checkbox checked={pregnant ?? false} />}
                label={'Is anyone in your household pregnant?'}
                labelPlacement='start'
                onChange={(e: any) => {
                  setPregnant(e.target.checked)
                  handlePregnantReset()
                  setIsChanged(true)
                }}

              />
            </div>
          }

          {
            pregnant &&
            <>
              <div className='step-two-input'>
                <p style={{ marginRight: '60px' }}>Who is pregnant in your household?</p>
                {convertObjectToArray(formValues?.adultsCount).map((_child, index: number) => renderPregnantCheckboxes(index))}
              </div>
            </>
          }

          <Divider />

          {
            <div className=' disability-div'>
              <FormControlLabel
                className='d-flex justify-content-end'
                labelPlacement='start'
                onChange={(e: any) => {
                  setHomelessness(e.target.checked)
                  handleHomelessReset()
                  setIsChanged(true)
                }}
                control={<Checkbox checked={homelessness} />}
                label='Has anyone experienced homelessness in the past 12 months?'
              />
            </div>
          }

          {
            homelessness &&
            <>
              <div className='step-two-input'>
                <p style={{ marginRight: '238px' }}>Who has experienced homelessness?</p>

                {convertObjectToArray(formValues?.childrenCount).map((_child, index: number) => renderHomeLessCheckboxesForChildren(index))}
                {convertObjectToArray(formValues?.adultsCount).map((_child, index: number) => renderHomeLessCheckboxes(index))}
              </div>

              <div className='d-flex'>
                <p>Is your household currently homeless?</p>
                <Checkbox
                  checked={currentlyHomeless}
                  onChange={(e) => {
                    setCurrentlyHomeless(e.target.checked)
                    setIsChanged(true)
                  }}
                />
              </div>
            </>
          }

          <div className='step-two-input'>
            <p>Is anyone a member or child of a member of a Native American Tribe?</p>

            {convertObjectToArray(formValues?.childrenCount).map((_child, index: number) => renderTribalMemberCheckboxesForChildren(index))}
            {convertObjectToArray(formValues?.adultsCount).map((_child, index: number) => renderTribalMemberCheckboxes(index))}
          </div>

          <Divider />

          <b>Employment Status</b>
          {convertObjectToArray(formValues?.adultsCount).map((_child, index: number) => renderJobInputs(index))}

        </FormControl>
      </div>

      {isRequiredFieldsEmpty && <ErrorMessage text='Complete required fields' />}
      <NavigationButtons
        handleBack={props.handleBack}
        handleSubmit={handleSubmit}
        step={props.step}
        saveUpdatedData={saveUpdatedData}
        isChanged={isChanged}
      />
    </>
  )
}

export default StepTwo
