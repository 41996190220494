import React, { type FC, useState, useEffect } from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { Typography } from '@mui/material'

import { type FormValues } from '../../../../FormValuesInterfaces'

import './styles.sass'

interface FSPProps {
  contextValues: FormValues
  setContextValues: (values: FormValues) => void
}

const FSP: FC<FSPProps> = ({ contextValues, setContextValues }) => {
  const [heatAndEatAllowance, setHeatAndEatAllowance] = useState<boolean>(contextValues.heatAndEatAllowance ?? false)

  const handleRadioChange = (event: { target: { value: string } }) => {
    const value = event.target.value === 'yes'
    setHeatAndEatAllowance(value)
  }  

  useEffect(() => {
    setContextValues({
      ...contextValues,
      heatAndEatAllowance: heatAndEatAllowance
    })
  }, [heatAndEatAllowance])

  return (
    <div className='fsp-benefit'>
      <p>
        Has your household received energy assistance payments greater than $20 from WHEAP, LIHEAP, or any other source in the last 12 months, even in another state?
      </p>
      <FormControl>
        <RadioGroup value={heatAndEatAllowance ? 'yes' : 'no'} onChange={handleRadioChange}>
          <FormControlLabel
            value='yes'
            control={<Radio />}
            label={<Typography variant='body1'>Yes</Typography>}
          />
          <FormControlLabel
            value='no'
            control={<Radio />}
            label={<Typography variant='body1'>No</Typography>}
          />
        </RadioGroup>
      </FormControl>
    </div>
  )
}

export default FSP
