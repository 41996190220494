import React, { memo, useMemo } from 'react'
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core'

import { type FormValues } from '../../../FormValuesInterfaces'
import {
  type AdultMember,
  type Benefit,
  type ChildMember,
  type Result
} from '../../../OutputInterfaces'
import { type MonthYearToggleButton, type RenderDefaultRow, type RenderRow } from '../../../interfaces'
import { MIN_AGE_FOR_BUS_PASS } from '../../../Constants'

interface Props {
  formValues: FormValues
  result: Result
  monthYearToggleButton: MonthYearToggleButton
  renderRow: RenderRow
  renderDefaultRow: RenderDefaultRow
}

const BenefitsEligibilitySection: React.FC<Props> = memo((props) => {
  const {
    result,
    formValues,
    monthYearToggleButton,
    renderRow,
    renderDefaultRow
  } = props

  const renderTableMedicaid = useMemo(() => (adultMembers: AdultMember[], childMembers: ChildMember[]) => {
    const renderMembers = (members: AdultMember[] | ChildMember[], adultMember: boolean): JSX.Element[] => {
      return members?.map((member: AdultMember | ChildMember, programIndex: number) =>
        renderMedicaidMember(member, adultMember, programIndex)
      )
    }

    return (
      <>
        <TableRow>
          <TableCell align="left" colSpan={2} className="v-align">
            <p>Medicaid / Badgercare</p>
          </TableCell>
        </TableRow>

        {renderMembers(adultMembers, true)}
        {renderMembers(childMembers, false)}

        {result?.output?.medicaid_deductibles_note && (
          <TableRow>
            <TableCell colSpan={2}>
              <p className="badgercare-deductible-notes">{result?.output?.medicaid_deductibles_note}</p>
            </TableCell>
          </TableRow>
        )}
      </>
    )
  }, [result?.output?.medicaid_deductibles_note])

  const renderMedicaidMember = (member: AdultMember | ChildMember, adultMember: boolean, programIndex: number): JSX.Element => {
    const isEligible = member.medicaid_eligibility !== "Ineligible"
    const memberType = adultMember ? "Adult " : "Child "
    const formattedMemberName = member.name || `${memberType}${programIndex + 1}`

    return (
      <TableRow key={programIndex} className="medicaid-paddings">
        <TableCell align="left">
          <p>{formattedMemberName}</p>
        </TableCell>
        <TableCell align="right">
          <div>
            <p>{member.medicaid_eligibility}</p>
            {isEligible && (
              <>
                <p>Monthly Premium: {member.premium_note}</p>
                <p>{member.copay_note}</p>
                <p>{member.deductible_note}</p>
              </>
            )}
          </div>
        </TableCell>
      </TableRow>
    )
  }

  const renderBenefitsSection = useMemo(() => (benefit: Benefit, programIndex: number) => {
    const { child_care, food_assistance } = benefit

    if (child_care) {
      return result?.output?.child_members?.some(obj => obj.age < 13) && child_care?.programs?.length > 0
        ? renderRow(
          'childCareAssistance',
          'benefitsCalculations',
          'Child Care Assistance',
          child_care.programs,
          programIndex,
          '',
          'Benefit Outcome',
          '50px'
        )
        : []
    } else if (food_assistance) {
      return food_assistance?.programs?.length > 0
        ? renderRow(
          'foodAssistance',
          'benefitsCalculations',
          'Food Assistance',
          food_assistance.programs,
          programIndex,
          '',
          food_assistance.programs_sum,
          '50px'
        )
        : []
    } else {
      return renderDefaultRow('benefitsCalculations', benefit as any, programIndex)
    }
  }, [result?.output?.child_members, renderRow, renderDefaultRow])

  const renderReducedFarePassesTable = useMemo(() => (adultMembers: AdultMember[], childMembers: ChildMember[]) => {
    const renderMembers = (members: Array<AdultMember | ChildMember>, adultMember: boolean) => {
      return members?.map((member: AdultMember | ChildMember, programIndex: number) =>
        renderReducedFarePassesMember(member, adultMember, programIndex)
      )
    }

    const foodAssistanceProgram = result?.output?.benefits?.programs.find(program =>
      program.food_assistance?.programs?.find(subProgram => subProgram.key === 'fsp')
    )

    return (
      <>
        <TableRow>
          <TableCell align='left' className='v-align'>
            <p>Madison Metro Reduced Fare Bus Pass</p>
          </TableCell>

          <TableCell align='right'>
            {renderMembers(adultMembers, true)}
            {renderMembers(childMembers, false)}
          </TableCell>
        </TableRow>

        {result?.output?.bus_pass_benefit_note && (
          <TableRow>
            <TableCell colSpan={2}>
              {result?.output?.bus_pass_benefit_note.map((note: string, index: number) => (
                index === 0 && foodAssistanceProgram ? <p key={index}><b>{note}</b></p> : <p key={index}>{note}</p>
              ))}
            </TableCell>
          </TableRow>
        )}
      </>
    )
  }, [result?.output?.medicaid_deductibles_note])

  const renderReducedFarePassesMember = (member: AdultMember | ChildMember, adultMember: boolean, programIndex: number) => {
    const memberType = adultMember ? 'Adult ' : 'Child '
    const formattedMemberName = member.name || memberType + (programIndex + 1)

    return (
      <div className='medicaid-paddings' key={programIndex}>
        {member.age >= MIN_AGE_FOR_BUS_PASS && formValues.adults[programIndex].checkBusPassEligibility && (
          <p className='bus-pass-note'>{formattedMemberName}: {member.bus_pass_eligibility_note}</p>
        )}
      </div>
    )
  }

  const renderBenefitsTable = useMemo(() => (
    <Table>
      <TableBody>
        {formValues.check_hlth &&
          renderTableMedicaid(
            result?.output?.adult_members,
            formValues?.childrenCount > 0 ? result?.output?.child_members : []
          )}

        {(!formValues.check_hlth &&
          result?.output?.benefits?.programs[0]?.child_care?.programs?.length === 0 &&
          result?.output?.benefits?.programs[1]?.food_assistance?.programs?.length === 0 &&
          result?.output?.benefits?.programs.length === 2)
          ? <TableRow>
            <TableCell colSpan={2}>
              Select benefits to model eligibility.
            </TableCell>
          </TableRow>
          : result?.output?.benefits?.programs.map(
            (benefit: Benefit, programIndex: number) =>
              renderBenefitsSection(benefit, programIndex)
          )}

        {formValues.check_com_reduced_fare_passes &&
          renderReducedFarePassesTable(
            result?.output?.adult_members,
            formValues?.childrenCount > 0 ? result?.output?.child_members : []
          )}
      </TableBody>
    </Table>
  ), [
    formValues.check_hlth,
    renderTableMedicaid,
    result?.output?.adult_members,
    result?.output?.child_members,
    result?.output?.benefits?.programs,
    renderBenefitsSection
  ])

  return (
    <>
      <div className='d-flex justify-space-between align-items-center'>
        <h3>Public Benefits Eligibility</h3>
        <div className='d-flex justify-content-end flex-column'>
          {monthYearToggleButton('benefitsCalculations')}
        </div>
      </div>
      <TableContainer component={Paper}>{renderBenefitsTable}</TableContainer>
    </>
  )
})

export default BenefitsEligibilitySection
