import { type StylesConfig } from 'react-select'
import { styled } from '@mui/material/styles'
import { tableCellClasses } from '@mui/material'
import { TableCell, TableRow } from '@material-ui/core'
import HealthBenefits from './components/Employee/HealthcareBenefits'
import DentalBenefits from './components/Employee/DentalBenefits'
import VisionBenefits from './components/Employee/VisionBenefit'
import MedicalFSA from './components/Employee/MedicalFSA'
import DependentCareFSA from './components/Employee/DependentCareFSA'
import PensionBenefits from './components/Employee/PensionBenefits'
import TSA403B from './components/Employee/TSA403B'
import ShortTerm from './components/Employee/ShortTermBenefit'
import LongTerm from './components/Employee/LongTermBenefit'
import LongTermCareInsurance from './components/Employee/LongTermCareInsurance'
import LifeInsuranceBenefit from './components/Employee/LifeInsuranceBenefit'

export const DROPDOWN_COLOR_OPTIONS: any = [
  { value: 'baskin-robins-egg-blue', label: 'Baskin Robins Egg Blue', color: '#68E5DE' },
  { value: 'big-pumpkin', label: 'Big Pumpkin', color: '#F27615' },
  { value: 'blue-moon', label: 'Blue Moon', color: '#86DAF4' },
  { value: 'blue-bunny', label: 'Blue-Bunny', color: '#98D0F9' },
  { value: 'chick-o-stick-yellow', label: 'Chick O Stick Yellow', color: '#FAEDC5' },
  { value: 'clove-purple', label: 'Clove Purple', color: '#EBECFB' },
  { value: 'reamsicle-orange', label: 'Creamsicle Orange', color: '#F4CB49' },
  { value: 'currant-purple', label: 'Currant Purple', color: '#C0B6F9' },
  { value: 'fantasia-blue', label: 'Fantasia Blue', color: '#2457F0' },
  { value: 'floss-blue', label: 'Floss Blue', color: '#E5F6FC' },
  { value: 'gandolf-grey', label: 'Gandolf Grey', color: '#505B62' },
  { value: 'get-it-green', label: 'Get It Green', color: '#0A9A95' },
  { value: 'grapez', label: 'Grapez', color: '#F2E9F9' },
  { value: 'maui-punch-blue', label: 'Maui Punch Blue', color: '#E0F7FB' },
  { value: 'orange-fanta', label: 'Orange Fanta', color: '#FCEEE2' },
  { value: 'pale-green', label: 'Pale Green', color: '#EDF2EE' },
  { value: 'peep-yellow', label: 'Peep Yellow', color: '#F9F8D6' },
  { value: 'pink-panther', label: 'Pink Panther', color: '#F9ADCE' },
  { value: 'pistachio-green', label: 'Pistachio Green', color: '#ABE898' },
  { value: 'power-purple', label: 'Power Purple', color: '#703DF4' },
  { value: 'promise-green', label: 'Promise Green', color: '#1D9F1B' },
  { value: 'push-up-pop-orange', label: 'Push Up Pop Orange', color: '#F7BE8B' },
  { value: 'smart-cherry', label: 'Smart Cherry', color: '#FCEFF2' },
  { value: 'spearmint', label: 'spearmint', color: '#D4F7F5' }, ,
  { value: 'ube-purple', label: 'Ube Purple', color: '#D4F7F5' },
  { value: 'winter-green', label: 'Winter Green', color: '#ECF9EA' },
  { value: 'yummy-yellow', label: 'Yummy Yellow', color: '#E9E24C' },
  { value: 'lighty-white-grey', label: 'Slighty White Grey', color: '#F8F5F3' }
]

interface ColourOption {
  readonly value: string
  readonly label: string
  readonly color: string
  readonly isFixed?: boolean
  readonly isDisabled?: boolean
}

const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10
  }
})

export const DROPDOWN_COLOR_STYLES: StylesConfig<ColourOption> = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = data.color
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? data.color
          : isFocused
            ? color
            : undefined,

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : color
          : undefined
      }
    }
  },
  input: (styles) => ({ ...styles, ...dot() }),
  placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) })
}

export const QUICK_PEEK_TIERS = [{ value: 1, label: 'Tier 1' }, { value: 2, label: 'Tier 2' }, { value: 3, label: 'Tier 3' }]

export const ALL_POPULAR_RECENT = ['All', 'Most Recent', 'Most Popular']

export const TIER_OPTIONS = [
  { label: 'Tier in Ascending Order', value: 0 },
  { label: 'Tier in Descending Order', value: 1 },
  { label: 'Created At in Ascending Order', value: 2 },
  { label: 'Created At in Descending Order', value: 3 },
  { label: 'Published At in Ascending Order', value: 4 },
  { label: 'Published At in Descending Order', value: 5 }
]

export const STEP_NAMES = [
  'Household Information',
  'Personal & Family Background',
  'Work & Income',
  'Public Benefits',
  'Child Care',
  'Health Care',
  'Other Expenses',
  'Results'
]

export const ADMIN_DASHBOSRD_STEP_NAMES = [
  'Create Folder',
  'Create Scenario',
  'Baseline Scenario',
  'Scenario Variables',
  'Generate CSV',
  'Create Visualization',
  'Graphs'
]

type EMPLOYEE_NEXT_BENEFIT_PROPS = Record<number, string>

export const EMPLOYEE_NEXT_BENEFIT: EMPLOYEE_NEXT_BENEFIT_PROPS = {
  0: 'Dental Insurance',
  1: 'Vision Insurance',
  2: 'FSA Insurance',
  3: 'Retirement Insurance',
  4: 'Finish'
}

export const NUMBER_OF_CHILDREN = [
  { label: '0', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 }
]

export const NUMBER_OF_ADULT = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 }
]

export const GENDER_OPTIONS = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Nonbinary', value: 'nonbinary' }
]

export const JOB_CURRENTLY_HAVE = [
  { label: 'Unemployed', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 }
]

export const AGES_IN_MONTHS = [
  { label: '0', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 12 }
]

export const WI_BENEFITS = [
  { label: 'Medicaid/BadgerCare', value: 'hlth' },
  { label: 'SNAP / Foodshare', value: 'fsp' },
  { label: 'Women, Infants, and Children (WIC)', value: 'wic' },
  // TODO: Putting it on hold, resume after MVP
  // { label: 'Free and Reduced Meal Programs for Children', value: 'frlp' },
  { label: 'Wisconsin Shares Child Care Subsidy', value: 'ccdf' },
  { label: 'Head Start and Early Head Start', value: 'headstart' },
  { label: 'TANF Cash Assistance', value: 'tanf' },
  { label: 'Madison Metro Reduced Fare Bus Pass', value: 'com_reduced_fare_passes' },
  { label: 'Lifeline', value: 'lifeline' },
  { label: 'Section 8 Housing Vouchers', value: 'sec8' }
]

export const EMPLOYEE_BENEFITS_LIST = [
  { label: 'Health', value: 'health' },
  { label: 'Vision', value: 'vision' },
  { label: 'Dental', value: 'dental' },
  { label: 'Disability & Life', value: 'disability_and_life' },
  { label: 'Retirement', value: 'retirement' }
]

export const BenefitComponents = {
  health: HealthBenefits,
  dental: DentalBenefits,
  vision: VisionBenefits,
  health_flexible_spending_account: MedicalFSA,
  dependent_care_flexible_spending_account: DependentCareFSA,
  pension: PensionBenefits,
  '403(b)': TSA403B,
  'short-term_disability_insurance': ShortTerm,
  'long-term_disability_insurance': LongTerm,
  'long-term_care_insurance': LongTermCareInsurance,
  life_insurance: LifeInsuranceBenefit
}

// TODO: Removed the frlp from DISABLED_BENEFITS, re-add after MVP
export const DISABLED_BENEFITS = ['tanf', 'wic', 'ccdf', 'headstart']

export const BENEFIT_DURATION_OPTIONS = [
  { label: 'Week', value: 'week' },
  { label: 'Every Two Weeks', value: 'biweekly' },
  { label: 'Month', value: 'month' },
  { label: 'Twice a Month', value: 'bimonthly' },
  { label: 'Year', value: 'year' }
]

export const DURATION_OPTIONS = [
  { label: 'Hour', value: 'hour' },
  { label: 'Week', value: 'week' },
  { label: 'Every Two Weeks', value: 'biweekly' },
  { label: 'Month', value: 'month' },
  { label: 'Twice a Month', value: 'bimonthly' },
  { label: 'Year', value: 'year' }
]


export const TIPS_DURATION_OPTIONS = [
  { label: 'Week', value: 'week' },
  { label: 'Every Two Weeks', value: 'biweekly' },
  { label: 'Twice a Month', value: 'bimonthly' },
  { label: 'Month', value: 'month' }
]

export const CHILD_CARE_DURATION_OPTIONS = [
  { label: 'Select Provider rate...', value: 'none' },
  { label: 'Monthly', value: 'month' },
  { label: 'Weekly', value: 'week' },
  { label: 'Daily', value: 'day' },
  { label: 'Hourly', value: 'hour' }
]

type PayscaleDuration = Record<string, string>

export const PAYSCALE_DURATION: PayscaleDuration = {
  hour: 'hourly',
  day: 'daily',
  week: 'weekly',
  month: 'monthly'
}

export const CHILD_CARE_ATTEND_TYPE = [
  { label: 'Select care duration', value: 'none' },
  { label: 'Full Time', value: 'full_time' },
  { label: 'Part Time', value: 'part_time' }
]

type ChildCareTypes = Record<string, string>

export const CHILD_CARE_TYPES: ChildCareTypes = {
  full_time: 'Full-time',
  part_time: 'Part-time'
}

export const CHILD_CARE_CALCULATED_OPTIONS = [
  { label: 'Select Child Care Type...', value: 'none', isDisabled: false },
  { label: 'Licensed Group Care', value: 'group', isDisabled: false },
  { label: 'Licensed Family Care', value: 'family', isDisabled: false },
  { label: 'Certified Family Care', value: 'certified', isDisabled: false },
  { label: 'Friend or Family Member', value: 'registered', isDisabled: false }
]

export const CHILD_UNDER_ONE: number = 0
export const CHILD_AGED_FIVE: number = 5
export const CHILD_AGED_TWELVE: number = 12
export const CHILD_AGED_THIRTEEN: number = 13

export const PRIVATE_PLAN_TYPES = [
  { label: 'Individual Plan (Through Marketplace)', value: 'individual' },
  { label: 'Employer Plan (Through Job)', value: 'employer' }
]

type DurationMultiplier = Record<string, number>

export const DURATION_MULTIPLIER: DurationMultiplier = {
  hour: 52,
  week: 52,
  biweekly: 26,
  month: 12,
  year: 1
}

export const WEEKDAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday'
]

export const HEATING_SOURCE_OPTIONS = [
  { label: 'Blended Fuel', value: 'blendedFuel' },
  { label: 'Bottled Gas', value: 'bottleGas' },
  { label: 'Coal', value: 'coal' },
  { label: 'Electricity', value: 'electric' },
  { label: 'Gas', value: 'gas' },
  { label: 'Kerosene', value: 'kerosene' },
  { label: 'Natural Gas', value: 'naturalGas' },
  { label: 'Oil', value: 'fuelOil' },
  { label: 'Propane', value: 'bottleGas' },
  { label: 'Wood or Other', value: 'wood' }
]

export const STUDENT_STATUS = [
  { label: 'Not a student', value: 'not-student' },
  { label: 'Less than half-time', value: 'less_than_pt_student' },
  { label: 'Half-time', value: 'pt_student' },
  { label: 'Full-time', value: 'ft_student' }
]

export const YES_NO = ['Yes', 'No']

export const BLUR_QUESTIONS = {
  0: true,
  1: false,
  2: false,
  3: false
}

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

export const GENDERS = ['Male', 'Female', 'Transgender', 'Non-Binary', 'Prefer not to say']

export const RELATIONSHIPS = ['Self', 'Adult Child', 'Spouse', 'Related Adult', 'Non-related Adult']

export const MARTIAL_STATUSES = [
  'Single',
  'Married to Someone in Household',
  'Married to Someone outside of Household',
  'Separated',
  'Divorced',
  'Widowed'
]

export const CHILD_RELATIONSHIPS = [
  'This is my child',
  'This is a relative’s child that I am caring for (Kinship Care)',
  'Is a foster - child placed with me',
  'I do not have custody of this child - another adult in my home does'
]

export const KINSHIP_PLACEMENT = [
  'This kinship placement is court ordered',
  'I am receiving a Kinship Care payment',
  'None of the above'
]

export const CONDITION_FOR_CHILDREN = [
  'Have a natural or adoptive parent who is not living in the home',
  'You share custody of your children with an absent parent',
  'None of the above'
]

export const ENROLLMENT_STATUS = [
  'Less than part-time', 'Part-time', 'Fulltime'
]

export const EDUCATION_TYPES = [
  'High School',
  'Technical College',
  'GED or HSED',
  'Adult Basic Education or Literacy Training',
  'ESL (English as a second language)'
]

export const SECTIONS = {
  sectionTotalAnnualTaxCredits: false,
  sectionMonthlyExpenses: false,
  sectionChildCareExpenses: false,
  sectionExpenseAccount: false
}

export const TABLES = {
  childCareAssistance: false,
  foodAssistance: false,
  childTaxCredit: false,
  takeHomePay: false,
  totalDeductionFromPay: false,
  outOfPocketHealthCare: false,
  marketPlaceInsurance: false,
  livingExpenses: false,
  healthExpenseAccount: false
}

export const PROGRAMS_CALCULATIONS = {
  benefitsCalculations: 'monthly',
  totalAnnualTaxCreditCalculations: 'yearly',
  currentFinanceCalculations: 'monthly',
  monthlyExpensesCalculations: 'monthly',
  monthlyExpensesAccountCalculations: 'monthly',
  childCareExpensesCalculations: 'monthly',
  financialSnapshotCalculations: 'yearly'
}

export const SHORT_TERM_DISABILITY_BENEFIT = 'shortTermDisabilityBenefitData'
export const  LONG_TERM_CARE_iNSURANCE_BENEFIT = 'LongtermCareinsurance'
export const  LONG_TERM_DISABILITY_BENEFIT= 'long-term_disability_insurance'
export const LIFE_INSURANCE_BENEFIT = 'lifeInsuranceBenefitData'


export const COUNTIES = ['Adams', 'Ashland', 'Barron', 'Bayfield', 'Brown', 'Buffalo', 'Burnett', 'Calumet', 'Chippewa', 'Clark', 'Columbia', 'Crawford', 'Dane', 'Dodge', 'Door', 'Douglas', 'Dunn',
  'Eau claire', 'Florence', 'Fond du lac', 'Forest', 'Grant', 'Green', 'Green lake', 'Iowa', 'Iron', 'Jackson', 'Jefferson', 'Juneau', 'Kenosha', 'Kewaunee', 'La crosse', 'Lafayette', 'Langlade',
  'Lincoln', 'Manitowoc', 'Marathon', 'Marinette', 'Marquette', 'Menominee', 'Milwaukee', 'Monroe', 'Oconto', 'Oneida', 'Outagamie', 'Ozaukee', 'Pepin', 'Pierce', 'Polk', 'Portage', 'Price', 'Racine',
  'Richland', 'Rock', 'Rusk', 'Saint croix', 'Sauk', 'Sawyer', 'Shawano', 'Sheboygan', 'Taylor', 'Trempealeau', 'Vernon', 'Vilas', 'Walworth', 'Washburn', 'Washington', 'Waukesha', 'Waupaca', 'Waushara',
  'Winnebago', 'Wood']

export const STATES = { label: 'Wisconsin', value: 'WI' }

export const B2B_BENEFITS_FORMAT_STEP_NAME = (stepName: string): string => {
  return stepName.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())
}

export const PENSION_PLAN_YEARS_LIMIT = 5

export const AVERAGE_WORK_HOURS_PER_WEEK = 40
export const DEFAULT_PERCENTAGE = 100
export const MIN_AGE_FOR_BUS_PASS = 11
export const LIFELINE_SUBSIDY = 9.25
export const MAX_INCORRECT_ATTEMPTS = 3
export const MAX_TRAVEL_DAYS = 7
export const MAX_CHILD_CARE_DAYS = 5
export const MAX_CHILD_CARE_HOURS = 40
export const PREK_AGE_MAX = 4
export const WEEKS_IN_YEAR = 52
export const MAX_OUTER_WIDTH = 767

export const NPS_SURVEY_COOKIES_EXPIRAY_DAYS = 30

export const SECONDS_IN_A_DAY = 86400000

export const INCOME_CURRENT = ['travelPerWeek', 'currentWage', 'currentPayscale', 'currentWorkweek']

export const BENEFITS_ELIGIBILITY = ['Receiving', 'Eligible', 'Ineligible']

export const KINSHIP_CONDITION = 'This is a relative’s child that I am caring for (Kinship Care)'

export const EMAIL_FORMAT = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

export const CHILD_CARE_DAY_HOURS = [0, 0, 0, 0, 0]

export const NON_NUMERIC_CHARACTERS = /[^0-9]/g

export const WHITE_SPACE_REGEX = /\s+/g

export const OOP_MEDICAL_EXPENSES_QP = 29

export const WI_SHARES_APPROVED_ACTIVITY_REQUIREMENTS_QP = 112

export const MAX_NPS_RATING = 5

export const SNAP_MIN_HOURS = 80

export const AVG_WEEKS_PER_MONTH = 4.33

export const CHART_WIDTH = 600
export const CHART_HEIGHT = 400

export const ROW_KEY_TANF = 'tanf'
export const ROW_KEY_WIC = 'wic'
export const ROW_KEY_CHILD_SUPPORT = 'child_support'
export const ROW_KEY_LIFELINE = 'life_line_phone'
export const ROW_KEY_PHONE_EXPENSE = 'phone_expenses'
export const ROW_KEY_EMPLOYER_HEALTH_INSURANCE = 'hlth_care_deduction'
export const ROW_KEY_MARKETPLACE_PREMIUMS = 'unsub_cost_healt_insurance'
export const ROW_KEY_BUS_FARE_PASS = 'trans_expenses'
export const ROW_KEY_PREK = 'prek'
export const ROW_KEY_CHILDCARE_EXPENSE = 'childcare_expenses'
export const ROW_KEY_SECTION8 = 'housing_benefit'
export const ROW_KEY_RENT_EXPENSES = 'rent_mortgage'
export const ROW_KEY_POTENTIAL_CTC = 'potential_ctc'
export const ROW_KEY_SNAP = 'fsp'
export const ROW_KEY_FOOD_EXPENSES = 'food_expenses'
export const ROW_KEY_CCDF = 'ccdf'

export const PARSLEY_EMAIL = 'info@yourparsley.com'

export const ITEMS_PER_PAGE = 5

export const AUTO_CLOSE_DURATION = 500

export const ROUNDING_FACTOR = 100
export const MONTHS_IN_YEAR = 12
export const PAY_PERIODS_IN_YEAR = 26

export const BENEFIT_TYPE_HEALTH = 'health'
export const BENEFIT_TYPE_DENTAL = 'dental'
export const BENEFIT_TYPE_VISION = 'vision'
export const BENEFIT_TYPE_SHORT_TERM_DISABILITY = 'short_term_disability'
export const BENEFIT_TYPE_LONG_TERM_DISABILITY = 'Long-Term Disability Insurance'
export const BENEFIT_TYPE_LIFE_INSURANCE = 'life_insurance'

