import React, { type Context, type Dispatch, memo, useContext } from 'react'
import PurpleButton from './PurpleButton'
import OutlinedButton from './OutlinedButton'
import { type FormValues } from '../../FormValuesInterfaces'
import { type BenefitData } from '../../EmployeeCalculatorInterface'

import { LIFE_INSURANCE_BENEFIT, LONG_TERM_CARE_iNSURANCE_BENEFIT, SHORT_TERM_DISABILITY_BENEFIT } from '../../Constants'


interface SelectDeselectButtonGroupProps {
  formValuesContext: Context<FormValues>
  setValues: Dispatch<FormValues>
  benefitData: BenefitData
  setIsSelected: (value: boolean) => void
  error?: boolean
}

const SelectDeselectButtonGroup: React.FC<SelectDeselectButtonGroupProps> = memo((props) => {
  const {
    formValuesContext,
    setValues,
    benefitData,
    setIsSelected,
    error
  } = props
  const formValues: FormValues = useContext(formValuesContext)
  const getBenefitName = (name: string): string => {
    if (name === SHORT_TERM_DISABILITY_BENEFIT) {
      return 'Short-Term Disability InsuranceBenefitData'
    }

    if (name === LONG_TERM_CARE_iNSURANCE_BENEFIT) {
      return 'long-term_care_insuranceBenefitData'
    }
    if (name === LIFE_INSURANCE_BENEFIT) {
      return 'life_insuranceBenefitData'
    }
    return name
  }

  const handleSelect = (): void => {
    const benefitName = benefitData.name ?? ''
    const updatedAdults = {
      ...formValues.adults,
      0: {
        ...formValues.adults[0],
        [getBenefitName(benefitName)]: {
          name: getBenefitName(benefitName),
          amount: benefitData.amount ?? 0,
          provider: benefitData.provider ?? '',
          type: benefitData.type ?? '',
          coverage: benefitData.coverage ?? '',
          contribution: benefitData.contribution ?? '',
          dependentCareWithContribution: benefitData.dependentCareWithContribution,
          isSelected: true
        }
      }
    }

    const formValuesUpdated: FormValues = {
      ...formValues,
      adults: updatedAdults
    }
    setValues(formValuesUpdated)
    setIsSelected(true)
  }
  const handleDeselect = (): void => {
    const benefitName = benefitData.name ?? ''
    const updatedAdults = {
      ...formValues.adults,
      0: {
        ...formValues.adults[0],
        [getBenefitName(benefitName)]: {
          name: getBenefitName(benefitName),
          provider: '',
          amount: 0,
          type: '',
          coverage: '',
          contribution: '',
          dependentCareWithContribution: 0,
          isSelected: false
        }
      }
    }

    const formValuesUpdated: FormValues = {
      ...formValues,
      adults: updatedAdults
    }
    setValues(formValuesUpdated)
    setIsSelected(false)
  }

  const renderRegularButtons = (): JSX.Element => (
    benefitData.isSelected
      ? (
        <PurpleButton label='Deselect' width='250px' buttonClick={handleDeselect} />
      )
      : (
        <OutlinedButton label='Select' width='250px' btnClick={handleSelect} disabled={error} />
      )
  )

  return renderRegularButtons()
})

export default SelectDeselectButtonGroup
