import React, { memo, useEffect, useState, useRef, type Context } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import { COUNTIES, STATES } from '../../Constants'
import I18n from '../../config/I18n'
import PinCodeModal from './PinCodeModal'
import ConfirmationJobInfo from './ConfirmationJobInfo'
import Earnings from './Earnings'
import Taxes from './Taxes'
import BenefitsList from './BenefitsList'
import Benefits from './Benefits'
import { type FormValues } from '../../FormValuesInterfaces'
import BenefitsCliff from '../BenefitsCliff'
import LogoAndBackButton from '../LogoAndBackButton'

interface B2bCalculatorStepProps {
  usStates: { label: string, value: string }
  wiCounties: string[]
  formValuesContext: Context<FormValues>
  userId: number
  signedIn: boolean
  userIsAdmin: boolean
  userIsGuest: boolean
  sitemapDate: string
}

const B2bCalculatorSteps: React.FC<B2bCalculatorStepProps> = memo(() => {
  const [step, setStep] = useState(0)
  const [formValues, setFormValues] = useState<FormValues>({} as FormValues)
  const FormValuesContext = React.createContext<FormValues>(formValues)
  const [isBeforeTaxes, setIsBeforeTaxes] = useState(false)
  const [isAfterTaxes, setIsAfterTaxes] = useState(false)
  const usStates = STATES
  const wiCounties: string[] = COUNTIES
  const [modalOpen, setModalOpen] = useState(false)
  const [isChanged, setIsChanged] = useState(false)
  const [hideLogo, sethideLogo] = useState(false)
  const childRef = useRef<{ handleButtonClick: () => void }>(null)

  const handleNextClick = (): void => {
    setStep(step + 1)
    setIsChanged(false)
  }
  const handleBackButton = (): void => {
    if (isChanged) {
      setModalOpen(true)
      childRef?.current?.handleButtonClick()
      setIsChanged(false)
    } else {
      setStep(step - 1)
    }
  }

  const handleBackClick = (): void => {
    setStep(2)
  }

  const renderLogoAndBackButton = (): React.ReactNode => {
    if (step >= 0) {
      return (
        <LogoAndBackButton
          formValuesContext={FormValuesContext}
          activeStep={step}
          backButtonCondition={1}
          onBackClick={handleBackButton}
          setModalOpen={setModalOpen}
          modalOpen={modalOpen}
          setStep={setStep}
          setValues={setFormValues}
        />
      )
    }
  }

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent): void => {
      event.preventDefault()
      event.returnValue = false
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => { window.removeEventListener('beforeunload', handleBeforeUnload) }
  }, [])

  const renderStep = (): JSX.Element => {
    switch (step) {
      case 0:
        return (
          <Router>
            <Routes>
              <Route
                path={`/${I18n.locale as string}/employers/:id`}
                element={
                  <PinCodeModal
                    formValuesContext={FormValuesContext}
                    setValues={setFormValues}
                    handleNext={handleNextClick}
                  />
                }
              />
            </Routes>
          </Router>
        )
      case 1:
        return (
          <ConfirmationJobInfo
            formValuesContext={FormValuesContext}
            setValues={setFormValues}
            handleNext={handleNextClick}
          />
        )
      case 2:
        return (
          <Earnings
            formValuesContext={FormValuesContext}
            setValues={setFormValues}
            handleNext={handleNextClick}
            handleBack={handleBackClick}
            isBeforeTaxes={isBeforeTaxes}
            setIsBeforeTaxes={setIsBeforeTaxes}
            setIsChanged={setIsChanged}
            ref={childRef}
          />
        )
      case 3:
        return (
          <Taxes
            formValuesContext={FormValuesContext}
            setValues={setFormValues}
            handleNext={handleNextClick}
            handleBack={handleBackClick}
            isAfterTaxes={isAfterTaxes}
            setIsAfterTaxes={setIsAfterTaxes}
            setIsChanged={setIsChanged}
            ref={childRef}
          />
        )
      case 4:
        return (
          <BenefitsList
            formValuesContext={FormValuesContext}
            setValues={setFormValues}
            handleNext={handleNextClick}
            setIsChanged={setIsChanged}
            ref={childRef}
            sethideLogo= {sethideLogo}
          />
        )
      case 5:
        return (
          <Router>
            <Routes>
              <Route
                path={`/${I18n.locale as string}/employers/:id`}
                element={
                  <Benefits
                    formValuesContext={FormValuesContext}
                    setValues={setFormValues}
                    handleNext={handleNextClick}
                    handleBack={handleBackClick}
                    setIsChanged={setIsChanged}
                    ref={childRef}
                  />
                }
              />
            </Routes>
          </Router>
        )
      case 6:
        return (
          <BenefitsCliff
            formValuesContext={FormValuesContext}
            usStates={usStates}
            wiCounties={wiCounties}
            values={formValues}
          />
        )
      default:
        return (
          <BenefitsCliff
            formValuesContext={FormValuesContext}
            usStates={usStates}
            wiCounties={wiCounties}
            values={formValues}
          />
        )
    }
  }

  return (
    <>
      {!hideLogo && (renderLogoAndBackButton())}
      {renderStep()}
    </>
  )
})

B2bCalculatorSteps.displayName = 'B2bCalculatorSteps'

export default B2bCalculatorSteps
