import React, { memo, useContext, useEffect, useState } from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Divider from '@mui/material/Divider'
import ColorDivider from '../ColorDivider'
import BenefitToggleButton from '../BenefitToggleButton'
import SelectDeselectButtonGroup from '../useSelectDeselect'
import ContributionHeading from '../ContributionHeading'
import { replaceUrlWithLink, scrollToTop } from '../../../Helper'
import { calculateBenefitValue, calculateLifeInsuranceCareContribution, getBenefitOptions } from '../helpers/Benefits'
import { DEFAULT_PERCENTAGE, SHORT_TERM_DISABILITY_BENEFIT } from '../../../Constants'
import { type BenefitData, type BenefitDetailsProps } from '../../../EmployeeCalculatorInterface'
import { type Adults } from '../../AdultsInterface'
import './styles.sass'
import NumberInput from '../NumberInput'

const BenefitDetails: React.FC<BenefitDetailsProps> = memo(({ title, benefitType, calculateAmount, formValuesContext, benefitDataCompany, setValues }) => {
  const formValues = useContext(formValuesContext)
  const employeeType = formValues?.adults[0]?.employee?.company_name ?? ''
  const [disabled, setDisabled] = useState(false)
  const [providerOptions, setProviderOptions] = useState([''])
  const [typeOptions, setTypeOptions] = useState([{ option: '', value: '' }])
  const [coverageOptions, setCoverageOptions] = useState([{ option: '', value: '' }])
  const uniqueProviderOptions = Array.from(new Set(providerOptions.map(item => item)))
  const uniqueTypeOptions = Array.from(new Set(typeOptions.map(item => item)))
  const uniqueCoverageOptions = Array.from(new Set(coverageOptions.map(item => item)))
  const [, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const benefitTypeKey = `${benefitType}BenefitData` as keyof Adults
  const extractBenefitType = (): BenefitData | undefined => {
  return formValues?.adults[0]?.[benefitTypeKey] as BenefitData | undefined
  }
  const extractedType = extractBenefitType()
  const type = (extractedType?.type as string) || (uniqueTypeOptions[0]?.value ?? '')
  const initialBenefitData: BenefitData = {
    name: extractedType?.name as string,
    amount: extractedType?.amount as number,
    coverage: extractedType?.coverage as string,
    contribution: extractedType?.contribution as string,
    isSelected: !!(extractedType?.isSelected),
    provider: extractedType?.provider !== ''
      ? extractedType?.provider as string
      : uniqueProviderOptions[0]?.toLowerCase()?.replace(/\s+/g, '_'),
    type
  }

  const [benefitData, setBenefitData] = useState(initialBenefitData)
  const shouldDisable = benefitData.coverage === null || (type !== undefined && benefitData.type === null) || benefitData.provider === null

  useEffect(() => {
    if (benefitDataCompany) {
      const { providerOptions, typeOptions, coverageOptions } = getBenefitOptions(employeeType, benefitType, benefitDataCompany)
      setProviderOptions(providerOptions)
      setTypeOptions(typeOptions)
      setCoverageOptions(coverageOptions)
    }
  }, [benefitDataCompany])

  useEffect(() => {
    setBenefitData((prevData) => {
      const coverageString = prevData.coverage ?? '';
      const updatedAmount = benefitType === 'life_insurance'
        ? calculateLifeInsuranceCareContribution(coverageString)
        : prevData.amount;
      return {
        ...prevData,
        amount: updatedAmount
      }
    })
  }, [benefitData.coverage, benefitType])


  useEffect(() => {
    if (benefitData.type === 'basic' && benefitType ==='life_insurance') {
      setBenefitData((prevData) => ({
        ...prevData,
        amount: 0,
        coverage: ''
      }));
    }
  }, [benefitData.type])
  
  
  useEffect(() => {
    if (initialBenefitData.provider && initialBenefitData.type && initialBenefitData.coverage) {
      setBenefitData(initialBenefitData)
    }
  }, [initialBenefitData.provider, initialBenefitData.type, initialBenefitData.coverage])

  useEffect(() => {
    setDisabled(shouldDisable)
  }, [shouldDisable])

  useEffect(() => {
    if (benefitData.name === SHORT_TERM_DISABILITY_BENEFIT) {
      setBenefitData((data) => ({
        ...data,
        amount: parseFloat((calculateBenefitValue(formValues) ?? 0).toFixed(2))
      }))
      return
    }
    if (benefitData.provider && benefitData.coverage && benefitData.type) {
      const provider = benefitData.provider
      const coverage = benefitData.coverage
      const type = benefitData.type

      const { amount, contribution } = calculateAmount(provider, type, coverage)
      setBenefitData((data) => ({
        ...data,
        amount,
        contribution: contribution.toString()
      }))
    }
  }, [benefitData.provider, benefitData.type, benefitData.coverage])
  const buildCoverageText = () => {
    if (benefitType === 'life_insurance') {
      if (benefitData.type === null) {
        return <p>Select plan details to see employer contribution.</p>;
      }
      if (benefitData.type === 'basic') {
        return (
          <p>
           This benefit covers {formValues.BenefitPayoutSelection.lifeInsurancePayout * DEFAULT_PERCENTAGE}% of your salary for {formValues.BenefitPayoutDuration.lifeInsuranceDuration}.
          </p>
        )
      } else if (benefitData.type === 'supplemental') {
        return (
          <p dangerouslySetInnerHTML={{ __html: replaceUrlWithLink(formValues.benefitExplanation.lifeInsuranceBenefitExplanation) }} />
        )
      }
    }

    return (
      <p>
        This benefit covers {formValues.BenefitPayoutSelection[benefitType === 'Long-Term Disability Insurance' ? 'longTermDisabilityPayout' : 'shortTermDisabilityPayout'] * DEFAULT_PERCENTAGE}% of your salary {formValues.BenefitPayoutDuration[benefitType === 'Long-Term Disability Insurance' ? 'longTermDisabilityDuration' : 'shortTermDisabilityDuration']}.
      </p>
    )
  }
  
  const renderBenefitToggleButton = (type: string, valueKey: any, options: any) => (
    <BenefitToggleButton
      type={type}
      value={valueKey == 'type' ? (benefitData?.type ?? '') : benefitData?.coverage ?? ''}
      options={options}
      setValue={(newValue) => {
        setBenefitData((data) => ({ ...data, [valueKey]: newValue }));
      }}
      setSelected={(newValue) => {
        setBenefitData((data) => ({ ...data, isSelected: newValue }));
      }}
    />
  )

  scrollToTop()

  return (
    <div className='dental-container'>
      <div className='page-header'>
        <b>{title}</b>
        <p>Choose the plan that best fits your goals and estimated budget. Be sure to reach out to your HR contact with questions.</p>
      </div>

      <ColorDivider />

      <div className='page-body'>
        <b>Providers</b>
        <ToggleButtonGroup
          color='secondary'
          value={benefitData.provider || uniqueProviderOptions[0]?.toLowerCase()?.replace(/\s+/g, '_')}
          exclusive
          onChange={(_, newProvider) => {
            if (newProvider !== null) {
              setBenefitData((data) => ({ ...data, provider: newProvider }))
            }
          }}
        >
          {uniqueProviderOptions.map((option) => (
            <ToggleButton key={option} value={option.toLowerCase().replace(/\s+/g, '_')} className='provider-option'>
              {option}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>

        <div className='benefit-data'>
          <div className='montly-data'>
            {benefitType === 'Short-Term Disability Insurance' ? (
              <b>${(calculateBenefitValue(formValues)).toFixed(2) ?? 0}</b>
            ) : (
              <b>${(benefitData?.amount ?? 0).toFixed(2)}</b>
            )}
            <p>PRE-TAX MONTHLY PREMIUM</p>
          </div>

          <Divider variant='middle' />

          <h2>Employer Contribution</h2>
          <ContributionHeading benefitData={benefitData} benefitType='premium' isPension={false} formValuesContext= {formValuesContext} />

          <Divider variant='middle' />

          <div>
            {benefitType === 'Long-Term Disability Insurance' || benefitType === 'Short-Term Disability Insurance' || benefitType== 'life_insurance' ? (
              <>
                <h2>Coverage</h2>    
                  {buildCoverageText()} 
                  {benefitType === 'life_insurance' && uniqueTypeOptions.length !== 0 && renderBenefitToggleButton('Type', 'type', uniqueTypeOptions.slice(0, 2))}
              </>
            ) : (
              <>
                {uniqueTypeOptions.length !== 0 && renderBenefitToggleButton('Type', 'type', uniqueTypeOptions.slice(0, 2))}
                {renderBenefitToggleButton('Coverage', 'coverage', uniqueCoverageOptions)}
              </>
            )}
          </div>
          {benefitType === 'life_insurance' && benefitData.type === 'supplemental' && (
          <div className='benefit-input-container'>
            <h2>Enter the Premium of selected Plan</h2>

            <div className={`options ${errorMessage !== '' ? 'error' : ''}`}>
              <NumberInput
                value={benefitData.coverage ?? ''} 
                setValue={(newValue: string) => {
                  setBenefitData((prevData) => ({ ...prevData, coverage: newValue }))
                }}
                setError={setErrorMessage}
                setIsError={setError}
                annualContributionStartrangeValue={0}
                label='$'
                setSelected={(newValue: boolean) => {
                  setBenefitData((prevData) => ({ ...prevData, isSelected: newValue }))
                }}
              />
            </div>
          </div>
)}

          <div className='selected-button'>
            <SelectDeselectButtonGroup
              formValuesContext={formValuesContext}
              setValues={setValues}
              benefitData={benefitData}
              setIsSelected={(newValue) => {
                setBenefitData((data) => ({ ...data, isSelected: newValue }))
              }}
              error={disabled}
            />
          </div>
        </div>
      </div>
    </div>
  )
})

export default BenefitDetails