import React from 'react'
import { Container, Typography, Link, List, ListItem, Divider } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, Arial, sans-serif',
  },
})

const Terms = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth='md' sx={{ padding: '2rem' }}>
        <Typography variant='h3' align='center' gutterBottom sx={{ fontSize: '26px' }}>
          Terms and Conditions of <span style={{ fontWeight: 'bold' }}>Parsley</span>
        </Typography>

        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          These Terms govern
        </Typography>

        <List
          sx={{ listStyleType: 'disc', paddingLeft: '2rem', fontSize: '10.5px', paddingTop: '0' }}
        >
          <ListItem sx={{ display: 'list-item' }}>the use of this Application, and,</ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            any other related Agreement or legal relationship with the Owner
          </ListItem>
        </List>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          in a legally binding way. Capitalized words are defined in the relevant dedicated section
          of this document.
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          The User must read this document carefully.
        </Typography>
        <Typography sx={{ fontSize: '10.5px', marginTop: '2rem' }}>
          This Application is provided by:
        </Typography>

        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px' }}>
          Parsley Solutions, PBC, 218 Jackson St., Madison, WI 53704
        </Typography>

        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', fontWeight: 'bold' }}>
          Owner contact email:{' '}
          <Link href='mailto:info@yourparsley.com' underline='hover'>
            info@yourparsley.com
          </Link>
        </Typography>

        <Typography
          variant='h5'
          gutterBottom
          sx={{ marginTop: '2rem', fontSize: '18px', fontWeight: 'bold' }}
        >
          What the User should know at a glance
        </Typography>
        <List
          sx={{ listStyleType: 'disc', paddingLeft: '2rem', fontSize: '10.5px', lineHeight: 1.6 }}
        >
          <ListItem sx={{ display: 'list-item' }}>
            Please note that some provisions in these Terms may only apply to certain categories of
            Users. In particular, certain provisions may only apply to Consumers or to those Users
            that do not qualify as Consumers. Such limitations are always explicitly mentioned
            within each affected clause. In the absence of any such mention, clauses apply to all
            Users.
          </ListItem>
        </List>
        <Divider />

        <Typography
          variant='h5'
          gutterBottom
          sx={{ marginTop: '2rem', fontSize: '18px', fontWeight: 'bold' }}
        >
          TERMS OF USE
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          Unless otherwise specified, the terms of use detailed in this section apply generally when
          using this Application.
        </Typography>

        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          Single or additional conditions of use or access may apply in specific scenarios and in
          such cases are additionally indicated within this document.
        </Typography>

        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          By using this Application, Users confirm to meet the following requirements:
        </Typography>
        <List
          sx={{ listStyleType: 'disc', paddingLeft: '2rem', fontSize: '10.5px', lineHeight: 1.6 }}
        >
          <ListItem sx={{ display: 'list-item' }}>
            There are no restrictions for Users in terms of being Consumers or Business Users;
          </ListItem>
        </List>

        <Typography
          variant='h5'
          gutterBottom
          sx={{ marginTop: '2rem', fontSize: '15px', fontWeight: 'bold' }}
        >
          Account registration
        </Typography>

        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          To use the Service Users may register or create a User account, providing all required
          data or information in a complete and truthful manner. Users may also use the Service
          without registering or creating a User account, however, this may cause limited
          availability of certain features or functions. Users are responsible for keeping their
          login credentials confidential and safe. For this reason, Users are also required to
          choose passwords that meet the highest standards of strength permitted by this
          Application. By registering, Users agree to be fully responsible for all activities that
          occur under their username and password. Users are required to immediately and
          unambiguously inform the Owner via the contact details indicated in this document, if they
          think their personal information, including but not limited to User accounts, access
          credentials or personal data, have been violated, unduly disclosed or stolen.
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          The Data processing is carried out using computers and/or IT enabled tools, following
          organizational procedures and modes strictly related to the purposes indicated. In
          addition to the Owner, in some cases, the Data may be accessible to certain types of
          persons in charge, involved with the operation of this Application (administration, sales,
          marketing, legal, system administration) or external parties (such as third-party
          technical service providers, mail carriers, hosting providers, IT companies,
          communications agencies) appointed, if necessary, as Data Processors by the Owner. The
          updated list of these parties may be requested from the Owner at any time.
        </Typography>
        <Typography variant='h6' gutterBottom sx={{ fontWeight: 'bold', fontSize: '10px' }}>
          Account termination
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10px', lineHeight: 1.6 }}>
          Users can terminate their account and stop using the Service at any time by doing the
          following:
        </Typography>
        <List
          sx={{ listStyleType: 'disc', paddingLeft: '2rem', fontSize: '10.5px', lineHeight: 1.6 }}
        >
          <ListItem sx={{ display: 'list-item' }}>
            By selecting “Delete My Account” in the application.
          </ListItem>
        </List>
        <Typography variant='h6' gutterBottom sx={{ fontWeight: 'bold', fontSize: '12px' }}>
          Retention Time
        </Typography>

        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          Unless specified otherwise in this document, Personal Data shall be processed and stored
          for as long as required by the purpose they have been collected for and may be retained
          for longer due to applicable legal obligation or based on the Users’ consent.
        </Typography>
        <Typography variant='h6' gutterBottom sx={{ fontWeight: 'bold', fontSize: '12px' }}>
          Account suspension and deletion
        </Typography>

        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          The Owner reserves the right, at its sole discretion, to suspend or delete at any time and
          without notice, User accounts that it deems inappropriate, offensive or in violation of
          these Terms. The suspension or deletion of User accounts shall not entitle Users to any
          claims for compensation, damages or reimbursement.
        </Typography>

        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          The suspension or deletion of accounts due to causes attributable to the User does not
          exempt the User from paying any applicable fees or prices.
        </Typography>
        <Typography
          variant='h5'
          gutterBottom
          sx={{ fontSize: '15px', fontWeight: 'bold', marginTop: '2rem' }}
        >
          Content on this Application
        </Typography>

        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          Unless where otherwise specified or clearly recognizable, all content available on this
          Application is owned or provided by the Owner or its licensors, or is information that is
          publicly available.
        </Typography>

        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          The Owner undertakes its utmost effort to ensure that the content provided on this
          Application infringes no applicable legal provisions or third-party rights. However, it
          may not always be possible to achieve such a result.
        </Typography>

        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          In such cases, without prejudice to any legal prerogatives of Users to enforce their
          rights, Users are kindly asked to preferably report related complaints using the contact
          details provided in this document.
        </Typography>

        <Typography
          variant='h5'
          gutterBottom
          sx={{ fontSize: '12px', fontWeight: 'bold', marginTop: '2rem' }}
        >
          Rights regarding content on this Application - All rights reserved
        </Typography>

        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          The Owner holds and reserves all intellectual property rights for any such content. Users
          may not, therefore, use such content in any way that is not necessary or implicit in the
          proper use of the Service.
        </Typography>

        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          In particular, but without limitation, Users may not copy, download, share (beyond the
          limits set forth below), modify, translate, transform, publish, transmit, sell,
          sublicense, edit, transfer/assign to third parties or create derivative works from the
          content available on this Application, nor allow any third party to do so through the User
          or their device, even without the User's knowledge.
        </Typography>

        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          Where explicitly stated on this Application, the User may download, copy and/or share some
          content available through this Application for its sole personal and non-commercial use
          and provided that the copyright attributions and all the other attributions requested by
          the Owner are correctly implemented.
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          Any applicable statutory limitation or exception to copyright shall stay unaffected.
        </Typography>

        <Typography
          variant='h5'
          gutterBottom
          sx={{ fontSize: '15px', fontWeight: 'bold', marginTop: '2rem' }}
        >
          Access to external resources
        </Typography>

        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          Through this Application Users may have access to external resources provided by third
          parties. Users acknowledge and accept that the Owner has no control over such resources
          and is therefore not responsible for their content and availability.
        </Typography>

        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          Conditions applicable to any resources provided by third parties, including those
          applicable to any possible grant of rights in content, result from each such third
          parties’ terms and conditions or, in the absence of those, applicable statutory law.
        </Typography>
        <Typography
          variant='h5'
          gutterBottom
          sx={{ fontSize: '15px', fontWeight: 'bold', marginTop: '2rem' }}
        >
          Acceptable use
        </Typography>

        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          This Application and the Service may only be used within the scope of what they are
          provided for, under these Terms and applicable law.
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          Users are solely responsible for making sure that their use of this Application and/or the
          Service violates no applicable law, regulations or third-party rights.
        </Typography>
        <Typography
          variant='body1'
          paragraph
          sx={{ fontSize: '10.5px', lineHeight: 1.6, fontWeight: 'bold' }}
        >
          Therefore, the Owner reserves the right to take any appropriate measure to protect its
          legitimate interests including by denying Users access to this Application or the Service,
          terminating contracts, reporting any misconduct performed through this Application or the
          Service to the competent authorities – such as judicial or administrative authorities -
          whenever Users engage or are suspected to engage in any of the following activities:
        </Typography>
        <List
          sx={{ listStyleType: 'disc', paddingLeft: '2rem', fontSize: '10.5px', lineHeight: 1.6 }}
        >
          <ListItem sx={{ display: 'list-item' }}>
            violate laws, regulations and/or these Terms;
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>infringe any third-party rights;</ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            considerably impair the Owner’s legitimate interests;
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>offend the Owner or any third party.</ListItem>
        </List>
        <Typography
          variant='h5'
          gutterBottom
          sx={{ fontSize: '15px', fontWeight: 'bold', marginTop: '2rem' }}
        >
          Software license
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          Any intellectual or industrial property rights, and any other exclusive rights on software
          or technical applications embedded in or related to this Application are held by the Owner
          and/or its licensors.
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          Subject to Users’ compliance with and notwithstanding any divergent provision of these
          Terms, the Owner merely grants Users a revocable, non-exclusive, non-sublicensable and
          non-transferable license to use the software and/or any other technical means embedded in
          the Service within the scope and for the purposes of this Application and the Service
          offered.
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          This license does not grant Users any rights to access, usage or disclosure of the
          original source code. All techniques, algorithms, and procedures contained in the software
          and any documentation thereto related is the Owner’s or its licensors’ sole property.
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          All rights and license grants to Users shall immediately terminate upon any termination or
          expiration of the Agreement.
        </Typography>
        <Typography
          variant='h5'
          gutterBottom
          sx={{ fontSize: '18px', fontWeight: 'bold', marginTop: '2rem' }}
        >
          Liability and indemnification
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          Unless otherwise explicitly stated or agreed with Users, the Owner’s liability for damages
          in connection with the execution of the Agreement shall be excluded, limited and/or
          reduced to the maximum extent permitted by applicable law.
        </Typography>
        <Typography
          variant='h5'
          gutterBottom
          sx={{ fontSize: '15px', fontWeight: 'bold', marginTop: '2rem' }}
        >
          US Users
        </Typography>
        <Typography
          variant='h5'
          gutterBottom
          sx={{ fontSize: '12px', fontWeight: 'bold', marginTop: '2rem' }}
        >
          Disclaimer of Warranties
        </Typography>
        <Typography
          variant='body1'
          paragraph
          sx={{ fontSize: '10.5px', lineHeight: 1.6, fontWeight: 'bold' }}
        >
          This Application is provided strictly on an “as is” and “as available” basis. Use of the
          Service is at Users’ own risk. To the maximum extent permitted by applicable law, the
          Owner expressly disclaims all conditions, representations, and warranties — whether
          express, implied, statutory or otherwise, including, but not limited to, any implied
          warranty of merchantability, fitness for a particular purpose, or non-infringement of
          third-party rights. No advice or information, whether oral or written, obtained by the
          User from the Owner or through the Service will create any warranty not expressly stated
          herein.
        </Typography>
        <Typography
          variant='body1'
          paragraph
          sx={{ fontSize: '10.5px', lineHeight: 1.6, fontWeight: 'bold' }}
        >
          Without limiting the foregoing, the Owner, its subsidiaries, affiliates, licensors,
          officers, directors, agents, co-branders, partners, suppliers and employees do not warrant
          that the content is accurate, reliable or correct; that the Service will meet Users’
          requirements; that the Service will be available at any particular time or location,
          uninterrupted or secure; that any defects or errors will be corrected; or that the Service
          is free of viruses or other harmful components. Any content downloaded or otherwise
          obtained through the use of the Service is downloaded at Users' own risk and Users shall
          be solely responsible for any damage to
        </Typography>
        <Typography
          variant='body1'
          paragraph
          sx={{ fontSize: '10.5px', lineHeight: 1.6, fontWeight: 'bold' }}
        >
          Users’ computer system or mobile device or loss of data that results from such download or
          Users’ use of the Service.
        </Typography>
        <Typography
          variant='body1'
          paragraph
          sx={{ fontSize: '10.5px', lineHeight: 1.6, fontWeight: 'bold' }}
        >
          The Owner does not warrant, endorse, guarantee, or assume responsibility for any product
          or service advertised or offered by a third party through the Service or any hyperlinked
          website or service, and the Owner shall not be a party to or in any way monitor any
          transaction between Users and third-party providers of products or services.
        </Typography>
        <Typography
          variant='body1'
          paragraph
          sx={{ fontSize: '10.5px', lineHeight: 1.6, fontWeight: 'bold' }}
        >
          The Service may become inaccessible or it may not function properly with Users’ web
          browser, mobile device, and/or operating system. The owner cannot be held liable for any
          perceived or actual damages arising from Service content, operation, or use of this
          Service. Federal law, some states, and other jurisdictions, do not allow the exclusion and
          limitations of certain implied warranties. The above exclusions may not apply to Users.
          This Agreement gives Users specific legal rights, and Users may also have other rights
          which vary from state to state. The disclaimers and exclusions under this agreement shall
          not apply to the extent prohibited by applicable law.
        </Typography>
        <Typography
          variant='h5'
          gutterBottom
          sx={{ fontSize: '12px', fontWeight: 'bold', marginTop: '2rem' }}
        >
          Limitations of liability
        </Typography>
        <Typography
          variant='body1'
          paragraph
          sx={{ fontSize: '10.5px', lineHeight: 1.6, fontWeight: 'bold' }}
        >
          To the maximum extent permitted by applicable law, in no event shall the Owner, and its
          subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers
          and employees be liable for:
        </Typography>
        <List
          sx={{
            listStyleType: 'disc',
            paddingLeft: '2rem',
            fontSize: '10.5px',
            lineHeight: 1.6,
          }}
        >
          <ListItem sx={{ display: 'list-item', fontWeight: 'bold' }}>
            any indirect, punitive, incidental, special, consequential or exemplary damages,
            including without limitation damages for loss of profits, goodwill, use, data or other
            intangible losses, arising out of or relating to the use of, or inability to use, the
            Service; and
          </ListItem>
          <ListItem sx={{ display: 'list-item', fontWeight: 'bold' }}>
            any damage, loss or injury resulting from hacking, tampering or other unauthorized
            access or use of the Service or User account or the information contained therein;
          </ListItem>
          <ListItem sx={{ display: 'list-item', fontWeight: 'bold' }}>
            any errors, mistakes, or inaccuracies of content;
          </ListItem>
          <ListItem sx={{ display: 'list-item', fontWeight: 'bold' }}>
            personal injury or property damage, of any nature whatsoever, resulting from User access
            to or use of the Service;
          </ListItem>
          <ListItem sx={{ display: 'list-item', fontWeight: 'bold' }}>
            any unauthorized access to or use of the Owner’s secure servers and/or any and all
            personal information stored therein;
          </ListItem>
          <ListItem sx={{ display: 'list-item', fontWeight: 'bold' }}>
            any interruption or cessation of transmission to or from the Service;
          </ListItem>
          <ListItem sx={{ display: 'list-item', fontWeight: 'bold' }}>
            any bugs, viruses, trojan horses, or the like that may be transmitted to or through the
            Service;
          </ListItem>
          <ListItem sx={{ display: 'list-item', fontWeight: 'bold' }}>
            any errors or omissions in any content or for any loss or damage incurred as a result of
            the use of any content posted, emailed, transmitted, or otherwise made available through
            the Service; and/or
          </ListItem>
          <ListItem sx={{ display: 'list-item', fontWeight: 'bold' }}>
            the defamatory, offensive, or illegal conduct of any User or third party. In no event
            shall the Owner, and its subsidiaries, affiliates, officers, directors, agents,
            co-branders, partners, suppliers and employees be liable for any claims, proceedings,
            liabilities, obligations, damages, losses or costs in an amount exceeding the amount
            paid by User to the Owner hereunder in the preceding 12 months, or the period of
            duration of this agreement between the Owner and User, whichever is shorter.
          </ListItem>
        </List>
        <Typography
          variant='body1'
          paragraph
          sx={{ fontSize: '10.5px', lineHeight: 1.6, fontWeight: 'bold' }}
        >
          This limitation of liability section shall apply to the fullest extent permitted by law in
          the applicable jurisdiction whether the alleged liability is based on contract, tort,
          negligence, strict liability, or any other basis, even if company has been advised of the
          possibility of such damage.
        </Typography>
        <Typography
          variant='body1'
          paragraph
          sx={{ fontSize: '10.5px', lineHeight: 1.6, fontWeight: 'bold' }}
        >
          Some jurisdictions do not allow the exclusion or limitation of incidental or consequential
          damages, therefore the above limitations or exclusions may not apply to the User. The
          terms give User specific legal rights, and User may also have other rights which vary from
          jurisdiction to jurisdiction. The disclaimers, exclusions, and limitations of liability
          under the terms shall not apply to the extent prohibited by applicable law.
        </Typography>
        <Typography
          variant='h5'
          gutterBottom
          sx={{ fontSize: '12px', fontWeight: 'bold', marginTop: '2rem' }}
        >
          Indemnification
        </Typography>
        <Typography
          variant='body1'
          paragraph
          sx={{ fontSize: '10.5px', lineHeight: 1.6, fontWeight: 'bold' }}
        >
          The User agrees to defend, indemnify and hold the Owner and its subsidiaries, affiliates,
          officers, directors, agents, co-branders, partners, suppliers and employees harmless from
          and against any and all claims or demands, damages, obligations, losses, liabilities,
          costs or debt, and expenses, including, but not limited to, legal fees and expenses,
          arising from
        </Typography>
        <List
          sx={{ listStyleType: 'disc', paddingLeft: '2rem', fontSize: '10.5px', lineHeight: 1.6 }}
        >
          <ListItem sx={{ display: 'list-item', fontWeight: 'bold' }}>
            User’s use of and access to the Service, including any data or content transmitted or
            received by User;
          </ListItem>
          <ListItem sx={{ display: 'list-item', fontWeight: 'bold' }}>
            User’s violation of these terms, including, but not limited to, User’s breach of any of
            the representations and warranties set forth in these terms;
          </ListItem>
          <ListItem sx={{ display: 'list-item', fontWeight: 'bold' }}>
            User’s violation of any third-party rights, including, but not limited to, any right of
            privacy or intellectual property rights;
          </ListItem>
          <ListItem sx={{ display: 'list-item', fontWeight: 'bold' }}>
            User’s violation of any statutory law, rule, or regulation;
          </ListItem>
          <ListItem sx={{ display: 'list-item', fontWeight: 'bold' }}>
            any content that is submitted from User’s account, including third party access with
            User’s unique username, password or other security measure, if applicable, including,
            but not limited to, misleading, false, or inaccurate information; User’s wilful
            misconduct; or
          </ListItem>
          <ListItem sx={{ display: 'list-item', fontWeight: 'bold' }}>
            statutory provision by User or its affiliates, officers, directors, agents, co-branders,
            partners, suppliers and employees to the extent allowed by applicable law.
          </ListItem>
        </List>
        <Typography
          variant='h5'
          gutterBottom
          sx={{ marginTop: '2rem', fontSize: '18px', fontWeight: 'bold' }}
        >
          Common provisions
        </Typography>
        <Typography
          variant='h5'
          gutterBottom
          sx={{ marginTop: '2rem', fontSize: '15px', fontWeight: 'bold' }}
        >
          No Waiver
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          To ensure the best possible service level, the Owner reserves the right to interrupt the
          Service for maintenance, system updates or any other changes, informing the Users
          appropriately.
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          Within the limits of law, the Owner may also decide to suspend or discontinue the Service
          altogether. If the Service is discontinued, the Owner will cooperate with Users to enable
          them to withdraw Personal Data or information and will respect Users' rights relating to
          continued product use and/or compensation, as provided for by applicable law.
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          Additionally, the Service might not be available due to reasons outside the Owner’s
          reasonable control, such as “force majeure” events (infrastructural breakdowns or
          blackouts etc.).
        </Typography>
        <Typography
          variant='h5'
          gutterBottom
          sx={{ marginTop: '2rem', fontSize: '15px', fontWeight: 'bold' }}
        >
          Service reselling
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          Users may not reproduce, duplicate, copy, sell, resell or exploit any portion of this
          Application and of its Service without the Owner’s express prior written permission,
          granted either directly or through a legitimate reselling programme.
        </Typography>
        <Typography
          variant='h5'
          gutterBottom
          sx={{ marginTop: '2rem', fontSize: '15px', fontWeight: 'bold' }}
        >
          Privacy policy
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          For information about the use of their personal data, Users must refer to the privacy
          policy of this Application which is hereby declared to be part of these Terms.
        </Typography>
        <Typography
          variant='h5'
          gutterBottom
          sx={{ marginTop: '2rem', fontSize: '15px', fontWeight: 'bold' }}
        >
          Intellectual property rights
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          Without prejudice to any more specific provision of these Terms, any intellectual property
          rights, such as copyrights, trademark rights, patent rights and design rights related to
          this Application are the exclusive property of the Owner or its licensors and are subject
          to the protection granted by applicable laws or international treaties relating to
          intellectual property.
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          All trademarks — nominal or figurative — and all other marks, trade names, service marks,
          word marks, illustrations, images, or logos appearing in connection with this Application
          are, and remain, the exclusive property of the Owner or its licensors and are subject to
          the protection granted by applicable laws or international treaties related to
          intellectual property.
        </Typography>
        <Typography
          variant='h5'
          gutterBottom
          sx={{ marginTop: '2rem', fontSize: '15px', fontWeight: 'bold' }}
        >
          Changes to these Terms
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          The Owner reserves the right to amend or otherwise modify these Terms at any time. In such
          cases, the Owner will appropriately inform the User of these changes.
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          Such changes will only affect the relationship with the User from the date communicated to
          Users onwards.
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          The continued use of the Service will signify the User’s acceptance of the revised Terms.
          If Users do not wish to be bound by the changes, they must stop using the Service and may
          terminate the Agreement.
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          The applicable previous version will govern the relationship prior to the User's
          acceptance. The User can obtain any previous version from the Owner.
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          If legally required, the Owner will notify Users in advance of when the modified Terms
          will take effect.
        </Typography>
        <Typography
          variant='h5'
          gutterBottom
          sx={{ marginTop: '2rem', fontSize: '15px', fontWeight: 'bold' }}
        >
          Assignment of contract
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          The Owner reserves the right to transfer, assign, dispose of by novation, or subcontract
          any or all rights or obligations under these Terms, taking the User’s legitimate interests
          into account. Provisions regarding changes of these Terms will apply accordingly.
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          Users may not assign or transfer their rights or obligations under these Terms in any way,
          without the written permission of the Owner.
        </Typography>
        <Typography
          variant='h5'
          gutterBottom
          sx={{ marginTop: '2rem', fontSize: '15px', fontWeight: 'bold' }}
        >
          Contacts
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          All communications relating to the use of this Application must be sent using the contact
          information stated in this document.
        </Typography>
        <Typography
          variant='h5'
          gutterBottom
          sx={{ marginTop: '2rem', fontSize: '15px', fontWeight: 'bold' }}
        >
          Severability
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          Should any provision of these Terms be deemed or become invalid or unenforceable under
          applicable law, the invalidity or unenforceability of such provision shall not affect the
          validity of the remaining provisions, which shall remain in full force and effect.
        </Typography>
        <Typography
          variant='h5'
          gutterBottom
          sx={{ marginTop: '2rem', fontSize: '12px', fontWeight: 'bold' }}
        >
          US Users
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          Any such invalid or unenforceable provision will be interpreted, construed and reformed to
          the extent reasonably required to render it valid, enforceable and consistent with its
          original intent. These Terms constitute the entire Agreement between Users and the Owner
          with respect to the subject matter hereof, and supersede all other communications,
          including but not limited to all prior agreements, between the parties with respect to
          such subject matter. These Terms will be enforced to the fullest extent permitted by law.
        </Typography>
        <Typography
          variant='h5'
          gutterBottom
          sx={{ marginTop: '2rem', fontSize: '15px', fontWeight: 'bold' }}
        >
          Governing law
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          These Terms are governed by the law of the place where the Owner is based, as disclosed in
          the relevant section of this document, without regard to conflict of laws principles.
        </Typography>
        <Typography
          variant='h5'
          gutterBottom
          sx={{ marginTop: '2rem', fontSize: '12px', fontWeight: 'bold' }}
        >
          Prevalence of national law
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          However, regardless of the above, if the law of the country that the User is located in
          provides for a higher applicable consumer protection standard, such higher standards shall
          prevail.
        </Typography>
        <Typography
          variant='h5'
          gutterBottom
          sx={{ marginTop: '2rem', fontSize: '15px', fontWeight: 'bold' }}
        >
          Venue of jurisdiction
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          The exclusive competence to decide on any controversy resulting from or connected to these
          Terms lies with the courts of the place where the Owner is based, as displayed in the
          relevant section of this document.
        </Typography>
        <Typography
          variant='h5'
          gutterBottom
          sx={{ marginTop: '2rem', fontSize: '12px', fontWeight: 'bold' }}
        >
          Exception for Consumers in Europe
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          The above does not apply to any Users that qualify as European Consumers, nor to Consumers
          based in the United Kingdom, Switzerland, Norway or Iceland.
        </Typography>
        <Typography
          variant='h5'
          gutterBottom
          sx={{ marginTop: '2rem', fontSize: '12px', fontWeight: 'bold' }}
        >
          Exception for Consumers in Brazil
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          The above does not apply to Users in Brazil that qualify as Consumers.
        </Typography>
        <Typography
          variant='h5'
          gutterBottom
          sx={{ marginTop: '2rem', fontSize: '12px', fontWeight: 'bold' }}
        >
          US Users
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          Each party specifically waives any right to trial by jury in any court in connection with
          any action or litigation.
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          Any claims under these terms shall proceed individually and no party shall join in a class
          action or other proceeding with or on behalf of others.
        </Typography>
        <Typography
          variant='h5'
          gutterBottom
          sx={{ marginTop: '2rem', fontSize: '15px', fontWeight: 'bold' }}
        >
          US Users
        </Typography>
        <Typography
          variant='h5'
          gutterBottom
          sx={{ marginTop: '2rem', fontSize: '12px', fontWeight: 'bold' }}
        >
          Surviving provisions
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          This Agreement shall continue in effect until it is terminated by either this Application
          or the User. Upon termination, the provisions contained in these Terms that by their
          context are intended to survive termination or expiration will survive, including but not
          limited to the following:
        </Typography>
        <List
          sx={{ listStyleType: 'disc', paddingLeft: '2rem', fontSize: '10.5px', lineHeight: 1.6 }}
        >
          <ListItem sx={{ display: 'list-item' }}>
            the User’s grant of licenses under these Terms shall survive indefinitely;
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            the User’s indemnification obligations shall survive for a period of five years from the
            date of termination;
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            the disclaimer of warranties and representations, and the stipulations under the section
            containing indemnity and limitation of liability provisions, shall survive indefinitely.
          </ListItem>
        </List>
        <Typography
          variant='h5'
          gutterBottom
          sx={{ marginTop: '2rem', fontSize: '10px', fontWeight: 'bold' }}
        >
          Definitions and legal references
        </Typography>
        <Typography variant='body1' paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          Latest update: January 12,
        </Typography>
      </Container>
    </ThemeProvider>
  )
}

export default Terms
