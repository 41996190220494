import React, { type Dispatch, type SetStateAction } from 'react'
import Select from 'react-select'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'

import InputField from './InputField'
import ErrorMessage from './../ErrorMessage'
import { getSelectdValue } from './../../Helper'
import { LIFELINE_SUBSIDY } from '../../Constants'

interface Props {
  checkLifeline: boolean
  enrolledLifeline: boolean
  phoneOverride: boolean
  setPhoneOverride: Dispatch<SetStateAction<boolean>>
  setIsChanged: Dispatch<SetStateAction<boolean>>
  phoneOverrideAmt: number
  setPhoneOverrideAmt: Dispatch<SetStateAction<number>>
  numberOfPhonePlans: number
  setNumberOfPhonePlans: Dispatch<SetStateAction<number>>
  setIsRequiredFieldsEmpty: Dispatch<SetStateAction<boolean>>
}

const PhoneBillsInputs: React.FC<Props> = (props) => {
  const {
    checkLifeline,
    enrolledLifeline,
    phoneOverride,
    setPhoneOverride,
    setIsChanged,
    phoneOverrideAmt,
    setPhoneOverrideAmt,
    numberOfPhonePlans,
    setNumberOfPhonePlans,
    setIsRequiredFieldsEmpty
  } = props

  const generatePhonePlanOptions = (): Array<{ label: string, value: number }> => {
    const phonePlanOptions = []
    for (let i = checkLifeline ? 1 : 0; i <= 4; i++) {
      phonePlanOptions.push({ label: String(i), value: i })
    }
    return phonePlanOptions
  }

  const phonePlanOptions = generatePhonePlanOptions()

  return (
    <div className='phone-bill'>
      <b>Phone Bills</b>

      <div className='amount-input'>
        <p>How many phone lines does your family have?</p>
        <Select
          className='basic-single'
          classNamePrefix='select'
          isSearchable={false}
          onChange={(e) => {
            if (e.value === 0) {
              setPhoneOverrideAmt(0)
            }
            setNumberOfPhonePlans(e.value)
            setIsChanged(true)
            setIsRequiredFieldsEmpty(false)
          }}
          name='state'
          options={phonePlanOptions}
          value={getSelectdValue(numberOfPhonePlans, phonePlanOptions)}
        />
      </div>

      <RadioGroup
        onChange={() => {
          setPhoneOverride(!phoneOverride)
          setIsChanged(true)
        }}
        value={String(phoneOverride)}
      >
        <div className='amount-input'>
          <FormControlLabel
            value='true'
            control={<Radio />}
            label='I want to enter how much my household spends on phone bills.'
          />

          {phoneOverride &&
            <InputField
              setValue={setPhoneOverrideAmt}
              setIsChanged={setIsChanged}
              label='Amount in $'
              value={phoneOverrideAmt}
              disabled={numberOfPhonePlans === 0 && phoneOverrideAmt > 0}
            />
          }
        </div>

        <div>
          {numberOfPhonePlans === 0 && phoneOverrideAmt > 0 &&
            <ErrorMessage text='You must have at least one phone line to enter phone expenses' />
          }

          {checkLifeline &&
            <i>Enter phone costs to estimate Lifeline discount.</i>
          }

          {enrolledLifeline && phoneOverrideAmt < LIFELINE_SUBSIDY && phoneOverride &&
            <ErrorMessage text={`Your phone bill cannot be less than $${LIFELINE_SUBSIDY} because you are currently using Lifeline.
                                 You can change this on the benefits selection screen.`} />
          }
        </div>

        <FormControlLabel
          onChange={() => {
            setPhoneOverrideAmt(0)
            setIsChanged(true)
          }}
          value='false'
          control={<Radio />}
          label='I want the calculator to estimate my household&apos;s phone expenses '
        />
        {numberOfPhonePlans === 0 && !phoneOverride &&
          <ErrorMessage text='You must have at least one phone line to estimate phone expenses.' />
        }
      </RadioGroup>

    </div>
  )
}
export default PhoneBillsInputs
