import React, { memo, useContext, useEffect, useState } from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Divider from '@mui/material/Divider'

import ColorDivider from '../ColorDivider'
import NumberInput from '../NumberInput'
import { addThousandsSeparator, calculateDependentCareLimit, calculateMedicalFsaLimit, calculatePensionContributionPerPayPeriod, calculatePensionRetirementInvestment, replaceUrlWithLink, scrollToTop } from '../../../Helper'
import ContributionHeading from '../ContributionHeading'
import SelectDeselectButtonGroup from '../useSelectDeselect'
import {
  calculateBenefitsContribution,
  calculateFSAAndRetirementContribution,
  calculateLongTermCareContribution,
  getBenefitOptions,
  replaceWhitespaceWithUnderscore
} from '../helpers/Benefits'
import { type Adults } from '../../AdultsInterface'
import type { ContributionLimits, FormValues } from '../../../FormValuesInterfaces'
import type { BenefitData, FSABenefitProps } from '../../../EmployeeCalculatorInterface'

import './styles.sass'

type ContributionLimitsKey = keyof ContributionLimits

interface BenefitProps extends FSABenefitProps {
  componentName: string
  benefitKey: string
}

const BenefitsContributionsDetails: React.FC<BenefitProps> = memo((props) => {
  const {
    formValuesContext,
    benefitDataCompany,
    setValues,
    setDisabled,
    componentName,
    benefitKey
  } = props

  const formValues: FormValues = useContext(formValuesContext)
  const employeeType = formValues?.adults[0]?.employee?.company_name ?? ''
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const benefitName = replaceWhitespaceWithUnderscore(componentName)
  const { providerOptions } = getBenefitOptions(employeeType, benefitName, benefitDataCompany)
  const annualContributionLimit = (formValues.benefitsAnnualContributionLimit)?.[`${benefitKey}Limit` as ContributionLimitsKey]?.[0]
  const annualSalary = formValues?.adults?.[0]?.currentJobs?.[0]?.currentAnnualWage ?? 0
  const [totalRetirementInvestment, settotalRetirementInvestment] = useState(0)
  const [isUserInteracting, setIsUserInteracting] = useState(false)
  const benefitTypeKey = `${benefitKey}BenefitData` as keyof Adults

  const extractBenefitType = (): BenefitData | undefined => {
    return (formValues?.adults[0]?.[benefitTypeKey] as BenefitData | undefined) ?? undefined
  }
  const extractedType = extractBenefitType()
  const [benefitData, setBenefitData] = useState({
    provider: extractedType?.provider !== ''
      ? extractedType?.provider as string
      : replaceWhitespaceWithUnderscore(providerOptions[0]),
    name: extractedType?.name as string,
    amount: extractedType?.amount as number,
    coverage: extractedType?.coverage as string,
    contribution: extractedType?.contribution as string,
    annualContributionLimit: annualContributionLimit || '0',
    dependentCareWithContribution: 0,
    isSelected: extractedType?.isSelected

  })

  useEffect(() => {
    setDisabled(error)
  }, [error])
  useEffect(() => {
    setBenefitData((prevData) => {
      let updatedAmount: number = prevData.amount

      if (componentName.toLowerCase() === 'pension') {
        updatedAmount = calculatePensionContributionPerPayPeriod(benefitData.coverage, annualSalary)
      } else {
        if (benefitKey === 'long-term_care_insurance') {
          updatedAmount = calculateLongTermCareContribution(prevData.coverage)
        } else {
          updatedAmount = calculateFSAAndRetirementContribution(
            prevData?.coverage,
            formValues?.adults[0]?.payFrequency as string
          )
        }
      }

      return {
        ...prevData,
        amount: updatedAmount,
        contribution: calculateBenefitsContribution(prevData?.provider),
      }
    })
  }, [benefitData.coverage])

  useEffect(() => {
    settotalRetirementInvestment(
      calculatePensionRetirementInvestment(benefitData.coverage, annualSalary)
    )

  }, [[benefitData.coverage]])
  const dependentCareLimit = calculateDependentCareLimit(
    annualContributionLimit,
    formValues.employerSeed,
    formValues.EmployerContributionLimit
  )
  scrollToTop()

  const handleProviderChange = (_event: React.MouseEvent<HTMLElement>, newProvider: string): void => {
    setBenefitData((prevData) => ({ ...prevData, provider: newProvider, isSelected: false }))
  }
  const sanitizedMaxPension = parseFloat(formValues.AnnualContributionMaximum.pension.replace('%', ''))
  const seedDeposit =
    benefitKey === 'medical'
      ? formValues.employerSeed.healthCareFSA || 0
      : benefitKey === 'dependentCare'
        ? formValues.employerSeed.dependentCareFSA || 0
        : null;

  const isDependentCare = seedDeposit !== 0 && benefitKey === 'dependentCare';
  const isMedical = seedDeposit !== 0 && benefitKey === 'medical';


  useEffect(() => {
    if (seedDeposit !== 0 && benefitKey === 'medical') {
      setBenefitData((prevData) => {
        const employeeContribution = isNaN(parseFloat(benefitData?.coverage))
          ? seedDeposit
          : calculateMedicalFsaLimit(
            parseFloat(benefitData?.coverage),
            { healthCareFSA: seedDeposit },
            { healthCareFSA: formValues.EmployerContributionLimit.healthCareFSA }
          )
        return {
          ...prevData,
          dependentCareWithContribution: employeeContribution,
          contribution: (employeeContribution - (isNaN(parseFloat(benefitData?.coverage)) ? 0 : parseFloat(benefitData?.coverage))).toString()
        }
      })
    }
    else if (seedDeposit !== 0 && benefitKey === 'dependentCare') {
      setBenefitData((prevData) => {
        const employeeContribution = isNaN(parseFloat(benefitData?.coverage))
          ? seedDeposit
          : seedDeposit + (parseFloat(benefitData?.coverage) * 2)
        return {
          ...prevData,
          dependentCareWithContribution: employeeContribution,
          contribution: (employeeContribution - (isNaN(parseFloat(benefitData?.coverage)) ? 0 : parseFloat(benefitData?.coverage))).toString()
        }
      })
    }
  }, [benefitData.coverage])


  return (
    <div className={`fsa-container`}>
      <div className='data-container'>
        <div className='page-header'>
          <b>{componentName}</b>
          <p>Choose the plan that best fits your goals and estimated budget. Be sure to reach out to your HR contact with questions.</p>
        </div>

        <ColorDivider />

        <div className='page-body'>
          <b>Providers</b>

          <ToggleButtonGroup
            color='secondary'
            value={benefitData.provider}
            exclusive
            onChange={handleProviderChange}
            aria-label='Platform'
          >
            {providerOptions.map((option) => (
              <ToggleButton
                key={option}
                value={replaceWhitespaceWithUnderscore(option)}
                className='provider-option'
              >
                {option}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>

          <div className='benefit-data'>
            <div className='montly-data'>
              <b>${benefitData.amount.toFixed(2)}</b>
              {benefitKey === 'long-term_care_insurance' ? (
                <p>PRE-TAX MONTHLY PREMIUM</p>
              ) : (
                <h3>PRE-TAX CONTRIBUTION PER PAY PERIOD</h3>
              )}
            </div>

            <Divider variant='middle' />
            <div>
              {benefitKey === 'long-term_care_insurance' ? (
                <>
                  <h2>Employer Contribution</h2>
                  <p>YOUR EMPLOYER DOES NOT CONTRIBUTE TO THE COST OF THIS BENEFIT </p>


                  <h2>Coverage</h2>
                  <p dangerouslySetInnerHTML={{ __html: replaceUrlWithLink(formValues.benefitExplanation.longTermCareInsuranceBenefitExplanation) }} />
                </>

              ) : (
                <>
                  <h2>Employer Match</h2>
                  <ContributionHeading
                    benefitData={{
                      ...benefitData,
                      isSelected: typeof benefitData.isSelected === 'boolean'
                        ? benefitData.isSelected
                        : Boolean(benefitData.isSelected)
                    }}
                    benefitType='limit'
                    formValuesContext={formValuesContext}
                    isPension={componentName.toLowerCase() === 'pension'}
                  />
                </>
              )}
            </div>
            <Divider variant='middle' />
            {componentName.toLowerCase() !== 'pension' && (
              <div className='benefit-input-container'>
                {benefitKey === 'long-term_care_insurance' ? (
                  <>
                    <h2>Enter Monthly Premium of selected Plan</h2>
                  </>
                ) :
                  <div className='info-text'>
                    <h2>Select your Annual Contribution Amount</h2>

                    <p>MAXIMUM CONTRIBUTION IS ${
                      parseFloat(String(benefitKey === 'dependentCare' ? dependentCareLimit : annualContributionLimit)).toFixed(2)
                    }</p>
                  </div>
                }
                <div style={{ display: isDependentCare || isMedical ? 'flex' : 'block', alignItems: 'center', gap: '10px', width: '100%' }}>
                  <div className={`options ${errorMessage !== '' ? 'error' : ''}`} style={{
                    flexGrow: (seedDeposit !== 0 && (benefitKey === 'dependentCare' || benefitKey === 'medical')) ? 1 : 0,
                  }}>
                    <NumberInput
                      value={benefitData?.coverage}
                      setValue={(newValue: string) => {
                        setBenefitData((prevData) => ({ ...prevData, coverage: newValue }))
                      }}
                      setError={setErrorMessage}
                      setIsError={setError}
                      annualContributionStartrangeValue={0}
                      annualContributionEndrangeValue={parseFloat(String(benefitKey === 'dependentCare' ? dependentCareLimit : annualContributionLimit))}
                      label='$'
                      isPension={componentName.toLowerCase() === 'pension'}
                      setSelected={(newValue: boolean) => {
                        setBenefitData((prevData) => ({ ...prevData, isSelected: newValue }))
                      }}
                    />
                  </div>
                  {(seedDeposit !== 0 && benefitKey === 'dependentCare') && (
                    <div style={{ flexGrow: 1 }}>
                      <div>
                        Total Annual Dependent Care FSA with Employer Contribution: $
                        {benefitData.dependentCareWithContribution}
                      </div>
                    </div>
                  )}

                  {(seedDeposit !== 0 && benefitKey === 'medical') && (
                    <div style={{ flexGrow: 1 }}>
                      <div>
                        Total Annual Healthcare FSA with Employer Contribution: $
                        {benefitData.dependentCareWithContribution}
                      </div>
                    </div>
                  )}

                </div>


                {errorMessage && <div className='error-text'>{errorMessage}</div>}
              </div>
            )}
            {componentName.toLowerCase() === 'pension' && (
              <>
                <div style={{ display: 'flex', alignItems: 'center', padding: '8px', marginBottom: '18px' }}>
                  <div className={`options ${errorMessage !== '' ? 'error' : ''}`}>
                    <div style={{ border: '0.05rem solid #AAAEB5', width: '100px' }}>
                      <NumberInput
                        value={benefitData?.coverage}
                        setValue={(newValue: string) => {

                          setBenefitData((prevData) => {
                            if (parseFloat(newValue) !== sanitizedMaxPension) {
                              setIsUserInteracting(true)
                              setError(true)
                            } else {
                              setErrorMessage('')
                              setIsUserInteracting(false)
                              setError(false)
                            }

                            return { ...prevData, coverage: newValue }
                          })
                        }}
                        setError={setErrorMessage}
                        setIsError={setError}
                        annualContributionStartrangeValue={0}
                        annualContributionEndrangeValue={formValues.EmployerContributionLimit.pension}
                        label=''
                        setSelected={(newValue: boolean) => {
                          setBenefitData((prevData) => ({ ...prevData, isSelected: newValue }))
                        }}
                        isPension={componentName.toLowerCase() === 'pension'}
                      />
                    </div>
                  </div>
                  <span>%</span>
                  <p style={{ marginLeft: '16px', marginBottom: 0 }}>
                    Annual Retirement Investment with Employer Match:
                    <strong>
                      &nbsp;$ {isNaN(totalRetirementInvestment) ? '0' : addThousandsSeparator(totalRetirementInvestment.toFixed(2))}
                    </strong>
                  </p>
                </div>
                {errorMessage && <div className='error-text'>{errorMessage}</div>}
              </>
            )}

            {componentName.toLowerCase() === 'pension' && (
              <div
              >
                <h2>Select your Annual Contribution Amount</h2>
                <p style={{
                  color:
                    isUserInteracting && parseFloat(benefitData.coverage) !== sanitizedMaxPension
                      ? 'red'
                      : 'black',
                }}>
                  {formValues.AnnualContributionMinimum.pension === formValues.AnnualContributionMaximum.pension
                    ? `Your contribution must be ${formValues.AnnualContributionMaximum.pension} of your salary in order to participate in the pension.`
                    : `Your contribution must be ${formValues.AnnualContributionMaximum.pension} of your salary.`}
                </p>
              </div>
            )}

            <div className='selected-button'>
              <SelectDeselectButtonGroup
                formValuesContext={formValuesContext}
                setValues={setValues}
                benefitData={benefitData}
                setIsSelected={(newValue) => {
                  if (!error) {
                    setBenefitData((prevData) => ({ ...prevData, isSelected: newValue }))
                  }
                }}
                error={error}
              />
            </div>
          </div>
        </div>
      </div>
    </div >
  )
})

export default BenefitsContributionsDetails
