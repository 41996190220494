import React from 'react'
import { Container, Typography, Link, List, ListItem, Divider } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, Arial, sans-serif',
  },
})

const PrivacyPolicy = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md" sx={{ padding: '2rem' }}>
        <Typography variant="h3" align="center" gutterBottom sx={{ fontSize: '26px', fontWeight: 'bold' }}>
          Privacy Policy of <span style={{ fontWeight: 'bold' }}>Parsley</span>
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10px', lineHeight: 1.6 }}>
          In order to receive specific information about your Personal Data collected and how it is used, contact the Owner.
        </Typography>

        <Typography sx={{ fontSize: '13px', fontWeight: 'bold', marginTop: '2rem' }}>
          Owner and Data Controller
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10px' }}>
          Parsley Solutions, PBC, 218 Jackson St., Madison, WI 53704
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10px', fontWeight: 'bold' }}>
          Owner contact email:{' '}
          <Link href="mailto:info@yourparsley.com" underline="hover">
            info@yourparsley.com
          </Link>
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ marginTop: '2rem', fontSize: '13px', fontWeight: 'bold' }}>
          Types of Data Collected
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          Personally Identifiable Information (PII), Financial Information (FI) and household Data are collected and necessary to provide the services of the application.  The data is saved only when a user establishes an account and all PII is deleted when the user deletes their account.  No PII is shared with third parties.  Data may be anonymized and aggregated for ongoing analysis.  Anonymized and aggregated data,  which cannot be connected to any specific individual, may be retained for use by the owner.
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10px', lineHeight: 1.6 }}>
          Personal Data may be freely provided by the User, or, in case of Usage Data, collected automatically when using this Application.
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10px', lineHeight: 1.6 }}>
          Unless specified otherwise, all Data requested by this Application is mandatory and failure to provide this Data may make it impossible for this Application to provide its services. In cases where this Application specifically states that some Data is not mandatory, Users are free not to communicate this Data without consequences to the availability or the functioning of the Service.
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10px', lineHeight: 1.6 }}>
          Users who are uncertain about which Personal Data is mandatory are welcome to contact the Owner.
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10px', lineHeight: 1.6 }}>
          Any use of Cookies – or of other tracking tools — by this Application or by the owners of third-party services used by this Application serves the purpose of providing the Service required by the User, in addition to any other purposes described in the present document and in the Cookie Policy.
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10px', lineHeight: 1.6 }}>
          Users are responsible for the accuracy of the Personal Data provided and its impact on the accuracy and impact on application results.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ marginTop: '2rem', fontSize: '13px', fontWeight: 'bold' }}>
          Mode and place of processing the Data
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', fontSize: '10px' }}>
          Methods of Processing
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10px', lineHeight: 1.6 }}>
          The Owner takes appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data.
        </Typography>
        <Typography variant="body1" paragraph sx={{ fontSize: '10px', lineHeight: 1.6 }}>
          The Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to the Owner, in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of this Application (administration, sales, marketing, legal, system administration) or external parties (such as third-party technical service providers, mail carriers, hosting providers, IT companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be requested from the Owner at any time.
        </Typography>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', fontSize: '10px' }}>
          Place
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10px', lineHeight: 1.6 }}>
          The Data is processed at the Owner's operating offices and in any other places where the parties involved in the processing are located.
        </Typography>
        <Typography variant="body1" paragraph sx={{ fontSize: '10px', lineHeight: 1.6 }}>
          Depending on the User's location, data transfers may involve transferring the User's Data to a country other than their own. To find out more about the place of processing of such transferred Data, Users can check the section containing details about the processing of Personal Data.
        </Typography>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', fontSize: '10px' }}>
          Retention Time
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10px', lineHeight: 1.6 }}>
          Unless specified otherwise in this document, Personal Data shall be processed and stored for as long as required by the purpose they have been collected for and may be retained for longer due to applicable legal obligation or based on the Users’ consent.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ marginTop: '2rem', fontSize: '13px', fontWeight: 'bold' }}>
          Cookie Policy
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10px', lineHeight: 1.6 }}>
          This Application uses Trackers. To learn more, Users may consult the Cookie Policy.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ marginTop: '2rem', fontSize: '13px', fontWeight: 'bold' }}>
          Further Information for Users
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ fontSize: '13px', fontWeight: 'bold', marginTop: '2rem' }}>
          Legal Basis of Processing
        </Typography>
        <Typography variant="body1" paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          The Owner may process Personal Data relating to Users if one of the following applies:
        </Typography>
        <List sx={{ listStyleType: 'disc', paddingLeft: '2rem', fontSize: '10.5px', lineHeight: 1.6 }}>
          <ListItem sx={{ display: 'list-item' }}>
            Users have given their consent for one or more specific purposes.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            Provision of Data is necessary for the performance of an agreement with the User
            and/or for any pre-contractual obligations thereof.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            Processing is necessary for compliance with a legal obligation to which the Owner is subject.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            Processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Owner.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            Processing is necessary for the purposes of the legitimate interests pursued by the Owner.
          </ListItem>
        </List>


        <Typography variant="body1" paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          In any case, the Owner will gladly help to clarify the specific legal basis that applies to the
          processing, and in particular whether the provision of Personal Data is a statutory or
          contractual requirement, or a requirement necessary to enter into a contract.
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ fontSize: '13px', fontWeight: 'bold', marginTop: '2rem' }}>
          Further Information About Retention Time
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          Unless specified otherwise in this document, Personal Data shall be processed and stored for as
          long as required by the purpose they have been collected for and may be retained for
          longer due to applicable legal obligationor based on the Users’ consent.
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          Therefore:
        </Typography>

        <List sx={{ listStyleType: 'disc', paddingLeft: '2rem', fontSize: '10.5px', lineHeight: 1.6 }}>
          <ListItem sx={{ display: 'list-item' }}>
            Personal Data collected for purposes related to the performance of a contract between the Owner and the User shall be retained until such contract has been fully performed.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            Personal Data collected for the purposes of the Owner’s legitimate interests shall be retained as long as needed to fulfill such purposes. Users may find specific information regarding the legitimate interests pursued by the Owner within the relevant sections of this document or by contacting the Owner.
          </ListItem>
        </List>


        <Typography variant="body1" paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          The Owner may be allowed to retain Personal Data for a longer period whenever the User has given
          consent to such processing, as long as such consent is not withdrawn. Furthermore, the Owner may
          be obliged to retain Personal Data for a longer period whenever required to fulfill a legal obligation or upon order of an authority.
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          Once the retention period expires, Personal Data shall be deleted. Therefore, the right of access,the right to erasure,  the right to rectification, and the right to data portability cannot be enforced after expiration of the retention period.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ fontSize: '13px', fontWeight: 'bold', marginTop: '2rem' }}>
          The Rights of Users Based on GDPR
        </Typography>

        <List sx={{ listStyleType: 'disc', paddingLeft: '2rem', fontSize: '10.5px', lineHeight: 1.6 }}>
  <ListItem sx={{ display: 'list-item' }}>
    <Typography component="span" sx={{ fontWeight: 'bold', fontSize: '10px' }}>
      Withdraw their consent at any time.
    </Typography>
    {' '}Users have the right to withdraw consent where they have previously given their consent to the processing of their Personal Data.
  </ListItem>
  <ListItem sx={{ display: 'list-item' }}>
    <Typography component="span" sx={{ fontWeight: 'bold',fontSize: '10px'}}>
      Object to processing of their Data.
    </Typography>
    {' '}Users have the right to object to the processing of their Data if the processing is carried out on a legal basis other than consent.
  </ListItem>
  <ListItem sx={{ display: 'list-item' }}>
    <Typography component="span" sx={{ fontWeight: 'bold',fontSize: '10px'}}>
      Access their Data.
    </Typography>
    {' '}Users have the right to learn if Data is being processed by the Owner, obtain disclosure regarding certain aspects of the processing and obtain a copy of the Data undergoing processing.
  </ListItem>
  <ListItem sx={{ display: 'list-item' }}>
    <Typography component="span" sx={{ fontWeight: 'bold',fontSize: '10px' }}>
      Verify and seek rectification.
    </Typography>
    {' '}Users have the right to verify the accuracy of their Data and ask for it to be updated or corrected.
  </ListItem>
  <ListItem sx={{ display: 'list-item' }}>
    <Typography component="span" sx={{ fontWeight: 'bold',fontSize: '10px' }}>
      Restrict the processing of their Data.
    </Typography>
    {' '}Users have the right to restrict the processing of their Data. In this case, the Owner will not process their Data for any purpose other than storing it.
  </ListItem>
  <ListItem sx={{ display: 'list-item' }}>
    <Typography component="span" sx={{ fontWeight: 'bold',fontSize: '10px' }}>
      Have their Personal Data deleted or otherwise removed.
    </Typography>
    {' '}Users have the right to obtain the erasure of their Data from the Owner.
  </ListItem>
  <ListItem sx={{ display: 'list-item' }}>
    <Typography component="span" sx={{ fontWeight: 'bold',fontSize: '10px' }}>
      Receive their Data and have it transferred to another controller.
    </Typography>
    {' '}Users have the right to receive their Data in a structured, commonly used, and machine-readable format and, if technically feasible, to have it transmitted to another controller without any hindrance.
  </ListItem>
  <ListItem sx={{ display: 'list-item' }}>
    <Typography component="span" sx={{ fontWeight: 'bold',fontSize: '10px' }}>
      Lodge a complaint.
    </Typography>
    {' '}Users have the right to bring a claim before their competent data protection authority.
  </ListItem>
</List>


        <Typography variant="body1" paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          Users are also entitled to learn about the legal basis for Data transfers abroad, including to any international organization governed by public international law or set up by two or more countries, such as the UN, and about the security measures taken by the Owner to safeguard their Data.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ fontSize: '13px', fontWeight: 'bold', marginTop: '2rem' }}>
          Details About the Right to Object to Processing
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6, fontWeight: 'bold' }}>
          Where Personal Data is processed for a public interest, in the exercise of an official authority vested in the Owner, or for the purposes of the legitimate interests pursued by the Owner, Users may object to such processing by providing a ground related to their particular situation to justify the objection.
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6, ontWeight: 'bold' }}>
          The Owner does not use Personal Data for Direct Marketing purposes.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ fontSize: '13px', fontWeight: 'bold', marginTop: '2rem' }}>
          How to Exercise These Rights
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          Any requests to exercise User rights can be directed to the Owner through the contact details provided in this document. Such requests are free of charge and will be answered by the Owner as early as possible and always within one month, providing Users with the information required by law. Any rectification or erasure of Personal Data or restriction of processing will be communicated by the Owner to each recipient, if any, to whom the Personal Data has been disclosed unless this proves impossible or involves disproportionate effort. At the Users’ request, the Owner will inform them about those recipients.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ fontSize: '13px', fontWeight: 'bold', marginTop: '2rem' }}>
          Additional Information About Data Collection and Processing
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', fontSize: '12px' }}>
          Legal Action
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          The User's Personal Data may be used for legal purposes by the Owner in Court or in the stages leading to possible legal action arising from improper use of this Application or the related Services.
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          The User declares to be aware that the Owner may be required to reveal Personal Data upon request of public authorities.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', fontSize: '12px' }}>
          Additional Information About User's Personal Data
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          In addition to the information contained in this privacy policy, this Application may provide the User with additional and contextual information concerning particular Services or the collection and processing of Personal Data upon request.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', fontSize: '12px' }}>
          System Logs and Maintenance
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          For operation and maintenance purposes, this Application and any third-party services may collect files that record interaction with this Application (System logs) or use other Personal Data (such as the IP Address).
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', fontSize: '12px' }}>
          Information Not Contained in This Policy
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          More details concerning the collection or processing of Personal Data may be requested from the Owner at any time. Please see the contact information at the beginning of this document.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ fontSize: '13px', fontWeight: 'bold', marginTop: '2rem' }}>
          Changes to This Privacy Policy
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          The Owner reserves the right to make changes to this privacy policy at any time by notifying its Users on this page and possibly within this Application and/or — as far as technically and legally feasible — sending a notice to Users via any contact information available to the Owner. It is strongly recommended to check this page often, referring to the date of the last modification listed at the bottom.
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          Should the changes affect processing activities performed on the basis of the User’s consent, the Owner shall collect new consent from the User, where required.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ fontSize: '13px', fontWeight: 'bold', marginTop: '2rem' }}>
          Definitions and Legal References
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', fontSize: '12px' }}>
          Personal Data (or Data)
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          Any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', fontSize: '12px' }}>
          Usage Data
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          Information collected automatically through this Application (or third-party services employed in this Application), which can include: the IP addresses or domain names of the computers utilized by the Users who use this Application, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server's answer (successful outcome, error, etc.), the country of origin, the features of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent on each page within the Application) and the details about the path followed within the Application with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User's IT environment.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', fontSize: '12px' }}>
          User
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          The individual using this Application who, unless otherwise specified, coincides with the Data Subject.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', fontSize: '12px' }}>
          Data Subject
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          The natural person to whom the Personal Data refers.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', fontSize: '12px' }}>
          Data Processor (or Processor)
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          The natural or legal person, public authority, agency, or other body which processes Personal Data on behalf of the Controller, as described in this privacy policy.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', fontSize: '12px' }}>
          Data Controller (or Owner)
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
          The natural or legal person, public authority, agency, or other body which, alone or jointly with others, determines the purposes and means of the processing of Personal Data, including the security measures concerning the operation and use of this Application. The Data Controller, unless otherwise specified, is the Owner of this Application.
        </Typography>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', fontSize: '12px' }}>
        This Application
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
        The means by which the Personal Data of the User is collected and processed.
        </Typography>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', fontSize: '12px' }}>
        Service
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
        The service provided by this Application as described in the relative terms (if available) and on this site/application.
        </Typography>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', fontSize: '12px' }}>
        European Union (or EU)
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
        Unless otherwise specified, all references made within this document to the European Union include all current member states to the European Union and the European Economic Area.
        </Typography>
<Divider/>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', fontSize: '12px' }}>
        Legal information
        </Typography>

        <Typography variant="body1" paragraph sx={{ fontSize: '10.5px', lineHeight: 1.6 }}>
        This privacy policy relates solely to this Application, if not stated otherwise within this document.
        </Typography>
        <Typography variant="body2" align="center" sx={{ marginTop: '2rem', color: 'gray', fontSize: '10.5px' }}>
          Latest update: 2023
        </Typography>

      </Container>
    </ThemeProvider>
  )
}

export default PrivacyPolicy
