import React, { memo } from 'react'

import { type FSABenefitProps } from '../../../EmployeeCalculatorInterface'
import BenefitsContributionsDetails from '../BenefitsContributionsDetails'

const LongTermCareInsurance: React.FC<FSABenefitProps> = memo((props) => {
  return (
    <BenefitsContributionsDetails
      {...props}
      componentName='Long Term Care Insurance'
      benefitKey='long-term_care_insurance'
    />
  )
})

export default LongTermCareInsurance
