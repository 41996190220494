import React, { useContext, useState, useEffect, forwardRef, useImperativeHandle, type ChangeEvent } from 'react'
import Select from 'react-select'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'

import { type FormValues } from '../../../FormValuesInterfaces'
import BeforeEstimatedTax from '../BeforeAfterEstimatedTax'
import ColorDivider from '../ColorDivider'
import NumberInput from '../NumberInput'
import PurpleButton from '../PurpleButton'
import { convertAndRound, scrollToTop } from '../../../Helper'
import { calculatePay } from '../../../api/employeeTaxes'
import {
  AVERAGE_WORK_HOURS_PER_WEEK,
  DURATION_MULTIPLIER,
  TIPS_DURATION_OPTIONS,
  WEEKS_IN_YEAR
} from '../../../Constants'

import './styles.sass'

interface EarningsProps {
  formValuesContext: React.Context<FormValues>
  setValues: React.Dispatch<FormValues>
  handleNext: () => void
  handleBack: () => void
  isBeforeTaxes: boolean
  setIsBeforeTaxes: (value: boolean) => void
  setIsChanged: (value: boolean) => void
}

const Earnings = forwardRef((props: EarningsProps, ref) => {
  const {
    formValuesContext,
    setValues,
    handleNext,
    handleBack,
    isBeforeTaxes,
    setIsBeforeTaxes,
    setIsChanged
  } = props
  const formValues: FormValues = useContext(formValuesContext)
  const [hours, setHours] = useState(formValues?.currentWorkweek[0][0])
  const [tip, setTip] = useState(formValues?.adults[0]?.currentJobs?.[0]?.tipsAmount)
  const [tipType, setTipType] = useState(formValues?.adults[0]?.currentJobs?.[0]?.tipsPayscale)
  const [otAvailable, setOtAvailable] = useState(false)
  const [earningType, setEarningType] = useState(formValues?.adults[0]?.employee?.wage_period)
  const hoursRange = useState(formValues?.adults[0]?.employee?.hours_range)
  const otRange = useState(formValues?.adults[0]?.employee?.ot_hours_range)
  const employeeChange = useState(formValues?.adults[0]?.employee?.employee_change)
  const [beforeTaxValue, setBeforeTaxValue] = useState(formValues?.adults[0]?.beforeTaxAmount)
  const [currentAnnualWage, setCurrentAnnualWage] = useState(formValues?.adults[0]?.currentJobs?.[0]?.currentAnnualWage ?? 0)
  const [hoursError, setHoursError] = useState('')
  const [otError, setOTError] = useState('')
  const [error, setError] = useState(false)
  const [pay, setPay] = useState(0)
  const wagesOTType = 'week'
  const [wagesOTWorkweek, setWagesOTWorkweek] = useState(formValues?.overtimeWorkweek[0] || {})
  const [showNote, setShowNote] = useState(false)
  const calculatePayBasedOnMultiplier = (annualPay: number, multiplier: number | undefined): number => {
    return multiplier !== undefined ? annualPay / multiplier : 0
  }
  useEffect(() => {
    if (formValues?.overtimeWorkweek && formValues.overtimeWorkweek[0] !== undefined) {
      setWagesOTWorkweek(formValues.overtimeWorkweek[0])
    }
  }, [formValues?.overtimeWorkweek])
  useEffect(() => {
    if (formValues?.isInitialRender) {
      setWagesOTWorkweek(prevState => ({
        ...prevState,
        [0]: parseInt('0')
      }))

      const initialHours = formValues?.adults[0]?.employee?.scheduled_hours.toString() ?? '0'
      setHours(Number(initialHours))
    } else {
      setHours(formValues?.currentWorkweek[0][0])
    }
  }, [formValues])

  useEffect(() => {
    const hourlyPay = formValues?.hourlyPay
    const newCurrentAnnualWage = hourlyPay * WEEKS_IN_YEAR * Number(hours)
    setCurrentAnnualWage(newCurrentAnnualWage)
    setPay(calculatePayBasedOnMultiplier(newCurrentAnnualWage, DURATION_MULTIPLIER[formValues?.adults[0]?.employee?.pay_frequency as string]))
  }, [hours, formValues?.hourlyPay, formValues?.adults[0]?.employee?.pay_frequency])

  const getData = async (formValues: FormValues): Promise<void> => {
    const response = await calculatePay(formValues)

    setBeforeTaxValue(convertAndRound(response?.before_estimated_tax as number))

    const formValuesUpdated = {
      ...formValues,
      isInitialRender: false,
      adults: {
        ...formValues.adults,
        0: {
          ...formValues.adults[0],
          beforeTaxAmount: convertAndRound(response?.before_estimated_tax as number),
          currentJobs: [
            {
              ...formValues?.adults?.[0].currentJobs?.[0],
              currentWage: pay
            }
          ]
        }
      }
    }

    setValues(formValuesUpdated)
    setIsBeforeTaxes(!isBeforeTaxes)
  }

  useEffect(() => {
    setOtAvailable(Number(hours) >= AVERAGE_WORK_HOURS_PER_WEEK)
    setError(hoursError !== '' || otError !== '')
  }, [hours, wagesOTWorkweek, tip, tipType])

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setEarningType(event.target.value)
  }

  useImperativeHandle(ref, () => ({
    handleButtonClick() {
      const formValuesUpdated = {
        ...formValues,
        currentAnnualWage: {
          0: {
            0: hours
          }
        },
        currentWorkweek: {
          0: {
            0: Number(hours)
          }
        },
        overtimeAvailable: {
          0: {
            0: formValues?.adults?.[0]?.employee?.ot_available ? 'yes' : 'no'
          }
        },
        overtimePayscale: {
          0: {
            0: wagesOTType
          }
        },
        overtimeWorkweek: {
          0: {
            0: wagesOTWorkweek[0] as number
          }
        },
        tipsAvailable: {
          0: {
            0: formValues?.adults?.[0]?.employee?.tips_available ? 'yes' : 'no'
          }
        },
        tipsAmount: {
          0: {
            0: tip
          }
        },
        tipsPayscale: {
          0: {
            0: tipType
          }
        },
        isInitialRender: false
      }

      setValues(formValuesUpdated as FormValues)
    }
  }))

  const handleButtonClick = (): void => {
    const formValuesUpdated = {
      ...formValues,
      currentWorkweek: {
        0: {
          0: Number(hours)
        }
      },
      currentPayscale: {
        0: {
          0: 'hour' as string
        }
      },

      tipsAvailable: {
        0: {
          0: formValues?.adults?.[0]?.employee?.tips_available ? 'yes' : 'no'
        }
      },
      tipsAmount: {
        0: {
          0: tip as number
        }
      },
      tipsPayscale: {
        0: {
          0: tipType as string
        }
      },
      overtimeWorkweek: {
        0: {
          0: wagesOTWorkweek[0] as number
        }
      },
      adults: {
        ...formValues?.adults,
        0: {
          ...formValues?.adults?.[0],
          jobs: 1,
          currentJobs: [
            {
              ...formValues?.adults?.[0]?.currentJobs?.[0],
              currentWorkweek: hours as number,
              currentAnnualWage,
              tipsAmount: tip as number,
              tipsPayscale: tipType as string,
              currentWage: pay,
              overtimeWorkweek: wagesOTWorkweek[0] as number
            }
          ]
        }
      }
    }

    setValues(formValuesUpdated as FormValues)
    void getData(formValuesUpdated)
  }

  const getSelectdValue = (
    value: string,
    options: Array<{ label: string, value: string }>
  ): { label: string, value: string } | undefined => {
    return options.find((option) => option.value === value)
  }
  const renderHoursAndOTInput = (
    heading: string,
    valueAvailable: boolean,
    value: string,
    canEmployeeChange: boolean,
    setValue: (value: string) => void,
    isovertimeField: boolean
  ): React.ReactNode => {
    const numericValue = parseFloat(value);
    const displayValue = isNaN(numericValue) ? "0" : numericValue.toString();
  
    const handleContainerClick = () => {
      if (isovertimeField && !canEmployeeChange) {
        setShowNote(true);
      }
    }
  
    if (valueAvailable) {
      return (
        <>
          <b>{heading}?</b>
          <div
            className="hours-input"
            onClick={handleContainerClick}
            style={{ position: "relative" }}
          >
            <div style={{ position: "relative" }}>
              {!canEmployeeChange && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 1,
                    cursor: "pointer",
                  }}
                ></div>
              )}
              <NumberInput
                value={displayValue}
                label="Enter Hours"
                setValue={(val) => {
                  const newValue = parseFloat(val)
                  if (!isNaN(newValue)) {
                    setValue(val)
                  } else {
                    setValue("0")
                  }
                }}
                disabled={!canEmployeeChange}
              />
            </div>
            <Select
              classNamePrefix="select"
              placeholder="Week"
              isSearchable={false}
              menuPlacement="top"
            />
          </div>
        </>
      )
    }
    return null
  }

  const validateHours = (value: string, starting: number, ending: number): string => {
    const numericValue = parseFloat(value)

    if (numericValue < starting || numericValue > ending) {
      setError(true)
      return `Value must be between ${starting} and ${ending}.`
    }

    return ''
  }

  const handleHoursChange = (value: string): void => {
    const error = validateHours(value, hoursRange[0]?.[0] as number, hoursRange[0]?.[1] as number)
    setHours(Number(value))
    setHoursError(error)
    setIsChanged(true)
  
  }

  const handleOTChange = (value: string): void => {

    const error = validateHours(value, 0, otRange[0]?.[1] as number - (otRange[0]?.[0] as number) + 1)
    setWagesOTWorkweek(prevState => ({
      ...prevState,
      [0]: parseInt(value)
    }))
    setOTError(error)
    setIsChanged(true)
  }

  scrollToTop()
  return (
    <div className='earning-page-container'>
      <div className='page-header'>
        <b>Let&apos;s get started</b>
        <p>
          First, let&apos;s calculate your take-home pay so you have an idea of what to expect each pay-period.
        </p>
      </div>

      <ColorDivider />

      <div className='page-body'>
        <b>Earnings type</b>

        <RadioGroup className='custom-radio-group' value={earningType} onChange={handleChange}>
          <div className='radio-button'>
            <FormControlLabel
              value='hour'
              control={<Radio />}
              label='Hourly'
              disabled={earningType === 'year'}
            />
          </div>

          <div className='radio-button'>
            <FormControlLabel
              value='year'
              control={<Radio />}
              label='Salary'
              disabled={earningType === 'hour'}
            />
          </div>
        </RadioGroup>

        <div className='earning-info-container'>
          <div className='earning-info-data'>
            {renderHoursAndOTInput(
              'How many hours per week',
              true,
              String(hours),
              employeeChange[0] as boolean,
              handleHoursChange,
              false
            )}

            {hoursError !== '' && <div className='error-text'>{hoursError}</div>}

            {formValues?.adults[0]?.currentJobs?.[0]?.tipsAvailable === 'yes' && (
              <>
                <b>Wages earned as tips:</b>
                <div className='hours-input'>
                  <NumberInput
                    value={String(tip)}
                    setValue={(value: string) => {
                      setTip(Number(value))
                      setIsChanged(true)
                    }}
                    label='$'
                  />
                  <Select
                    classNamePrefix='select'
                    placeholder='Duration'
                    onChange={(e) => {
                      setTipType(e?.value ?? '')
                      setIsChanged(true)
                    }}
                    isSearchable={false}
                    options={TIPS_DURATION_OPTIONS}
                    value={
                      tipType
                        ? getSelectdValue(tipType, TIPS_DURATION_OPTIONS)
                        : TIPS_DURATION_OPTIONS[0]
                    }
                  />
                </div>
              </>
            )}

            {renderHoursAndOTInput(
              'How many overtime hours per week',
              formValues?.adults[0]?.currentJobs?.[0]?.overtimeAvailable === 'yes',
              wagesOTWorkweek[0].toString(),
              otAvailable,
              handleOTChange,
              true
            )}
            {showNote && (
              <div style={{ color: 'red', marginTop: '10px' }}>
                You must work at least 40 hours before qualifying for over-time pay.
              </div>
            )}

            {otError !== '' && <div className='error-text'>{otError}</div>}
          </div>
        </div>

        <div className='confirm-button'>
          <PurpleButton
            label='Continue'
            width='100%'
            buttonClick={handleButtonClick}
            disabled={error}
          />
          <BeforeEstimatedTax
            handleNext={handleNext}
            handleBack={handleBack}
            isOpen={isBeforeTaxes}
            setIsOpen={setIsBeforeTaxes}
            position='Before'
            value={beforeTaxValue as number}
          />
        </div>
      </div>
    </div>
  )
})

Earnings.displayName = 'Earnings'

export default Earnings
