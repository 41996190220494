import React, { useState, useContext, useEffect } from 'react'
import { Checkbox, FormControlLabel } from '@material-ui/core'

import { WIC, TANF, ReducedFareBusPass, CCDF, FSP } from './BenefitsReceived'
import { getBenefitsInitialValues, haveChildUnderFive, scrollToTop } from '../../Helper'
import { WI_BENEFITS, DISABLED_BENEFITS } from '../../Constants'
import { type StepProps } from '../../interfaces'
import { type FormValues } from '../../FormValuesInterfaces'

import '../../component_styles/BenefitsCliff.sass'
import NavigationButtons from './NavigationButtons'
import { type Adults } from '../AdultsInterface'

const StepFour: React.FC<StepProps> = ({ formValuesContext, setValues, handleNext, handleBack, step }) => {
  const formValues: FormValues = useContext(formValuesContext)

  const [benefits, setBenefits] = useState(formValues)
  const [contextValues, setContextValues] = useState(formValues)
  const [isChanged, setIsChanged] = useState(false)
  const [selectAllChecked, setSelectAllChecked] = useState(formValues.selectAllBenefits ?? false)

  const disabledBenefits = (input: string): boolean => {
    if (input === 'headstart') {
      return !haveChildUnderFive(formValues?.children)
    }

    const isPregnant = formValues?.pregnant
    const benefits = isPregnant
      ? DISABLED_BENEFITS.filter(benefit => benefit !== 'wic')
      : [...DISABLED_BENEFITS, 'wic']

    if (input === 'wic') {
      return benefits.includes(input) &&
        (formValues?.childrenCount === 0 || !haveChildUnderFive(formValues?.children))
    } else {
      return benefits.includes(input) && formValues?.childrenCount === 0
    }
  }

  const resetFormValues = (input: string): void => {
    const { children, adults, ...rest } = formValues
    switch (input) {
      case 'tanf':
        setContextValues({ ...rest, children, adults, tanfMonthsReceived: 0 })
        break
      case 'wic':
        Object.values(children).forEach((child) => delete child?.ageInMonths)
        setContextValues({ ...rest, children, adults, breastfeeding: false })
        break
      case 'com_reduced_fare_passes':
        Object.values(adults).forEach((adult: Adults) => { adult.checkBusPassEligibility = false })
        setContextValues({ ...rest, children, adults })
        break
      case 'fsp':
        setContextValues({ ...rest, children, adults, heatAndEatAllowance: false })
        break
    }
  }

  const renderBenefitInput = ({ value, label }: { value: string, label: string }): JSX.Element => {
    const isBenefitChecked = benefits[`check_${value}` as keyof FormValues] as boolean
    const isBenefitEnrolled = benefits[`enrolled_${value}` as keyof FormValues] as boolean

    const handleCheckChange = (event: React.ChangeEvent<{}>): void => {
      const checkbox = event.target as HTMLInputElement
      setBenefits({
        ...benefits,
        [`check_${value}`]: checkbox.checked,
        [`enrolled_${value}`]: checkbox.checked ? isBenefitEnrolled : false
      })
      resetFormValues(value)
      setIsChanged(true)
    }

    const handleEnrolledChange = (event: React.ChangeEvent<{}>): void => {
      const checkbox = event.target as HTMLInputElement
      setBenefits({ ...benefits, [`enrolled_${value}`]: checkbox.checked })
      setIsChanged(true)
    }

    return (
      <div key={value} className='benefit-checkbox'>
        <div className='eligibility-checkbox'>
          <FormControlLabel
            labelPlacement='end'
            disabled={disabledBenefits(value)}
            checked={isBenefitChecked ?? false}
            onChange={handleCheckChange}
            control={<Checkbox checked={isBenefitChecked} />}
            label={label}
          />
          {isBenefitChecked && renderBenefitsInput(value, isBenefitEnrolled)}
        </div>

        <Checkbox
          checked={isBenefitEnrolled ?? false}
          disabled={!isBenefitChecked}
          onChange={handleEnrolledChange}
        />
      </div>
    )
  }

  interface BenefitKeys {
    [key: `check_${string}`]: boolean
    [key: `enrolled_${string}`]: boolean
  }

  const handleSelectAllChange = (event: React.ChangeEvent<{}>): void => {
    const checkbox = event.target as HTMLInputElement
    const newSelectAllChecked = checkbox.checked

    setSelectAllChecked(newSelectAllChecked)

    const updatedBenefits: BenefitKeys = WI_BENEFITS.reduce<BenefitKeys>((acc, { value }) => {
      if (!disabledBenefits(value)) {
        acc[`check_${value}`] = newSelectAllChecked
        acc[`enrolled_${value}`] = newSelectAllChecked && benefits[`enrolled_${value}`] as boolean
      }
      return acc
    }, {})

    setBenefits({ ...benefits, ...updatedBenefits })
    setIsChanged(true)
  }

  const renderBenefitsInput = (benefit: string, isBenefitEnrolled: boolean): JSX.Element | undefined => {
    switch (benefit) {
      case 'wic':
        return <WIC
          key={benefit}
          contextValues={contextValues}
          setContextValues={setContextValues}
        />
      case 'tanf':
        return <TANF
          key={benefit}
          contextValues={contextValues}
          setContextValues={setContextValues}
          state={formValues?.state}
        />
      case 'com_reduced_fare_passes':
        return <ReducedFareBusPass
          key={benefit}
          contextValues={contextValues}
          setContextValues={setContextValues}
        />
      case 'ccdf':
        return <CCDF
          key={benefit}
          contextValues={contextValues}
          setContextValues={setContextValues}
          isBenefitEnrolled={isBenefitEnrolled}
        />
      case 'fsp':
        return <FSP
          key={benefit}
          contextValues={contextValues}
          setContextValues={setContextValues}
        />
    }
  }

  const saveUpdatedData = (): void => {
    setValues({
      ...getBenefitsInitialValues(),
      ...{ ...contextValues, ...benefits },
      isCopaymentPeriodStabilizationPeriod: contextValues?.isCopaymentPeriodStabilizationPeriod,
      isCopaymentPeriodGPO: contextValues?.isCopaymentPeriodGPO,
      isCopaymentPeriodExitPhase: contextValues?.isCopaymentPeriodExitPhase,
      tanfMonthsReceived: contextValues.tanfMonthsReceived ?? 0,
      selectAllBenefits: selectAllChecked,
      heatAndEatAllowance: contextValues.heatAndEatAllowance ?? false
    })
  }

  const handleSubmit = (): void => {
    handleNext()
    saveUpdatedData()
  }

  const resetBenfits = (): void => {
    setBenefits({ ...benefits, breastfeeding: contextValues.breastfeeding ?? false })
  }

  useEffect(() => {
    resetBenfits()
  }, [contextValues])

  scrollToTop()

  return (
    <>
      <div className='step-four'>
        <h3>Public Benefits Selection</h3>

        <div className='d-flex justify-space-between'>
          <b>Select the programs you wish to check eligibility for:</b>
          <b className='currently-enrolled-text'>Currently Enrolled:</b>
        </div>

        <FormControlLabel
          labelPlacement='end'
          checked={selectAllChecked}
          onChange={handleSelectAllChange}
          control={<Checkbox />}
          label='Select All Benefits'
        />

        <div className='benefits-checkboxex'>
          {WI_BENEFITS.map(renderBenefitInput)}
        </div>
        <div className='d-flex flex-column extra-benefits'>
          <p>
            In addition to the above programs, the calculator will determine the amount of any federal and state income tax credits that you are eligible to receive.
          </p>
        </div>
      </div>

      <NavigationButtons
        handleBack={handleBack}
        handleSubmit={handleSubmit}
        step={step}
        saveUpdatedData={saveUpdatedData}
        isChanged={isChanged}
      />
    </>
  )
}

export default StepFour
