import React, { Context, Dispatch, SetStateAction } from 'react'
import { Button } from '@material-ui/core'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'

import logo from '../../assets/images/Parsley_Logo.png'
import I18n from '../config/I18n'
import BackButtonModal from './BenefitsCliff/BackButtonModal'
import { FormValues } from '../FormValuesInterfaces'

interface LogoAndBackButtonProps {
  activeStep: number
  backButtonCondition: number
  onBackClick: () => void
  modalOpen?: boolean | undefined
  setModalOpen?: Dispatch<SetStateAction<boolean>> | undefined
  setStep?: Dispatch<SetStateAction<number>> | undefined
  formValuesContext?: Context<FormValues>
  setValues?: Dispatch<FormValues>
}

const LogoAndBackButton: React.FC<LogoAndBackButtonProps> = (props) => {
  const [isPrinting, setIsPrinting] = React.useState(false)

  React.useEffect(() => {
    const handleBeforePrint = () => setIsPrinting(true)
    const handleAfterPrint = () => setIsPrinting(false)

    window.addEventListener('beforeprint', handleBeforePrint)
    window.addEventListener('afterprint', handleAfterPrint)

    return () => {
      window.removeEventListener('beforeprint', handleBeforePrint)
      window.removeEventListener('afterprint', handleAfterPrint)
    }
  }, [])

  const headerLogoAndBackButton = () => {
    if (props.activeStep > props.backButtonCondition) {
      return (
        <div className='header-back-button'>
          <Button
            onClick={props.onBackClick}
            startIcon={<ArrowBackIosNewIcon />}
            className='back-button'
          >
            Back
          </Button>
        </div>
      )
    } else {
      return (
        <a href={`/${I18n.locale}/dashboard`}>
          <img
            src={logo}
            title={I18n.t('logo_description')}
            alt={I18n.t('logo_description')}
            className={`logo-image ${isPrinting ? 'hide-in-print' : ''}`}
          />
        </a>
      )
    }
  }

  return (
    <>
      <div className='logo-width'>
        {headerLogoAndBackButton()}
      </div>
      <BackButtonModal
        step = {props.activeStep}
        formValuesContext={props.formValuesContext}
        setValues= {props.setValues}
        modalOpen={props.modalOpen}
        setModalOpen={props.setModalOpen}
        handleSaveAndBack={() => {
          props.setModalOpen?.(false)
          props.setStep?.((prevVal) => prevVal - 1)
        }}
      />
    </>
  )
}

export default LogoAndBackButton
