import React, { type ChangeEvent, type FC, memo, useContext, useState, type FormEvent } from 'react'
import { OutlinedInput } from '@mui/material'
import { Link } from '@material-ui/core'
import { useParams } from 'react-router-dom'

import PurpleButton from '../PurpleButton'
import DividingColorLines from '../../DividingColorLines'
import PrivacyPolicy from '../PrivacyPolicy'
import ZipCodeInfo from '../ZipCodeInfoModal'
import { type StepProps } from '../../../EmployeeCalculatorInterface'
import { type FormValues } from '../../../FormValuesInterfaces'
import { verifyEmployee } from '../../../api/verifyEmployee'
import PinCodeInfoModal from '../PinCodeInfoModal'
import { MAX_INCORRECT_ATTEMPTS } from '../../../Constants'
import { scrollToTop } from '../../../Helper'

import './styles.sass'
import { b2bInitialState, jobDetailsInitialState } from '../../AdultsInterface'

const PinCodeModal: FC<StepProps> = memo((props) => {
  const { formValuesContext, setValues, handleNext } = props
  const formValues: FormValues = useContext(formValuesContext)
  const { id } = useParams()
  const [employer, setEmployer] = useState({ pin: '', zipCode: '' })
  const [isZipCodeOpen, setIsZipCodeOpen] = useState(false)
  const [isPinCodeOpen, setIsPinCodeOpen] = useState(false)
  const [incorrectAttempts, setIncorrectAttempts] = useState(1)
  const [error, setError] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [errorMessage, setErrorMessage] = useState<string>()

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const inputValue = event.target.value

    inputValue.length === 0 ? setDisabled(true) : setDisabled(false)
    setError(false)
    setErrorMessage('')
    setEmployer({ ...employer, [event.target.name]: inputValue })
  }

  const modalClick = (): void => { setIsZipCodeOpen(true) }

  const handleButtonClick = (): void => { }

  const submitForm = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault()

    const verificationCreds = {
      employer: {
        pin: employer.pin,
        zip_code: employer.zipCode
      }
    }

    verifyEmployee(id, verificationCreds, setErrorMessage)
      .then(({ data }) => {
        const formValuesUpdated = {
          ...formValues,
          adultsCount: 1,
          b2bCalculator: true,
          isInitialRender: true,
          jobs: 1,
          jobsDetails: {
            0: 1
          },
          overtimeAvailable: {
            0: {
              0: data?.ot_available
            }
          },
          overtimeWage: {
            0: {
              0: data?.ot_pay_rate
            }
          },
          
          ot_pay_rate:{
            0:{
              0:data?.ot_pay_rate
            }
          },
          overtimePayscale: {
            0: {
              0: data?.pay_frequency
            }
          },
          overtimeWorkweek: {
            0: {
              0: data?.ot_hours_range[1] - 40 as number
            }
          },
          currentWage: {
            0: {
              0: data?.wage
            }
          },
          adults: {
            ...formValues.adults,
            0: {
              ...b2bInitialState,
              employee: data,
              name: data?.full_name,
              age: data?.age,
              currentJobs: [
                {
                  ...jobDetailsInitialState,
                  currentWage: data?.wage
                }
              ]
            }
          },
          children: {}
        }
        setValues(formValuesUpdated)
        handleNext()
      }).catch(() => {
        setError(true)
        setDisabled(true)

        incorrectAttempts === MAX_INCORRECT_ATTEMPTS ? setIsPinCodeOpen(true) : setIncorrectAttempts(incorrectAttempts + 1)
      })
  }

  scrollToTop()

  return (
    <div className='pin-modal'>
      <div className='page-content'>
        <b>Welcome to Parsley</b>
        <p>Confirm your profile to explore your compensation</p>

        <div className='data-modal'>
          <b className='title'>Enter your employer provided pin</b>
          <form onSubmit={submitForm}>
            <div className='input-field'>
              <b className={error ? 'error-text' : ''}>Zip</b>
              <OutlinedInput
                fullWidth
                type='text'
                name='zipCode'
                inputProps={{ maxLength: 5 }}
                error={error}
                value={employer.zipCode}
                onChange={handleInputChange}
              />

              <Link onClick={modalClick} className='link-text'>Why do we need this?</Link>

              {isZipCodeOpen && <ZipCodeInfo isOpen={isZipCodeOpen} setIsOpen={setIsZipCodeOpen} />}
            </div>

            <div className='input-field'>
              <b className={error ? 'error-text' : ''}>Pin</b>
              <OutlinedInput
                fullWidth
                type='text'
                name='pin'
                inputProps={{ maxLength: 4 }}
                error={error}
                value={employer.pin}
                onChange={handleInputChange} />
            </div>

            <div className='error-messages'>
              <p>{errorMessage}</p>
            </div>

            <div className='landing-button'>
              <PurpleButton label='Get Started' width='280px' buttonClick={handleButtonClick} disabled={disabled} />
              <Link onClick={() => { setIsPinCodeOpen(true) }} className={error ? 'disable-link' : 'link'}>Don&apos;t have a pin?</Link>

              <PinCodeInfoModal isOpen={isPinCodeOpen} setIsOpen={setIsPinCodeOpen} incorrectPinMessage={incorrectAttempts >= 3} />
            </div>
          </form>
        </div>
      </div>

      <DividingColorLines />

      <PrivacyPolicy />
    </div>
  )
})

export default PinCodeModal
