import React, { forwardRef, useImperativeHandle, useContext, useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'

import ColorDivider from '../ColorDivider'
import PurpleCheckbox from '../PurpleCheckbox'
import PurpleButton from '../PurpleButton'
import { type StepProps } from '../../../EmployeeCalculatorInterface'
import { type FormValues } from '../../../FormValuesInterfaces'
import { scrollToTop } from '../../../Helper'
import { getBenefitsData } from '../helpers/Benefits'

import './styles.sass'
import 'react-toastify/dist/ReactToastify.css'

const BenefitsList = forwardRef((props: StepProps, ref) => {
  const { formValuesContext, setValues, handleNext, setIsChanged } = props
  const formValues: FormValues = useContext(formValuesContext)
  const [isSelectedBenefits, setIsSelectedBenefits] = useState(formValues?.adults[0]?.selectedBenefits)
  const [benefitsAvailable, setBenefitsAvailable] = useState<string[]>([])
  const [isBenefitsOpen, setIsBenefitsOpen] = useState(false)
  const [employeeBenefitList, setEmployeeBenefitList] = useState(formValues?.adults[0]?.employerBenefits ?? [])
  const [benefitsAnnualContributionLimit, setBenefitsAnnualContributionLimit] = useState({
    dependentCareLimit: [],
    medicalLimit: [],
    pensionLimit: [],
    '403(b)Limit': []
  })
  const [benefitPayoutSelection, setBenefitPayoutSelection] = useState({
    longTermDisabilityPayout: 0,
    shortTermDisabilityPayout: 0,
    lifeInsurancePayout:0,
  })
  const [benefitPayoutDuration, setBenefitPayoutDuration] = useState({
    longTermDisabilityDuration: '',
    shortTermDisabilityDuration: '',
    lifeInsuranceDuration:''
  })
  const [employerSeed, setEmployerSeed] = useState<{ dependentCareFSA: number | null , healthCareFSA: number| null}>({
    dependentCareFSA: null,
    healthCareFSA: null
  })
  
  const [benefitExplanation, setBenefitExplanation] = useState({
    longTermCareInsuranceBenefitExplanation: '',
    lifeInsuranceBenefitExplanation: '',
  })
  const [AnnualContributionMinimum, setAnnualContributionMinimum] = useState(({
    pension: ''
  }))
  const [AnnualContributionMaximum, setAnnualContributionMaximum] = useState({
    pension:''
  }) 
  const [VestmentTimePeriod, setVestmentTimePeriod] = useState(0)
  const [EmployerContributionLimit, SetEmployerContributionLimit] =useState({
    pension: 0,
    DependentCareFSA: 0,
    healthCareFSA: 0,
  })
  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        await getBenefitsData(formValues?.adults[0]?.employee?.id as number, setEmployeeBenefitList, setBenefitsAnnualContributionLimit,
          setBenefitPayoutSelection, setBenefitPayoutDuration, setBenefitExplanation, setAnnualContributionMinimum, setAnnualContributionMaximum,
          setVestmentTimePeriod, SetEmployerContributionLimit, setEmployerSeed)

      } catch (error) {
        toast.error(`Unable to fetch or process benefits data`)
      }
    }

    void fetchData()
  }, [])

  useEffect(() => {
    if (formValues.adults[0].selectAllBenefits) {
      const allBenefits = employeeBenefitList?.map((benefit) => benefit.value)
      setBenefitsAvailable(allBenefits)
    } else {
      setBenefitsAvailable(formValues?.adults[0]?.employee?.benefits_available as string[])
    }
  }, [employeeBenefitList])

  useImperativeHandle(ref, () => ({
    handleButtonClick() {
      const formValuesUpdated = {
        ...formValues,
        adults: {
          ...formValues.adults,
          0: {
            ...formValues.adults[0],
            selectedBenefits: isSelectedBenefits,
            employerBenefits: employeeBenefitList,
            isBenefitsInitialRender: false
          }
        },
        benefitsAnnualContributionLimit: {
          dependentCareLimit: benefitsAnnualContributionLimit.dependentCareLimit,
          medicalLimit: benefitsAnnualContributionLimit.medicalLimit,
          pensionLimit: benefitsAnnualContributionLimit.pensionLimit,
          'TSA403bLimit': benefitsAnnualContributionLimit['403(b)Limit']
        },
        BenefitPayoutSelection: {
          longTermDisabilityPayout: benefitPayoutSelection.longTermDisabilityPayout,
          shortTermDisabilityPayout: benefitPayoutSelection.shortTermDisabilityPayout,
          lifeInsurancePayout:benefitPayoutSelection.lifeInsurancePayout
        },
        BenefitPayoutDuration: {

          longTermDisabilityDuration: benefitPayoutDuration.longTermDisabilityDuration,
          shortTermDisabilityDuration: benefitPayoutDuration.shortTermDisabilityDuration,
          lifeInsuranceDuration:benefitPayoutDuration.lifeInsuranceDuration
        },
        benefitExplanation: {
          longTermCareInsuranceBenefitExplanation: benefitExplanation.longTermCareInsuranceBenefitExplanation,
          lifeInsuranceBenefitExplanation:benefitExplanation.lifeInsuranceBenefitExplanation

        },
        AnnualContributionMinimum: {
          pension: AnnualContributionMinimum.pension
        },
        AnnualContributionMaximum: {
          pension: AnnualContributionMaximum.pension
        },
        VestmentTimePeriod: VestmentTimePeriod,
        EmployerContributionLimit: {
          pension: EmployerContributionLimit.pension,
          DependentCareFSA: EmployerContributionLimit.DependentCareFSA,
          healthCareFSA: EmployerContributionLimit.healthCareFSA
        },
        employerSeed:{
          dependentCareFSA:employerSeed.dependentCareFSA,
          healthCareFSA:employerSeed.healthCareFSA
        }
      }

      setValues(formValuesUpdated)
    }
  }))

  const handleButtonClick = (): void => {
    setIsBenefitsOpen(!isBenefitsOpen)
    handleNext()

    const formValuesUpdated = {
      ...formValues,
      adults: {
        ...formValues.adults,
        0: {
          ...formValues.adults[0],
          selectedBenefits: isSelectedBenefits,
          employerBenefits: employeeBenefitList,
          isBenefitsInitialRender: false
        }
      },
      benefitsAnnualContributionLimit: {
        dependentCareLimit: benefitsAnnualContributionLimit.dependentCareLimit,
        medicalLimit: benefitsAnnualContributionLimit.medicalLimit,
        pensionLimit: benefitsAnnualContributionLimit.pensionLimit,
        'TSA403bLimit': benefitsAnnualContributionLimit['403(b)Limit']
      },
      BenefitPayoutSelection: {
        longTermDisabilityPayout: benefitPayoutSelection.longTermDisabilityPayout,
        shortTermDisabilityPayout: benefitPayoutSelection.shortTermDisabilityPayout,
        lifeInsurancePayout:benefitPayoutSelection.lifeInsurancePayout
      },
      BenefitPayoutDuration: {
        longTermDisabilityDuration: benefitPayoutDuration.longTermDisabilityDuration,
        shortTermDisabilityDuration: benefitPayoutDuration.shortTermDisabilityDuration,
        lifeInsuranceDuration:benefitPayoutDuration.lifeInsuranceDuration
      },
      benefitExplanation: {
        longTermCareInsuranceBenefitExplanation: benefitExplanation.longTermCareInsuranceBenefitExplanation,
        lifeInsuranceBenefitExplanation:benefitExplanation.lifeInsuranceBenefitExplanation
      },
      AnnualContributionMinimum: {
        pension: AnnualContributionMinimum.pension
      },
      AnnualContributionMaximum: {
        pension: AnnualContributionMaximum.pension
      },
      VestmentTimePeriod: VestmentTimePeriod,
      EmployerContributionLimit: {
        pension: EmployerContributionLimit.pension,
        DependentCareFSA: EmployerContributionLimit.DependentCareFSA,
        healthCareFSA: EmployerContributionLimit.healthCareFSA
      },
      employerSeed:{
        dependentCareFSA:employerSeed.dependentCareFSA,
        healthCareFSA:employerSeed.healthCareFSA
      }
    }

    setValues(formValuesUpdated)
  }

  const renderBenefitsCheckbox = (label: string, value: string): JSX.Element => (
    <React.Fragment key={value}>
      <PurpleCheckbox
        label={label}
        value={value}
        selectedBenefits={isSelectedBenefits as string[]}
        setValue={(values: string[]) => {
          setIsSelectedBenefits(values)
          setIsChanged?.(true)
        }}
        benefitsAvailable={benefitsAvailable}
        employeeBenefitsList={employeeBenefitList}
      />
    </React.Fragment>
  )

  scrollToTop()
  return (
    <div className='benefit-list-container'>
      <div className='page-header'>
        <b>Benefits</b>
        <p>Which of the following benefits will you choose to participate in?</p>
      </div>

      <ColorDivider />

      <div className='page-body'>
        <b>Select your benefits</b>
        {employeeBenefitList.map((benefit: { label: string, value: string }) => renderBenefitsCheckbox(benefit.label, benefit.value))}

        <div className='benefit-confirm-button'>
          <PurpleButton label='Continue' width='100%' buttonClick={handleButtonClick} />
        </div>
      </div>

      <ToastContainer
        position='top-right'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
})

export default BenefitsList
